import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

const baseStyle = defineStyle({
  _dark: {
    borderColor: rgba(COLORS.lightVividSkyBlue, OPACITY[15]),
  },
});

export const Divider = defineStyleConfig({
  baseStyle,
});
