import {
  GetPipelinesErrorCountResponse,
  AcknowledgeErrorsRequest,
} from '@common-types/analytics_service/api/v1/analytics_service';
import { AcknowledgementState } from '@common-types/analytics_service/conf/v1/analytics-config';
import apiSlice from 'src/store/api';
import { DATE_FILTER_START_DATE } from 'src/constants/dateFilter';
import { DateFilter } from 'src/types/DateFilter';
import { convertDateToUnixTimeSeconds, getAnalyticsEndDate } from 'src/utils/date';
import { RTK_QUERY_DEFAULT_STALE_TIME } from 'src/constants/queries';

interface PipelineErrorCountParams {
  siteId: string;
  acknowledgeState: AcknowledgementState;
}

export const API_TAG_PIPELINE_ERROR_COUNT = 'monitoringPipelineErrorCount';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: [API_TAG_PIPELINE_ERROR_COUNT] });

const injectedEndpoints = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    pipelineErrorCount: build.query<GetPipelinesErrorCountResponse, PipelineErrorCountParams>({
      query: ({ siteId, acknowledgeState }) => ({
        url: '/v1/analytics-service/pipelines/errors/count',
        params: {
          siteId,
          acknowledgeState,
          'time_range.start_timestamp.seconds': convertDateToUnixTimeSeconds(
            DATE_FILTER_START_DATE[DateFilter.Last60Days](),
          ),
          'time_range.end_timestamp.seconds': convertDateToUnixTimeSeconds(getAnalyticsEndDate()),
        },
      }),
      keepUnusedDataFor: RTK_QUERY_DEFAULT_STALE_TIME,
      providesTags: [API_TAG_PIPELINE_ERROR_COUNT],
    }),
    ackUnreadErrors: build.mutation<unknown, AcknowledgeErrorsRequest>({
      query: (data) => ({
        url: '/v1/analytics-service/components/errors/acknowledge',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [API_TAG_PIPELINE_ERROR_COUNT],
    }),
  }),
  overrideExisting: false,
});

export const { usePipelineErrorCountQuery, useAckUnreadErrorsMutation } = injectedEndpoints;
