import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(parts.keys);

export const Tabs = helpers.defineMultiStyleConfig({
  variants: {
    line: {
      tab: {
        color: 'label',
        paddingBottom: 3,
        paddingRight: 0,
        paddingLeft: 0,
        marginRight: 8,
        _selected: {
          color: 'white',
          fontWeight: '600',
          borderBottomWidth: '4px',
          borderBottomColor: 'link',
        },
      },
      tablist: {
        borderBottomWidth: 0,
      },
    },
  },
});
