/* eslint-disable */

export const protobufPackage = "errors";

export enum ErrorReason {
  /** EMPTY - EMPTY needed to keep enum compiler rules happy */
  EMPTY = 0,
  /**
   * NO_DATA - Custom error codes
   * Unassigned ranges available
   * 419-420
   * 432-450
   * 452-499
   * 512-599
   */
  NO_DATA = 512,
  SITE_UPDATE_REQUIRED = 599,
  /**
   * MULTIPLE_CHOICES - HTTP error codes
   * https://www.iana.org/assignments/http-status-codes/http-status-codes.xhtml
   */
  MULTIPLE_CHOICES = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  USE_PROXY = 305,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  MISDIRECTED_REQUEST = 421,
  UNPROCESSABLE_ENTITY = 422,
  LOCKED = 423,
  FAILED_DEPENDENCY = 424,
  UPGRADE_REQUIRED = 426,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
  VARIANT_ALSO_NEGOTIATES = 506,
  INSUFFICIENT_STORAGE = 507,
  LOOP_DETECTED = 508,
  NOT_EXTENDED = 510,
  NETWORK_AUTHENTICATION_REQUIRED = 511,
  UNRECOGNIZED = -1,
}

export function errorReasonFromJSON(object: any): ErrorReason {
  switch (object) {
    case 0:
    case "EMPTY":
      return ErrorReason.EMPTY;
    case 512:
    case "NO_DATA":
      return ErrorReason.NO_DATA;
    case 599:
    case "SITE_UPDATE_REQUIRED":
      return ErrorReason.SITE_UPDATE_REQUIRED;
    case 300:
    case "MULTIPLE_CHOICES":
      return ErrorReason.MULTIPLE_CHOICES;
    case 301:
    case "MOVED_PERMANENTLY":
      return ErrorReason.MOVED_PERMANENTLY;
    case 302:
    case "FOUND":
      return ErrorReason.FOUND;
    case 303:
    case "SEE_OTHER":
      return ErrorReason.SEE_OTHER;
    case 304:
    case "NOT_MODIFIED":
      return ErrorReason.NOT_MODIFIED;
    case 305:
    case "USE_PROXY":
      return ErrorReason.USE_PROXY;
    case 307:
    case "TEMPORARY_REDIRECT":
      return ErrorReason.TEMPORARY_REDIRECT;
    case 308:
    case "PERMANENT_REDIRECT":
      return ErrorReason.PERMANENT_REDIRECT;
    case 400:
    case "BAD_REQUEST":
      return ErrorReason.BAD_REQUEST;
    case 401:
    case "UNAUTHORIZED":
      return ErrorReason.UNAUTHORIZED;
    case 402:
    case "PAYMENT_REQUIRED":
      return ErrorReason.PAYMENT_REQUIRED;
    case 403:
    case "FORBIDDEN":
      return ErrorReason.FORBIDDEN;
    case 404:
    case "NOT_FOUND":
      return ErrorReason.NOT_FOUND;
    case 405:
    case "METHOD_NOT_ALLOWED":
      return ErrorReason.METHOD_NOT_ALLOWED;
    case 406:
    case "NOT_ACCEPTABLE":
      return ErrorReason.NOT_ACCEPTABLE;
    case 407:
    case "PROXY_AUTHENTICATION_REQUIRED":
      return ErrorReason.PROXY_AUTHENTICATION_REQUIRED;
    case 408:
    case "REQUEST_TIMEOUT":
      return ErrorReason.REQUEST_TIMEOUT;
    case 409:
    case "CONFLICT":
      return ErrorReason.CONFLICT;
    case 410:
    case "GONE":
      return ErrorReason.GONE;
    case 411:
    case "LENGTH_REQUIRED":
      return ErrorReason.LENGTH_REQUIRED;
    case 412:
    case "PRECONDITION_FAILED":
      return ErrorReason.PRECONDITION_FAILED;
    case 413:
    case "PAYLOAD_TOO_LARGE":
      return ErrorReason.PAYLOAD_TOO_LARGE;
    case 414:
    case "URI_TOO_LONG":
      return ErrorReason.URI_TOO_LONG;
    case 415:
    case "UNSUPPORTED_MEDIA_TYPE":
      return ErrorReason.UNSUPPORTED_MEDIA_TYPE;
    case 416:
    case "RANGE_NOT_SATISFIABLE":
      return ErrorReason.RANGE_NOT_SATISFIABLE;
    case 417:
    case "EXPECTATION_FAILED":
      return ErrorReason.EXPECTATION_FAILED;
    case 421:
    case "MISDIRECTED_REQUEST":
      return ErrorReason.MISDIRECTED_REQUEST;
    case 422:
    case "UNPROCESSABLE_ENTITY":
      return ErrorReason.UNPROCESSABLE_ENTITY;
    case 423:
    case "LOCKED":
      return ErrorReason.LOCKED;
    case 424:
    case "FAILED_DEPENDENCY":
      return ErrorReason.FAILED_DEPENDENCY;
    case 426:
    case "UPGRADE_REQUIRED":
      return ErrorReason.UPGRADE_REQUIRED;
    case 428:
    case "PRECONDITION_REQUIRED":
      return ErrorReason.PRECONDITION_REQUIRED;
    case 429:
    case "TOO_MANY_REQUESTS":
      return ErrorReason.TOO_MANY_REQUESTS;
    case 431:
    case "REQUEST_HEADER_FIELDS_TOO_LARGE":
      return ErrorReason.REQUEST_HEADER_FIELDS_TOO_LARGE;
    case 500:
    case "INTERNAL_SERVER_ERROR":
      return ErrorReason.INTERNAL_SERVER_ERROR;
    case 501:
    case "NOT_IMPLEMENTED":
      return ErrorReason.NOT_IMPLEMENTED;
    case 502:
    case "BAD_GATEWAY":
      return ErrorReason.BAD_GATEWAY;
    case 503:
    case "SERVICE_UNAVAILABLE":
      return ErrorReason.SERVICE_UNAVAILABLE;
    case 504:
    case "GATEWAY_TIMEOUT":
      return ErrorReason.GATEWAY_TIMEOUT;
    case 505:
    case "HTTP_VERSION_NOT_SUPPORTED":
      return ErrorReason.HTTP_VERSION_NOT_SUPPORTED;
    case 506:
    case "VARIANT_ALSO_NEGOTIATES":
      return ErrorReason.VARIANT_ALSO_NEGOTIATES;
    case 507:
    case "INSUFFICIENT_STORAGE":
      return ErrorReason.INSUFFICIENT_STORAGE;
    case 508:
    case "LOOP_DETECTED":
      return ErrorReason.LOOP_DETECTED;
    case 510:
    case "NOT_EXTENDED":
      return ErrorReason.NOT_EXTENDED;
    case 511:
    case "NETWORK_AUTHENTICATION_REQUIRED":
      return ErrorReason.NETWORK_AUTHENTICATION_REQUIRED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ErrorReason.UNRECOGNIZED;
  }
}

export function errorReasonToJSON(object: ErrorReason): string {
  switch (object) {
    case ErrorReason.EMPTY:
      return "EMPTY";
    case ErrorReason.NO_DATA:
      return "NO_DATA";
    case ErrorReason.SITE_UPDATE_REQUIRED:
      return "SITE_UPDATE_REQUIRED";
    case ErrorReason.MULTIPLE_CHOICES:
      return "MULTIPLE_CHOICES";
    case ErrorReason.MOVED_PERMANENTLY:
      return "MOVED_PERMANENTLY";
    case ErrorReason.FOUND:
      return "FOUND";
    case ErrorReason.SEE_OTHER:
      return "SEE_OTHER";
    case ErrorReason.NOT_MODIFIED:
      return "NOT_MODIFIED";
    case ErrorReason.USE_PROXY:
      return "USE_PROXY";
    case ErrorReason.TEMPORARY_REDIRECT:
      return "TEMPORARY_REDIRECT";
    case ErrorReason.PERMANENT_REDIRECT:
      return "PERMANENT_REDIRECT";
    case ErrorReason.BAD_REQUEST:
      return "BAD_REQUEST";
    case ErrorReason.UNAUTHORIZED:
      return "UNAUTHORIZED";
    case ErrorReason.PAYMENT_REQUIRED:
      return "PAYMENT_REQUIRED";
    case ErrorReason.FORBIDDEN:
      return "FORBIDDEN";
    case ErrorReason.NOT_FOUND:
      return "NOT_FOUND";
    case ErrorReason.METHOD_NOT_ALLOWED:
      return "METHOD_NOT_ALLOWED";
    case ErrorReason.NOT_ACCEPTABLE:
      return "NOT_ACCEPTABLE";
    case ErrorReason.PROXY_AUTHENTICATION_REQUIRED:
      return "PROXY_AUTHENTICATION_REQUIRED";
    case ErrorReason.REQUEST_TIMEOUT:
      return "REQUEST_TIMEOUT";
    case ErrorReason.CONFLICT:
      return "CONFLICT";
    case ErrorReason.GONE:
      return "GONE";
    case ErrorReason.LENGTH_REQUIRED:
      return "LENGTH_REQUIRED";
    case ErrorReason.PRECONDITION_FAILED:
      return "PRECONDITION_FAILED";
    case ErrorReason.PAYLOAD_TOO_LARGE:
      return "PAYLOAD_TOO_LARGE";
    case ErrorReason.URI_TOO_LONG:
      return "URI_TOO_LONG";
    case ErrorReason.UNSUPPORTED_MEDIA_TYPE:
      return "UNSUPPORTED_MEDIA_TYPE";
    case ErrorReason.RANGE_NOT_SATISFIABLE:
      return "RANGE_NOT_SATISFIABLE";
    case ErrorReason.EXPECTATION_FAILED:
      return "EXPECTATION_FAILED";
    case ErrorReason.MISDIRECTED_REQUEST:
      return "MISDIRECTED_REQUEST";
    case ErrorReason.UNPROCESSABLE_ENTITY:
      return "UNPROCESSABLE_ENTITY";
    case ErrorReason.LOCKED:
      return "LOCKED";
    case ErrorReason.FAILED_DEPENDENCY:
      return "FAILED_DEPENDENCY";
    case ErrorReason.UPGRADE_REQUIRED:
      return "UPGRADE_REQUIRED";
    case ErrorReason.PRECONDITION_REQUIRED:
      return "PRECONDITION_REQUIRED";
    case ErrorReason.TOO_MANY_REQUESTS:
      return "TOO_MANY_REQUESTS";
    case ErrorReason.REQUEST_HEADER_FIELDS_TOO_LARGE:
      return "REQUEST_HEADER_FIELDS_TOO_LARGE";
    case ErrorReason.INTERNAL_SERVER_ERROR:
      return "INTERNAL_SERVER_ERROR";
    case ErrorReason.NOT_IMPLEMENTED:
      return "NOT_IMPLEMENTED";
    case ErrorReason.BAD_GATEWAY:
      return "BAD_GATEWAY";
    case ErrorReason.SERVICE_UNAVAILABLE:
      return "SERVICE_UNAVAILABLE";
    case ErrorReason.GATEWAY_TIMEOUT:
      return "GATEWAY_TIMEOUT";
    case ErrorReason.HTTP_VERSION_NOT_SUPPORTED:
      return "HTTP_VERSION_NOT_SUPPORTED";
    case ErrorReason.VARIANT_ALSO_NEGOTIATES:
      return "VARIANT_ALSO_NEGOTIATES";
    case ErrorReason.INSUFFICIENT_STORAGE:
      return "INSUFFICIENT_STORAGE";
    case ErrorReason.LOOP_DETECTED:
      return "LOOP_DETECTED";
    case ErrorReason.NOT_EXTENDED:
      return "NOT_EXTENDED";
    case ErrorReason.NETWORK_AUTHENTICATION_REQUIRED:
      return "NETWORK_AUTHENTICATION_REQUIRED";
    case ErrorReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
