import { lazy, Suspense } from 'react';

const Dashboard = lazy(
  () => import(/* webpackPrefetch: true */ '../pages/Dashboard/DashboardPage'),
);

const DashboardPage = () => (
  <Suspense>
    <Dashboard />
  </Suspense>
);

export default DashboardPage;
