import { SelectItem } from 'src/types/Select';

export enum SiteFormFieldName {
  Name = 'name',
  Description = 'description',
  CloudType = 'cloudType',
  Region = 'region',
  MinInstances = 'minInstances',
  MaxInstances = 'maxInstances',
}

export enum SiteFormFieldLabel {
  Name = 'Name',
  Description = 'Description (Optional)',
  CloudType = 'Cloud Type',
  Region = 'Region (Optional)',
  MinInstances = 'Min Instances (Optional)',
  MaxInstances = 'Max Instances (Optional)',
}

export type SiteFormValues = {
  [SiteFormFieldName.Name]: string;
  [SiteFormFieldName.Description]: string;
  [SiteFormFieldName.CloudType]: SelectItem | undefined;
  [SiteFormFieldName.Region]: string;
  [SiteFormFieldName.MinInstances]: number | undefined;
  [SiteFormFieldName.MaxInstances]: number | undefined;
};
