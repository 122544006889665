import { createIcon } from '@chakra-ui/react';
import { COLORS } from 'src/constants/colors';

export const DestinationIcon = createIcon({
  displayName: 'DestinationIcon',
  viewBox: '0 0 24 24',
  path: [
    <g clipPath="url(#clip0_4028_9137)">
      <path
        d="M4.5 9.5L2 10.75L12 15.75L22 10.75L19.5 9.5"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 15.75L12 20.75L22 15.75"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2V10.75"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 7L12 10.75L8.25 7"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>,
    <defs>
      <clipPath id="clip0_4028_9137">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>,
  ],
});
