import { ListNotificationsResponse } from '@common-types/notification_service/api/v1/ns_api';
import { Event_Category } from '@common-types/notification_service/model/v1/event';
import { useQuery } from '@tanstack/react-query';
import { DATE_FILTER_START_DATE } from 'src/constants/dateFilter';
import { TEMPORARY_PAGINATION_LIMIT } from 'src/constants/queries';
import axios from 'src/lib/axios/axios';
import { DateFilter } from 'src/types/DateFilter';
import { convertDateToUnixTimeSeconds, getAnalyticsEndDate } from 'src/utils/date';

type UseNumberOfUnreadNotificationsQueryParams = {
  siteIds: string[];
  categories?: Event_Category[];
  ackValues?: boolean[];
  isEnabled?: boolean;
};

export function useNumberOfUnreadNotificationsQuery({
  siteIds = [],
  categories = [],
  ackValues = [],
  isEnabled,
}: UseNumberOfUnreadNotificationsQueryParams) {
  return useQuery({
    queryKey: [
      'numOfUnreadNotifications',
      {
        siteIds,
        categories,
        ackValues,
      },
    ],
    queryFn: async () => {
      const response = await axios.get<ListNotificationsResponse>('/v1/notifications', {
        params: {
          siteIds,
          categories,
          ackValues,
          'fromTimestamp.seconds': convertDateToUnixTimeSeconds(
            DATE_FILTER_START_DATE[DateFilter.Last60Days](),
          ),
          'toTimestamp.seconds': convertDateToUnixTimeSeconds(getAnalyticsEndDate()),
          // todo: remove this when pagination is implemented
          'pagination.limit': TEMPORARY_PAGINATION_LIMIT,
        },
      });
      return response.data.pagination?.totalCount ?? 0;
    },
    enabled: isEnabled,
  });
}
