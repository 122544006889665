/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "v1.event";

/** Next ID: 7 */
export interface UserNotification {
  /** title that the user sees in notifications page (Source */
  title: string;
  /** data is hydrated to Destination) */
  header: string;
  /** collapsed list view (Download Ready) */
  description: string;
  /** resource type that the notification is relevant to */
  objectType: string;
  /** (pipeline, source, sink, etc) */
  objectId: string;
  /** (pipelineId, sinkId, etc) */
  severity: UserNotification_Severity;
}

/** Next ID: 3 */
export enum UserNotification_Severity {
  UNKNOWN_SEVERITY = 0,
  INFORMATIONAL = 1,
  WARNING = 2,
  ERROR = 3,
  UNRECOGNIZED = -1,
}

export function userNotification_SeverityFromJSON(object: any): UserNotification_Severity {
  switch (object) {
    case 0:
    case "UNKNOWN_SEVERITY":
      return UserNotification_Severity.UNKNOWN_SEVERITY;
    case 1:
    case "INFORMATIONAL":
      return UserNotification_Severity.INFORMATIONAL;
    case 2:
    case "WARNING":
      return UserNotification_Severity.WARNING;
    case 3:
    case "ERROR":
      return UserNotification_Severity.ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserNotification_Severity.UNRECOGNIZED;
  }
}

export function userNotification_SeverityToJSON(object: UserNotification_Severity): string {
  switch (object) {
    case UserNotification_Severity.UNKNOWN_SEVERITY:
      return "UNKNOWN_SEVERITY";
    case UserNotification_Severity.INFORMATIONAL:
      return "INFORMATIONAL";
    case UserNotification_Severity.WARNING:
      return "WARNING";
    case UserNotification_Severity.ERROR:
      return "ERROR";
    case UserNotification_Severity.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseUserNotification(): UserNotification {
  return { title: "", header: "", description: "", objectType: "", objectId: "", severity: 0 };
}

export const UserNotification = {
  encode(message: UserNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.header !== "") {
      writer.uint32(18).string(message.header);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.objectType !== "") {
      writer.uint32(34).string(message.objectType);
    }
    if (message.objectId !== "") {
      writer.uint32(42).string(message.objectId);
    }
    if (message.severity !== 0) {
      writer.uint32(48).int32(message.severity);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserNotification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.header = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.objectType = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.objectId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.severity = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserNotification {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      header: isSet(object.header) ? globalThis.String(object.header) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      objectType: isSet(object.objectType) ? globalThis.String(object.objectType) : "",
      objectId: isSet(object.objectId) ? globalThis.String(object.objectId) : "",
      severity: isSet(object.severity) ? userNotification_SeverityFromJSON(object.severity) : 0,
    };
  },

  toJSON(message: UserNotification): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.header !== "") {
      obj.header = message.header;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.objectType !== "") {
      obj.objectType = message.objectType;
    }
    if (message.objectId !== "") {
      obj.objectId = message.objectId;
    }
    if (message.severity !== 0) {
      obj.severity = userNotification_SeverityToJSON(message.severity);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserNotification>, I>>(base?: I): UserNotification {
    return UserNotification.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserNotification>, I>>(object: I): UserNotification {
    const message = createBaseUserNotification();
    message.title = object.title ?? "";
    message.header = object.header ?? "";
    message.description = object.description ?? "";
    message.objectType = object.objectType ?? "";
    message.objectId = object.objectId ?? "";
    message.severity = object.severity ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
