/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Struct } from "../../../../../google/protobuf/struct";
import { Timestamp } from "../../../../../google/protobuf/timestamp";
import { TimeRange } from "../../../common/v1/common";
import { HydrationJobResource } from "../../../site_manager/model/v1/hydration";
import { JobResource } from "../../../site_manager/model/v1/site";

export const protobufPackage = "v1.event";

/** Next ID: 4 */
export enum SubCategory {
  UNSPECIFIED_SUBCATEGORY = 0,
  /** UPDATE_PIPELINE - Payload type: UpdatePipelineEvent */
  UPDATE_PIPELINE = 1,
  /** UPDATE_SOURCE - Payload type: Source */
  UPDATE_SOURCE = 2,
  /** UPDATE_SINK - Payload type: Sink */
  UPDATE_SINK = 3,
  /** DELETE_PIPELINE - Payload type: DeletePipelineEvent */
  DELETE_PIPELINE = 4,
  /** ADD_SOURCE - Payload type: Source */
  ADD_SOURCE = 5,
  /** ADD_SINK - Payload type: Sink */
  ADD_SINK = 6,
  /** ADD_TRANSFORM - Payload type: Transform */
  ADD_TRANSFORM = 7,
  /** CAPTURE_LOG - Payload type: CaptureLogEvent */
  CAPTURE_LOG = 8,
  /** ADD_SITEFILE - Payload type: SiteFile */
  ADD_SITEFILE = 9,
  /** UPDATE_SITEFILE - Payload type: SiteFile */
  UPDATE_SITEFILE = 10,
  /** DELETE_SITEFILE - Payload type: DeleteSiteFile */
  DELETE_SITEFILE = 11,
  /** DELETE_SOURCE - Payload type: DeleteSource */
  DELETE_SOURCE = 12,
  /** DELETE_SINK - Payload type: DeleteSink */
  DELETE_SINK = 13,
  /** ALERT_TRIGGERED - Payload type: AlertTriggered */
  ALERT_TRIGGERED = 14,
  /** ADD_HYDRATION_JOB - Payload type: HydrationJobEvent */
  ADD_HYDRATION_JOB = 15,
  /** DELETE_HYDRATION_JOB - Payload type: DeleteHydrationJobEvent */
  DELETE_HYDRATION_JOB = 16,
  /** ADD_DYNAMIC_LOOKUP_JOB - Payload type: DynamicLookupJobEvent */
  ADD_DYNAMIC_LOOKUP_JOB = 17,
  /** DELETE_DYNAMIC_LOOKUP_JOB - Payload type: DeleteDynamicLookupJobEvent */
  DELETE_DYNAMIC_LOOKUP_JOB = 18,
  /** UPDATE_DYNAMIC_LOOKUP_JOB - Payload type: DynamicLookupJobEvent */
  UPDATE_DYNAMIC_LOOKUP_JOB = 19,
  UNRECOGNIZED = -1,
}

export function subCategoryFromJSON(object: any): SubCategory {
  switch (object) {
    case 0:
    case "UNSPECIFIED_SUBCATEGORY":
      return SubCategory.UNSPECIFIED_SUBCATEGORY;
    case 1:
    case "UPDATE_PIPELINE":
      return SubCategory.UPDATE_PIPELINE;
    case 2:
    case "UPDATE_SOURCE":
      return SubCategory.UPDATE_SOURCE;
    case 3:
    case "UPDATE_SINK":
      return SubCategory.UPDATE_SINK;
    case 4:
    case "DELETE_PIPELINE":
      return SubCategory.DELETE_PIPELINE;
    case 5:
    case "ADD_SOURCE":
      return SubCategory.ADD_SOURCE;
    case 6:
    case "ADD_SINK":
      return SubCategory.ADD_SINK;
    case 7:
    case "ADD_TRANSFORM":
      return SubCategory.ADD_TRANSFORM;
    case 8:
    case "CAPTURE_LOG":
      return SubCategory.CAPTURE_LOG;
    case 9:
    case "ADD_SITEFILE":
      return SubCategory.ADD_SITEFILE;
    case 10:
    case "UPDATE_SITEFILE":
      return SubCategory.UPDATE_SITEFILE;
    case 11:
    case "DELETE_SITEFILE":
      return SubCategory.DELETE_SITEFILE;
    case 12:
    case "DELETE_SOURCE":
      return SubCategory.DELETE_SOURCE;
    case 13:
    case "DELETE_SINK":
      return SubCategory.DELETE_SINK;
    case 14:
    case "ALERT_TRIGGERED":
      return SubCategory.ALERT_TRIGGERED;
    case 15:
    case "ADD_HYDRATION_JOB":
      return SubCategory.ADD_HYDRATION_JOB;
    case 16:
    case "DELETE_HYDRATION_JOB":
      return SubCategory.DELETE_HYDRATION_JOB;
    case 17:
    case "ADD_DYNAMIC_LOOKUP_JOB":
      return SubCategory.ADD_DYNAMIC_LOOKUP_JOB;
    case 18:
    case "DELETE_DYNAMIC_LOOKUP_JOB":
      return SubCategory.DELETE_DYNAMIC_LOOKUP_JOB;
    case 19:
    case "UPDATE_DYNAMIC_LOOKUP_JOB":
      return SubCategory.UPDATE_DYNAMIC_LOOKUP_JOB;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SubCategory.UNRECOGNIZED;
  }
}

export function subCategoryToJSON(object: SubCategory): string {
  switch (object) {
    case SubCategory.UNSPECIFIED_SUBCATEGORY:
      return "UNSPECIFIED_SUBCATEGORY";
    case SubCategory.UPDATE_PIPELINE:
      return "UPDATE_PIPELINE";
    case SubCategory.UPDATE_SOURCE:
      return "UPDATE_SOURCE";
    case SubCategory.UPDATE_SINK:
      return "UPDATE_SINK";
    case SubCategory.DELETE_PIPELINE:
      return "DELETE_PIPELINE";
    case SubCategory.ADD_SOURCE:
      return "ADD_SOURCE";
    case SubCategory.ADD_SINK:
      return "ADD_SINK";
    case SubCategory.ADD_TRANSFORM:
      return "ADD_TRANSFORM";
    case SubCategory.CAPTURE_LOG:
      return "CAPTURE_LOG";
    case SubCategory.ADD_SITEFILE:
      return "ADD_SITEFILE";
    case SubCategory.UPDATE_SITEFILE:
      return "UPDATE_SITEFILE";
    case SubCategory.DELETE_SITEFILE:
      return "DELETE_SITEFILE";
    case SubCategory.DELETE_SOURCE:
      return "DELETE_SOURCE";
    case SubCategory.DELETE_SINK:
      return "DELETE_SINK";
    case SubCategory.ALERT_TRIGGERED:
      return "ALERT_TRIGGERED";
    case SubCategory.ADD_HYDRATION_JOB:
      return "ADD_HYDRATION_JOB";
    case SubCategory.DELETE_HYDRATION_JOB:
      return "DELETE_HYDRATION_JOB";
    case SubCategory.ADD_DYNAMIC_LOOKUP_JOB:
      return "ADD_DYNAMIC_LOOKUP_JOB";
    case SubCategory.DELETE_DYNAMIC_LOOKUP_JOB:
      return "DELETE_DYNAMIC_LOOKUP_JOB";
    case SubCategory.UPDATE_DYNAMIC_LOOKUP_JOB:
      return "UPDATE_DYNAMIC_LOOKUP_JOB";
    case SubCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Enum for different archival source types */
export enum ArchivalSourceType {
  ARCHIVAL_SOURCE_DEFAULT = 0,
  ARCHIVAL_SOURCE_AWS_S3 = 1,
  ARCHIVAL_SOURCE_GCS = 2,
  UNRECOGNIZED = -1,
}

export function archivalSourceTypeFromJSON(object: any): ArchivalSourceType {
  switch (object) {
    case 0:
    case "ARCHIVAL_SOURCE_DEFAULT":
      return ArchivalSourceType.ARCHIVAL_SOURCE_DEFAULT;
    case 1:
    case "ARCHIVAL_SOURCE_AWS_S3":
      return ArchivalSourceType.ARCHIVAL_SOURCE_AWS_S3;
    case 2:
    case "ARCHIVAL_SOURCE_GCS":
      return ArchivalSourceType.ARCHIVAL_SOURCE_GCS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ArchivalSourceType.UNRECOGNIZED;
  }
}

export function archivalSourceTypeToJSON(object: ArchivalSourceType): string {
  switch (object) {
    case ArchivalSourceType.ARCHIVAL_SOURCE_DEFAULT:
      return "ARCHIVAL_SOURCE_DEFAULT";
    case ArchivalSourceType.ARCHIVAL_SOURCE_AWS_S3:
      return "ARCHIVAL_SOURCE_AWS_S3";
    case ArchivalSourceType.ARCHIVAL_SOURCE_GCS:
      return "ARCHIVAL_SOURCE_GCS";
    case ArchivalSourceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Enum for different archival data formats */
export enum ArchivalDataFormat {
  ARCHIVAL_DATA_FORMAT_DEFAULT = 0,
  ARCHIVAL_DATA_FORMAT_JSON = 1,
  ARCHIVAL_DATA_FORMAT_PARQUET = 2,
  UNRECOGNIZED = -1,
}

export function archivalDataFormatFromJSON(object: any): ArchivalDataFormat {
  switch (object) {
    case 0:
    case "ARCHIVAL_DATA_FORMAT_DEFAULT":
      return ArchivalDataFormat.ARCHIVAL_DATA_FORMAT_DEFAULT;
    case 1:
    case "ARCHIVAL_DATA_FORMAT_JSON":
      return ArchivalDataFormat.ARCHIVAL_DATA_FORMAT_JSON;
    case 2:
    case "ARCHIVAL_DATA_FORMAT_PARQUET":
      return ArchivalDataFormat.ARCHIVAL_DATA_FORMAT_PARQUET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ArchivalDataFormat.UNRECOGNIZED;
  }
}

export function archivalDataFormatToJSON(object: ArchivalDataFormat): string {
  switch (object) {
    case ArchivalDataFormat.ARCHIVAL_DATA_FORMAT_DEFAULT:
      return "ARCHIVAL_DATA_FORMAT_DEFAULT";
    case ArchivalDataFormat.ARCHIVAL_DATA_FORMAT_JSON:
      return "ARCHIVAL_DATA_FORMAT_JSON";
    case ArchivalDataFormat.ARCHIVAL_DATA_FORMAT_PARQUET:
      return "ARCHIVAL_DATA_FORMAT_PARQUET";
    case ArchivalDataFormat.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** this is the proto for event.payload in update_pipeline event */
export interface UpdatePipelineEvent {
  pipelineId: string;
  graphVersion: number;
}

/** this is the proto for event.payload in delete_pipeline event */
export interface DeletePipelineEvent {
  pipelineId: string;
}

/** Capture event sent to control agent to capture logs for a component. */
export interface CaptureLogEvent {
  siteId: string;
  componentIds: string[];
  /** num of log lines per hour. */
  numLogLines: number;
  timeoutMs: string;
}

export interface DeleteSiteFile {
  sitefileId: string;
  filename: string;
}

export interface DeleteSource {
  sourceId: string;
}

export interface DeleteSink {
  sinkId: string;
}

export interface UpsertSink {
  siteId: string;
  sinkId: string;
}

export interface UpsertSource {
  siteId: string;
  sourceId: string;
}

/** this is the proto for event.payload in update_pipeline event */
export interface AlertTriggered {
  siteId: string;
  alertId: string;
  labels: { [key: string]: string };
  currentValue: number;
  triggerTime: string | undefined;
}

export interface AlertTriggered_LabelsEntry {
  key: string;
  value: string;
}

export interface AWSConfig {
  accessKeyId: string;
  secretAccessKey: string;
  region: string;
  bucket: string;
}

export interface GCPConfig {
  credentialsPath: string;
  bucket: string;
}

/** HydrationJob sent to control agent for hydrating archived logs */
export interface HydrationJobEvent {
  siteId: string;
  hydrationJobId: string;
  timeRange: TimeRange | undefined;
  pipelineConfig: { [key: string]: any } | undefined;
  keyPrefix: string[];
  archivalSourceType: ArchivalSourceType;
  archivalDataFormat: ArchivalDataFormat;
  awsConfig?: AWSConfig | undefined;
  gcpConfig?: GCPConfig | undefined;
  resource: HydrationJobResource | undefined;
}

/** DeleteHydrationJobEvent deletes ongoing hydration job */
export interface DeleteHydrationJobEvent {
  siteId: string;
  hydrationJobId: string;
}

/** DynamicLookupJobEvent sent to control-agent for creating Dynamic lookup job */
export interface DynamicLookupJobEvent {
  siteId: string;
  jobId: string;
  fileName: string;
  fileSchema: { [key: string]: any } | undefined;
  cron: string;
  script: Uint8Array;
  secrets: string[];
  resource: JobResource | undefined;
}

/** DeleteDynamicLookupJobEvent deletes an Dynamic lookup job */
export interface DeleteDynamicLookupJobEvent {
  siteId: string;
  jobId: string;
  fileName: string;
}

function createBaseUpdatePipelineEvent(): UpdatePipelineEvent {
  return { pipelineId: "0", graphVersion: 0 };
}

export const UpdatePipelineEvent = {
  encode(message: UpdatePipelineEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pipelineId !== "0") {
      writer.uint32(8).uint64(message.pipelineId);
    }
    if (message.graphVersion !== 0) {
      writer.uint32(16).uint32(message.graphVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePipelineEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePipelineEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pipelineId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.graphVersion = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdatePipelineEvent {
    return {
      pipelineId: isSet(object.pipelineId) ? globalThis.String(object.pipelineId) : "0",
      graphVersion: isSet(object.graphVersion) ? globalThis.Number(object.graphVersion) : 0,
    };
  },

  toJSON(message: UpdatePipelineEvent): unknown {
    const obj: any = {};
    if (message.pipelineId !== "0") {
      obj.pipelineId = message.pipelineId;
    }
    if (message.graphVersion !== 0) {
      obj.graphVersion = Math.round(message.graphVersion);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdatePipelineEvent>, I>>(base?: I): UpdatePipelineEvent {
    return UpdatePipelineEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdatePipelineEvent>, I>>(object: I): UpdatePipelineEvent {
    const message = createBaseUpdatePipelineEvent();
    message.pipelineId = object.pipelineId ?? "0";
    message.graphVersion = object.graphVersion ?? 0;
    return message;
  },
};

function createBaseDeletePipelineEvent(): DeletePipelineEvent {
  return { pipelineId: "0" };
}

export const DeletePipelineEvent = {
  encode(message: DeletePipelineEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pipelineId !== "0") {
      writer.uint32(8).uint64(message.pipelineId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeletePipelineEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePipelineEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pipelineId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeletePipelineEvent {
    return { pipelineId: isSet(object.pipelineId) ? globalThis.String(object.pipelineId) : "0" };
  },

  toJSON(message: DeletePipelineEvent): unknown {
    const obj: any = {};
    if (message.pipelineId !== "0") {
      obj.pipelineId = message.pipelineId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeletePipelineEvent>, I>>(base?: I): DeletePipelineEvent {
    return DeletePipelineEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeletePipelineEvent>, I>>(object: I): DeletePipelineEvent {
    const message = createBaseDeletePipelineEvent();
    message.pipelineId = object.pipelineId ?? "0";
    return message;
  },
};

function createBaseCaptureLogEvent(): CaptureLogEvent {
  return { siteId: "0", componentIds: [], numLogLines: 0, timeoutMs: "0" };
}

export const CaptureLogEvent = {
  encode(message: CaptureLogEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.siteId !== "0") {
      writer.uint32(8).uint64(message.siteId);
    }
    for (const v of message.componentIds) {
      writer.uint32(18).string(v!);
    }
    if (message.numLogLines !== 0) {
      writer.uint32(24).uint32(message.numLogLines);
    }
    if (message.timeoutMs !== "0") {
      writer.uint32(32).int64(message.timeoutMs);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CaptureLogEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCaptureLogEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.componentIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.numLogLines = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timeoutMs = longToString(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CaptureLogEvent {
    return {
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      componentIds: globalThis.Array.isArray(object?.componentIds)
        ? object.componentIds.map((e: any) => globalThis.String(e))
        : [],
      numLogLines: isSet(object.numLogLines) ? globalThis.Number(object.numLogLines) : 0,
      timeoutMs: isSet(object.timeoutMs) ? globalThis.String(object.timeoutMs) : "0",
    };
  },

  toJSON(message: CaptureLogEvent): unknown {
    const obj: any = {};
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.componentIds?.length) {
      obj.componentIds = message.componentIds;
    }
    if (message.numLogLines !== 0) {
      obj.numLogLines = Math.round(message.numLogLines);
    }
    if (message.timeoutMs !== "0") {
      obj.timeoutMs = message.timeoutMs;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CaptureLogEvent>, I>>(base?: I): CaptureLogEvent {
    return CaptureLogEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CaptureLogEvent>, I>>(object: I): CaptureLogEvent {
    const message = createBaseCaptureLogEvent();
    message.siteId = object.siteId ?? "0";
    message.componentIds = object.componentIds?.map((e) => e) || [];
    message.numLogLines = object.numLogLines ?? 0;
    message.timeoutMs = object.timeoutMs ?? "0";
    return message;
  },
};

function createBaseDeleteSiteFile(): DeleteSiteFile {
  return { sitefileId: "0", filename: "" };
}

export const DeleteSiteFile = {
  encode(message: DeleteSiteFile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sitefileId !== "0") {
      writer.uint32(8).uint64(message.sitefileId);
    }
    if (message.filename !== "") {
      writer.uint32(18).string(message.filename);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteSiteFile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSiteFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sitefileId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filename = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteSiteFile {
    return {
      sitefileId: isSet(object.sitefileId) ? globalThis.String(object.sitefileId) : "0",
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
    };
  },

  toJSON(message: DeleteSiteFile): unknown {
    const obj: any = {};
    if (message.sitefileId !== "0") {
      obj.sitefileId = message.sitefileId;
    }
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteSiteFile>, I>>(base?: I): DeleteSiteFile {
    return DeleteSiteFile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteSiteFile>, I>>(object: I): DeleteSiteFile {
    const message = createBaseDeleteSiteFile();
    message.sitefileId = object.sitefileId ?? "0";
    message.filename = object.filename ?? "";
    return message;
  },
};

function createBaseDeleteSource(): DeleteSource {
  return { sourceId: "0" };
}

export const DeleteSource = {
  encode(message: DeleteSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sourceId !== "0") {
      writer.uint32(8).uint64(message.sourceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sourceId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteSource {
    return { sourceId: isSet(object.sourceId) ? globalThis.String(object.sourceId) : "0" };
  },

  toJSON(message: DeleteSource): unknown {
    const obj: any = {};
    if (message.sourceId !== "0") {
      obj.sourceId = message.sourceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteSource>, I>>(base?: I): DeleteSource {
    return DeleteSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteSource>, I>>(object: I): DeleteSource {
    const message = createBaseDeleteSource();
    message.sourceId = object.sourceId ?? "0";
    return message;
  },
};

function createBaseDeleteSink(): DeleteSink {
  return { sinkId: "0" };
}

export const DeleteSink = {
  encode(message: DeleteSink, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sinkId !== "0") {
      writer.uint32(8).uint64(message.sinkId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteSink {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sinkId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteSink {
    return { sinkId: isSet(object.sinkId) ? globalThis.String(object.sinkId) : "0" };
  },

  toJSON(message: DeleteSink): unknown {
    const obj: any = {};
    if (message.sinkId !== "0") {
      obj.sinkId = message.sinkId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteSink>, I>>(base?: I): DeleteSink {
    return DeleteSink.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteSink>, I>>(object: I): DeleteSink {
    const message = createBaseDeleteSink();
    message.sinkId = object.sinkId ?? "0";
    return message;
  },
};

function createBaseUpsertSink(): UpsertSink {
  return { siteId: "0", sinkId: "0" };
}

export const UpsertSink = {
  encode(message: UpsertSink, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.siteId !== "0") {
      writer.uint32(8).uint64(message.siteId);
    }
    if (message.sinkId !== "0") {
      writer.uint32(16).uint64(message.sinkId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpsertSink {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpsertSink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sinkId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpsertSink {
    return {
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      sinkId: isSet(object.sinkId) ? globalThis.String(object.sinkId) : "0",
    };
  },

  toJSON(message: UpsertSink): unknown {
    const obj: any = {};
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.sinkId !== "0") {
      obj.sinkId = message.sinkId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpsertSink>, I>>(base?: I): UpsertSink {
    return UpsertSink.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpsertSink>, I>>(object: I): UpsertSink {
    const message = createBaseUpsertSink();
    message.siteId = object.siteId ?? "0";
    message.sinkId = object.sinkId ?? "0";
    return message;
  },
};

function createBaseUpsertSource(): UpsertSource {
  return { siteId: "0", sourceId: "0" };
}

export const UpsertSource = {
  encode(message: UpsertSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.siteId !== "0") {
      writer.uint32(8).uint64(message.siteId);
    }
    if (message.sourceId !== "0") {
      writer.uint32(16).uint64(message.sourceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpsertSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpsertSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sourceId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpsertSource {
    return {
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      sourceId: isSet(object.sourceId) ? globalThis.String(object.sourceId) : "0",
    };
  },

  toJSON(message: UpsertSource): unknown {
    const obj: any = {};
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.sourceId !== "0") {
      obj.sourceId = message.sourceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpsertSource>, I>>(base?: I): UpsertSource {
    return UpsertSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpsertSource>, I>>(object: I): UpsertSource {
    const message = createBaseUpsertSource();
    message.siteId = object.siteId ?? "0";
    message.sourceId = object.sourceId ?? "0";
    return message;
  },
};

function createBaseAlertTriggered(): AlertTriggered {
  return { siteId: "0", alertId: "0", labels: {}, currentValue: 0, triggerTime: undefined };
}

export const AlertTriggered = {
  encode(message: AlertTriggered, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.siteId !== "0") {
      writer.uint32(8).uint64(message.siteId);
    }
    if (message.alertId !== "0") {
      writer.uint32(16).uint64(message.alertId);
    }
    Object.entries(message.labels).forEach(([key, value]) => {
      AlertTriggered_LabelsEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    if (message.currentValue !== 0) {
      writer.uint32(33).double(message.currentValue);
    }
    if (message.triggerTime !== undefined) {
      Timestamp.encode(toTimestamp(message.triggerTime), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AlertTriggered {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlertTriggered();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.alertId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = AlertTriggered_LabelsEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.labels[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.currentValue = reader.double();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.triggerTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlertTriggered {
    return {
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      alertId: isSet(object.alertId) ? globalThis.String(object.alertId) : "0",
      labels: isObject(object.labels)
        ? Object.entries(object.labels).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      currentValue: isSet(object.currentValue) ? globalThis.Number(object.currentValue) : 0,
      triggerTime: isSet(object.triggerTime) ? globalThis.String(object.triggerTime) : undefined,
    };
  },

  toJSON(message: AlertTriggered): unknown {
    const obj: any = {};
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.alertId !== "0") {
      obj.alertId = message.alertId;
    }
    if (message.labels) {
      const entries = Object.entries(message.labels);
      if (entries.length > 0) {
        obj.labels = {};
        entries.forEach(([k, v]) => {
          obj.labels[k] = v;
        });
      }
    }
    if (message.currentValue !== 0) {
      obj.currentValue = message.currentValue;
    }
    if (message.triggerTime !== undefined) {
      obj.triggerTime = message.triggerTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlertTriggered>, I>>(base?: I): AlertTriggered {
    return AlertTriggered.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlertTriggered>, I>>(object: I): AlertTriggered {
    const message = createBaseAlertTriggered();
    message.siteId = object.siteId ?? "0";
    message.alertId = object.alertId ?? "0";
    message.labels = Object.entries(object.labels ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.currentValue = object.currentValue ?? 0;
    message.triggerTime = object.triggerTime ?? undefined;
    return message;
  },
};

function createBaseAlertTriggered_LabelsEntry(): AlertTriggered_LabelsEntry {
  return { key: "", value: "" };
}

export const AlertTriggered_LabelsEntry = {
  encode(message: AlertTriggered_LabelsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AlertTriggered_LabelsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlertTriggered_LabelsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlertTriggered_LabelsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: AlertTriggered_LabelsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlertTriggered_LabelsEntry>, I>>(base?: I): AlertTriggered_LabelsEntry {
    return AlertTriggered_LabelsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlertTriggered_LabelsEntry>, I>>(object: I): AlertTriggered_LabelsEntry {
    const message = createBaseAlertTriggered_LabelsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseAWSConfig(): AWSConfig {
  return { accessKeyId: "", secretAccessKey: "", region: "", bucket: "" };
}

export const AWSConfig = {
  encode(message: AWSConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accessKeyId !== "") {
      writer.uint32(10).string(message.accessKeyId);
    }
    if (message.secretAccessKey !== "") {
      writer.uint32(18).string(message.secretAccessKey);
    }
    if (message.region !== "") {
      writer.uint32(26).string(message.region);
    }
    if (message.bucket !== "") {
      writer.uint32(34).string(message.bucket);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AWSConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAWSConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accessKeyId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.secretAccessKey = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.region = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bucket = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AWSConfig {
    return {
      accessKeyId: isSet(object.accessKeyId) ? globalThis.String(object.accessKeyId) : "",
      secretAccessKey: isSet(object.secretAccessKey) ? globalThis.String(object.secretAccessKey) : "",
      region: isSet(object.region) ? globalThis.String(object.region) : "",
      bucket: isSet(object.bucket) ? globalThis.String(object.bucket) : "",
    };
  },

  toJSON(message: AWSConfig): unknown {
    const obj: any = {};
    if (message.accessKeyId !== "") {
      obj.accessKeyId = message.accessKeyId;
    }
    if (message.secretAccessKey !== "") {
      obj.secretAccessKey = message.secretAccessKey;
    }
    if (message.region !== "") {
      obj.region = message.region;
    }
    if (message.bucket !== "") {
      obj.bucket = message.bucket;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AWSConfig>, I>>(base?: I): AWSConfig {
    return AWSConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AWSConfig>, I>>(object: I): AWSConfig {
    const message = createBaseAWSConfig();
    message.accessKeyId = object.accessKeyId ?? "";
    message.secretAccessKey = object.secretAccessKey ?? "";
    message.region = object.region ?? "";
    message.bucket = object.bucket ?? "";
    return message;
  },
};

function createBaseGCPConfig(): GCPConfig {
  return { credentialsPath: "", bucket: "" };
}

export const GCPConfig = {
  encode(message: GCPConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.credentialsPath !== "") {
      writer.uint32(10).string(message.credentialsPath);
    }
    if (message.bucket !== "") {
      writer.uint32(18).string(message.bucket);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GCPConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGCPConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.credentialsPath = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bucket = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GCPConfig {
    return {
      credentialsPath: isSet(object.credentialsPath) ? globalThis.String(object.credentialsPath) : "",
      bucket: isSet(object.bucket) ? globalThis.String(object.bucket) : "",
    };
  },

  toJSON(message: GCPConfig): unknown {
    const obj: any = {};
    if (message.credentialsPath !== "") {
      obj.credentialsPath = message.credentialsPath;
    }
    if (message.bucket !== "") {
      obj.bucket = message.bucket;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GCPConfig>, I>>(base?: I): GCPConfig {
    return GCPConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GCPConfig>, I>>(object: I): GCPConfig {
    const message = createBaseGCPConfig();
    message.credentialsPath = object.credentialsPath ?? "";
    message.bucket = object.bucket ?? "";
    return message;
  },
};

function createBaseHydrationJobEvent(): HydrationJobEvent {
  return {
    siteId: "0",
    hydrationJobId: "0",
    timeRange: undefined,
    pipelineConfig: undefined,
    keyPrefix: [],
    archivalSourceType: 0,
    archivalDataFormat: 0,
    awsConfig: undefined,
    gcpConfig: undefined,
    resource: undefined,
  };
}

export const HydrationJobEvent = {
  encode(message: HydrationJobEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.siteId !== "0") {
      writer.uint32(8).uint64(message.siteId);
    }
    if (message.hydrationJobId !== "0") {
      writer.uint32(16).uint64(message.hydrationJobId);
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(26).fork()).ldelim();
    }
    if (message.pipelineConfig !== undefined) {
      Struct.encode(Struct.wrap(message.pipelineConfig), writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.keyPrefix) {
      writer.uint32(42).string(v!);
    }
    if (message.archivalSourceType !== 0) {
      writer.uint32(48).int32(message.archivalSourceType);
    }
    if (message.archivalDataFormat !== 0) {
      writer.uint32(56).int32(message.archivalDataFormat);
    }
    if (message.awsConfig !== undefined) {
      AWSConfig.encode(message.awsConfig, writer.uint32(66).fork()).ldelim();
    }
    if (message.gcpConfig !== undefined) {
      GCPConfig.encode(message.gcpConfig, writer.uint32(74).fork()).ldelim();
    }
    if (message.resource !== undefined) {
      HydrationJobResource.encode(message.resource, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HydrationJobEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHydrationJobEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hydrationJobId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pipelineConfig = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.keyPrefix.push(reader.string());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.archivalSourceType = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.archivalDataFormat = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.awsConfig = AWSConfig.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.gcpConfig = GCPConfig.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.resource = HydrationJobResource.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HydrationJobEvent {
    return {
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      hydrationJobId: isSet(object.hydrationJobId) ? globalThis.String(object.hydrationJobId) : "0",
      timeRange: isSet(object.timeRange) ? TimeRange.fromJSON(object.timeRange) : undefined,
      pipelineConfig: isObject(object.pipelineConfig) ? object.pipelineConfig : undefined,
      keyPrefix: globalThis.Array.isArray(object?.keyPrefix)
        ? object.keyPrefix.map((e: any) => globalThis.String(e))
        : [],
      archivalSourceType: isSet(object.archivalSourceType) ? archivalSourceTypeFromJSON(object.archivalSourceType) : 0,
      archivalDataFormat: isSet(object.archivalDataFormat) ? archivalDataFormatFromJSON(object.archivalDataFormat) : 0,
      awsConfig: isSet(object.awsConfig) ? AWSConfig.fromJSON(object.awsConfig) : undefined,
      gcpConfig: isSet(object.gcpConfig) ? GCPConfig.fromJSON(object.gcpConfig) : undefined,
      resource: isSet(object.resource) ? HydrationJobResource.fromJSON(object.resource) : undefined,
    };
  },

  toJSON(message: HydrationJobEvent): unknown {
    const obj: any = {};
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.hydrationJobId !== "0") {
      obj.hydrationJobId = message.hydrationJobId;
    }
    if (message.timeRange !== undefined) {
      obj.timeRange = TimeRange.toJSON(message.timeRange);
    }
    if (message.pipelineConfig !== undefined) {
      obj.pipelineConfig = message.pipelineConfig;
    }
    if (message.keyPrefix?.length) {
      obj.keyPrefix = message.keyPrefix;
    }
    if (message.archivalSourceType !== 0) {
      obj.archivalSourceType = archivalSourceTypeToJSON(message.archivalSourceType);
    }
    if (message.archivalDataFormat !== 0) {
      obj.archivalDataFormat = archivalDataFormatToJSON(message.archivalDataFormat);
    }
    if (message.awsConfig !== undefined) {
      obj.awsConfig = AWSConfig.toJSON(message.awsConfig);
    }
    if (message.gcpConfig !== undefined) {
      obj.gcpConfig = GCPConfig.toJSON(message.gcpConfig);
    }
    if (message.resource !== undefined) {
      obj.resource = HydrationJobResource.toJSON(message.resource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HydrationJobEvent>, I>>(base?: I): HydrationJobEvent {
    return HydrationJobEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HydrationJobEvent>, I>>(object: I): HydrationJobEvent {
    const message = createBaseHydrationJobEvent();
    message.siteId = object.siteId ?? "0";
    message.hydrationJobId = object.hydrationJobId ?? "0";
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    message.pipelineConfig = object.pipelineConfig ?? undefined;
    message.keyPrefix = object.keyPrefix?.map((e) => e) || [];
    message.archivalSourceType = object.archivalSourceType ?? 0;
    message.archivalDataFormat = object.archivalDataFormat ?? 0;
    message.awsConfig = (object.awsConfig !== undefined && object.awsConfig !== null)
      ? AWSConfig.fromPartial(object.awsConfig)
      : undefined;
    message.gcpConfig = (object.gcpConfig !== undefined && object.gcpConfig !== null)
      ? GCPConfig.fromPartial(object.gcpConfig)
      : undefined;
    message.resource = (object.resource !== undefined && object.resource !== null)
      ? HydrationJobResource.fromPartial(object.resource)
      : undefined;
    return message;
  },
};

function createBaseDeleteHydrationJobEvent(): DeleteHydrationJobEvent {
  return { siteId: "0", hydrationJobId: "0" };
}

export const DeleteHydrationJobEvent = {
  encode(message: DeleteHydrationJobEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.siteId !== "0") {
      writer.uint32(8).uint64(message.siteId);
    }
    if (message.hydrationJobId !== "0") {
      writer.uint32(16).uint64(message.hydrationJobId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteHydrationJobEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteHydrationJobEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hydrationJobId = longToString(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteHydrationJobEvent {
    return {
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      hydrationJobId: isSet(object.hydrationJobId) ? globalThis.String(object.hydrationJobId) : "0",
    };
  },

  toJSON(message: DeleteHydrationJobEvent): unknown {
    const obj: any = {};
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.hydrationJobId !== "0") {
      obj.hydrationJobId = message.hydrationJobId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteHydrationJobEvent>, I>>(base?: I): DeleteHydrationJobEvent {
    return DeleteHydrationJobEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteHydrationJobEvent>, I>>(object: I): DeleteHydrationJobEvent {
    const message = createBaseDeleteHydrationJobEvent();
    message.siteId = object.siteId ?? "0";
    message.hydrationJobId = object.hydrationJobId ?? "0";
    return message;
  },
};

function createBaseDynamicLookupJobEvent(): DynamicLookupJobEvent {
  return {
    siteId: "0",
    jobId: "0",
    fileName: "",
    fileSchema: undefined,
    cron: "",
    script: new Uint8Array(0),
    secrets: [],
    resource: undefined,
  };
}

export const DynamicLookupJobEvent = {
  encode(message: DynamicLookupJobEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.siteId !== "0") {
      writer.uint32(8).uint64(message.siteId);
    }
    if (message.jobId !== "0") {
      writer.uint32(16).uint64(message.jobId);
    }
    if (message.fileName !== "") {
      writer.uint32(26).string(message.fileName);
    }
    if (message.fileSchema !== undefined) {
      Struct.encode(Struct.wrap(message.fileSchema), writer.uint32(34).fork()).ldelim();
    }
    if (message.cron !== "") {
      writer.uint32(42).string(message.cron);
    }
    if (message.script.length !== 0) {
      writer.uint32(50).bytes(message.script);
    }
    for (const v of message.secrets) {
      writer.uint32(58).string(v!);
    }
    if (message.resource !== undefined) {
      JobResource.encode(message.resource, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynamicLookupJobEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynamicLookupJobEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fileName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fileSchema = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.cron = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.script = reader.bytes();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.secrets.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.resource = JobResource.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynamicLookupJobEvent {
    return {
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "0",
      fileName: isSet(object.fileName) ? globalThis.String(object.fileName) : "",
      fileSchema: isObject(object.fileSchema) ? object.fileSchema : undefined,
      cron: isSet(object.cron) ? globalThis.String(object.cron) : "",
      script: isSet(object.script) ? bytesFromBase64(object.script) : new Uint8Array(0),
      secrets: globalThis.Array.isArray(object?.secrets) ? object.secrets.map((e: any) => globalThis.String(e)) : [],
      resource: isSet(object.resource) ? JobResource.fromJSON(object.resource) : undefined,
    };
  },

  toJSON(message: DynamicLookupJobEvent): unknown {
    const obj: any = {};
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.jobId !== "0") {
      obj.jobId = message.jobId;
    }
    if (message.fileName !== "") {
      obj.fileName = message.fileName;
    }
    if (message.fileSchema !== undefined) {
      obj.fileSchema = message.fileSchema;
    }
    if (message.cron !== "") {
      obj.cron = message.cron;
    }
    if (message.script.length !== 0) {
      obj.script = base64FromBytes(message.script);
    }
    if (message.secrets?.length) {
      obj.secrets = message.secrets;
    }
    if (message.resource !== undefined) {
      obj.resource = JobResource.toJSON(message.resource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DynamicLookupJobEvent>, I>>(base?: I): DynamicLookupJobEvent {
    return DynamicLookupJobEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DynamicLookupJobEvent>, I>>(object: I): DynamicLookupJobEvent {
    const message = createBaseDynamicLookupJobEvent();
    message.siteId = object.siteId ?? "0";
    message.jobId = object.jobId ?? "0";
    message.fileName = object.fileName ?? "";
    message.fileSchema = object.fileSchema ?? undefined;
    message.cron = object.cron ?? "";
    message.script = object.script ?? new Uint8Array(0);
    message.secrets = object.secrets?.map((e) => e) || [];
    message.resource = (object.resource !== undefined && object.resource !== null)
      ? JobResource.fromPartial(object.resource)
      : undefined;
    return message;
  },
};

function createBaseDeleteDynamicLookupJobEvent(): DeleteDynamicLookupJobEvent {
  return { siteId: "0", jobId: "0", fileName: "" };
}

export const DeleteDynamicLookupJobEvent = {
  encode(message: DeleteDynamicLookupJobEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.siteId !== "0") {
      writer.uint32(8).uint64(message.siteId);
    }
    if (message.jobId !== "0") {
      writer.uint32(16).uint64(message.jobId);
    }
    if (message.fileName !== "") {
      writer.uint32(26).string(message.fileName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteDynamicLookupJobEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteDynamicLookupJobEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fileName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteDynamicLookupJobEvent {
    return {
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "0",
      fileName: isSet(object.fileName) ? globalThis.String(object.fileName) : "",
    };
  },

  toJSON(message: DeleteDynamicLookupJobEvent): unknown {
    const obj: any = {};
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.jobId !== "0") {
      obj.jobId = message.jobId;
    }
    if (message.fileName !== "") {
      obj.fileName = message.fileName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteDynamicLookupJobEvent>, I>>(base?: I): DeleteDynamicLookupJobEvent {
    return DeleteDynamicLookupJobEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteDynamicLookupJobEvent>, I>>(object: I): DeleteDynamicLookupJobEvent {
    const message = createBaseDeleteDynamicLookupJobEvent();
    message.siteId = object.siteId ?? "0";
    message.jobId = object.jobId ?? "0";
    message.fileName = object.fileName ?? "";
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
