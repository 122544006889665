import { Site, Site_Status, site_StatusFromJSON } from '@common-types/site_manager/model/v1/site';
import { SITE_NEVER_USED } from 'src/constants/siteCreation';

export const isSite = (site?: Site): site is Site => Boolean(site && site.id);

export const isSiteEverUsed = (site?: Site): site is Site & boolean =>
  isSite(site) && site.lastAlive !== SITE_NEVER_USED;

export const isSiteActiveAndAlive = (site?: Site): site is Site & boolean =>
  isSiteEverUsed(site) && site_StatusFromJSON(site.status) === Site_Status.ACTIVE;
