import { EffectCallback, useEffect, useRef } from 'react';

function useOnMountEffect(effect: EffectCallback) {
  const mounted = useRef(false);

  return useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useOnMountEffect;
