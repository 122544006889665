/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../../google/protobuf/timestamp";
import { TimeRange } from "../../../common/v1/common";

export const protobufPackage = "v1.site_manager";

/** Next ID: 7 */
export enum HydrationStatus {
  HYDRATION_JOB_STATUS_DEFAULT = 0,
  HYDRATION_JOB_STATUS_DEPLOYING = 1,
  HYDRATION_JOB_STATUS_IN_PROGRESS = 2,
  HYDRATION_JOB_STATUS_COMPLETED = 3,
  HYDRATION_JOB_STATUS_FAILED = 4,
  HYDRATION_JOB_STATUS_DELETED = 5,
  HYDRATION_JOB_STATUS_TERMINATED = 6,
  UNRECOGNIZED = -1,
}

export function hydrationStatusFromJSON(object: any): HydrationStatus {
  switch (object) {
    case 0:
    case "HYDRATION_JOB_STATUS_DEFAULT":
      return HydrationStatus.HYDRATION_JOB_STATUS_DEFAULT;
    case 1:
    case "HYDRATION_JOB_STATUS_DEPLOYING":
      return HydrationStatus.HYDRATION_JOB_STATUS_DEPLOYING;
    case 2:
    case "HYDRATION_JOB_STATUS_IN_PROGRESS":
      return HydrationStatus.HYDRATION_JOB_STATUS_IN_PROGRESS;
    case 3:
    case "HYDRATION_JOB_STATUS_COMPLETED":
      return HydrationStatus.HYDRATION_JOB_STATUS_COMPLETED;
    case 4:
    case "HYDRATION_JOB_STATUS_FAILED":
      return HydrationStatus.HYDRATION_JOB_STATUS_FAILED;
    case 5:
    case "HYDRATION_JOB_STATUS_DELETED":
      return HydrationStatus.HYDRATION_JOB_STATUS_DELETED;
    case 6:
    case "HYDRATION_JOB_STATUS_TERMINATED":
      return HydrationStatus.HYDRATION_JOB_STATUS_TERMINATED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HydrationStatus.UNRECOGNIZED;
  }
}

export function hydrationStatusToJSON(object: HydrationStatus): string {
  switch (object) {
    case HydrationStatus.HYDRATION_JOB_STATUS_DEFAULT:
      return "HYDRATION_JOB_STATUS_DEFAULT";
    case HydrationStatus.HYDRATION_JOB_STATUS_DEPLOYING:
      return "HYDRATION_JOB_STATUS_DEPLOYING";
    case HydrationStatus.HYDRATION_JOB_STATUS_IN_PROGRESS:
      return "HYDRATION_JOB_STATUS_IN_PROGRESS";
    case HydrationStatus.HYDRATION_JOB_STATUS_COMPLETED:
      return "HYDRATION_JOB_STATUS_COMPLETED";
    case HydrationStatus.HYDRATION_JOB_STATUS_FAILED:
      return "HYDRATION_JOB_STATUS_FAILED";
    case HydrationStatus.HYDRATION_JOB_STATUS_DELETED:
      return "HYDRATION_JOB_STATUS_DELETED";
    case HydrationStatus.HYDRATION_JOB_STATUS_TERMINATED:
      return "HYDRATION_JOB_STATUS_TERMINATED";
    case HydrationStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface HydrationJob {
  id: string;
  siteId: string;
  /** Source whose data is being hydrated */
  sourceId: string;
  /** Sink where data is being stored */
  sinkId: string;
  /** Time range of the data to be hydrated */
  timeRange: TimeRange | undefined;
  status: HydrationStatus;
  /** List of hosts */
  hosts: string[];
  created: string | undefined;
  updated: string | undefined;
  createdBy: string;
  updatedBy: string;
  /** Add addHydration tag on each of hydrated log lines */
  addHydrationTag: boolean;
  /** Current health of hydration job */
  health: HydrationJobHealth | undefined;
  reason: string;
  resource: HydrationJobResource | undefined;
}

export interface HydrationJobHealth {
  id: string;
  jobId: string;
  siteId: string;
  status: HydrationStatus;
  /** total data need to processed by hydration job */
  totalData: string;
  /** data processed so far by hydration job */
  dataProcessed: string;
  createdBy: string;
  updatedBy: string;
  totalFilesSkipped: string;
  latestSkippedFilesList: string[];
}

export interface HydrationJobResource {
  cpuRequest?: number | undefined;
  cpuLimit?: number | undefined;
  memoryRequest?: number | undefined;
  memoryLimit?: number | undefined;
}

function createBaseHydrationJob(): HydrationJob {
  return {
    id: "0",
    siteId: "0",
    sourceId: "0",
    sinkId: "0",
    timeRange: undefined,
    status: 0,
    hosts: [],
    created: undefined,
    updated: undefined,
    createdBy: "",
    updatedBy: "",
    addHydrationTag: false,
    health: undefined,
    reason: "",
    resource: undefined,
  };
}

export const HydrationJob = {
  encode(message: HydrationJob, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.siteId !== "0") {
      writer.uint32(16).uint64(message.siteId);
    }
    if (message.sourceId !== "0") {
      writer.uint32(24).uint64(message.sourceId);
    }
    if (message.sinkId !== "0") {
      writer.uint32(32).uint64(message.sinkId);
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(42).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(48).int32(message.status);
    }
    for (const v of message.hosts) {
      writer.uint32(58).string(v!);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(66).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(74).fork()).ldelim();
    }
    if (message.createdBy !== "") {
      writer.uint32(82).string(message.createdBy);
    }
    if (message.updatedBy !== "") {
      writer.uint32(90).string(message.updatedBy);
    }
    if (message.addHydrationTag !== false) {
      writer.uint32(96).bool(message.addHydrationTag);
    }
    if (message.health !== undefined) {
      HydrationJobHealth.encode(message.health, writer.uint32(106).fork()).ldelim();
    }
    if (message.reason !== "") {
      writer.uint32(114).string(message.reason);
    }
    if (message.resource !== undefined) {
      HydrationJobResource.encode(message.resource, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HydrationJob {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHydrationJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.sourceId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sinkId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.hosts.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.updatedBy = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.addHydrationTag = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.health = HydrationJobHealth.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.reason = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.resource = HydrationJobResource.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HydrationJob {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "0",
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      sourceId: isSet(object.sourceId) ? globalThis.String(object.sourceId) : "0",
      sinkId: isSet(object.sinkId) ? globalThis.String(object.sinkId) : "0",
      timeRange: isSet(object.timeRange) ? TimeRange.fromJSON(object.timeRange) : undefined,
      status: isSet(object.status) ? hydrationStatusFromJSON(object.status) : 0,
      hosts: globalThis.Array.isArray(object?.hosts) ? object.hosts.map((e: any) => globalThis.String(e)) : [],
      created: isSet(object.created) ? globalThis.String(object.created) : undefined,
      updated: isSet(object.updated) ? globalThis.String(object.updated) : undefined,
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      updatedBy: isSet(object.updatedBy) ? globalThis.String(object.updatedBy) : "",
      addHydrationTag: isSet(object.addHydrationTag) ? globalThis.Boolean(object.addHydrationTag) : false,
      health: isSet(object.health) ? HydrationJobHealth.fromJSON(object.health) : undefined,
      reason: isSet(object.reason) ? globalThis.String(object.reason) : "",
      resource: isSet(object.resource) ? HydrationJobResource.fromJSON(object.resource) : undefined,
    };
  },

  toJSON(message: HydrationJob): unknown {
    const obj: any = {};
    if (message.id !== "0") {
      obj.id = message.id;
    }
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.sourceId !== "0") {
      obj.sourceId = message.sourceId;
    }
    if (message.sinkId !== "0") {
      obj.sinkId = message.sinkId;
    }
    if (message.timeRange !== undefined) {
      obj.timeRange = TimeRange.toJSON(message.timeRange);
    }
    if (message.status !== 0) {
      obj.status = hydrationStatusToJSON(message.status);
    }
    if (message.hosts?.length) {
      obj.hosts = message.hosts;
    }
    if (message.created !== undefined) {
      obj.created = message.created;
    }
    if (message.updated !== undefined) {
      obj.updated = message.updated;
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.updatedBy !== "") {
      obj.updatedBy = message.updatedBy;
    }
    if (message.addHydrationTag !== false) {
      obj.addHydrationTag = message.addHydrationTag;
    }
    if (message.health !== undefined) {
      obj.health = HydrationJobHealth.toJSON(message.health);
    }
    if (message.reason !== "") {
      obj.reason = message.reason;
    }
    if (message.resource !== undefined) {
      obj.resource = HydrationJobResource.toJSON(message.resource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HydrationJob>, I>>(base?: I): HydrationJob {
    return HydrationJob.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HydrationJob>, I>>(object: I): HydrationJob {
    const message = createBaseHydrationJob();
    message.id = object.id ?? "0";
    message.siteId = object.siteId ?? "0";
    message.sourceId = object.sourceId ?? "0";
    message.sinkId = object.sinkId ?? "0";
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    message.status = object.status ?? 0;
    message.hosts = object.hosts?.map((e) => e) || [];
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.createdBy = object.createdBy ?? "";
    message.updatedBy = object.updatedBy ?? "";
    message.addHydrationTag = object.addHydrationTag ?? false;
    message.health = (object.health !== undefined && object.health !== null)
      ? HydrationJobHealth.fromPartial(object.health)
      : undefined;
    message.reason = object.reason ?? "";
    message.resource = (object.resource !== undefined && object.resource !== null)
      ? HydrationJobResource.fromPartial(object.resource)
      : undefined;
    return message;
  },
};

function createBaseHydrationJobHealth(): HydrationJobHealth {
  return {
    id: "0",
    jobId: "0",
    siteId: "0",
    status: 0,
    totalData: "0",
    dataProcessed: "0",
    createdBy: "",
    updatedBy: "",
    totalFilesSkipped: "0",
    latestSkippedFilesList: [],
  };
}

export const HydrationJobHealth = {
  encode(message: HydrationJobHealth, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.jobId !== "0") {
      writer.uint32(16).uint64(message.jobId);
    }
    if (message.siteId !== "0") {
      writer.uint32(24).uint64(message.siteId);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.totalData !== "0") {
      writer.uint32(40).uint64(message.totalData);
    }
    if (message.dataProcessed !== "0") {
      writer.uint32(48).uint64(message.dataProcessed);
    }
    if (message.createdBy !== "") {
      writer.uint32(58).string(message.createdBy);
    }
    if (message.updatedBy !== "") {
      writer.uint32(66).string(message.updatedBy);
    }
    if (message.totalFilesSkipped !== "0") {
      writer.uint32(72).uint64(message.totalFilesSkipped);
    }
    for (const v of message.latestSkippedFilesList) {
      writer.uint32(90).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HydrationJobHealth {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHydrationJobHealth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.totalData = longToString(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.dataProcessed = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.updatedBy = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.totalFilesSkipped = longToString(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.latestSkippedFilesList.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HydrationJobHealth {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "0",
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "0",
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      status: isSet(object.status) ? hydrationStatusFromJSON(object.status) : 0,
      totalData: isSet(object.totalData) ? globalThis.String(object.totalData) : "0",
      dataProcessed: isSet(object.dataProcessed) ? globalThis.String(object.dataProcessed) : "0",
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      updatedBy: isSet(object.updatedBy) ? globalThis.String(object.updatedBy) : "",
      totalFilesSkipped: isSet(object.totalFilesSkipped) ? globalThis.String(object.totalFilesSkipped) : "0",
      latestSkippedFilesList: globalThis.Array.isArray(object?.latestSkippedFilesList)
        ? object.latestSkippedFilesList.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: HydrationJobHealth): unknown {
    const obj: any = {};
    if (message.id !== "0") {
      obj.id = message.id;
    }
    if (message.jobId !== "0") {
      obj.jobId = message.jobId;
    }
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.status !== 0) {
      obj.status = hydrationStatusToJSON(message.status);
    }
    if (message.totalData !== "0") {
      obj.totalData = message.totalData;
    }
    if (message.dataProcessed !== "0") {
      obj.dataProcessed = message.dataProcessed;
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.updatedBy !== "") {
      obj.updatedBy = message.updatedBy;
    }
    if (message.totalFilesSkipped !== "0") {
      obj.totalFilesSkipped = message.totalFilesSkipped;
    }
    if (message.latestSkippedFilesList?.length) {
      obj.latestSkippedFilesList = message.latestSkippedFilesList;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HydrationJobHealth>, I>>(base?: I): HydrationJobHealth {
    return HydrationJobHealth.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HydrationJobHealth>, I>>(object: I): HydrationJobHealth {
    const message = createBaseHydrationJobHealth();
    message.id = object.id ?? "0";
    message.jobId = object.jobId ?? "0";
    message.siteId = object.siteId ?? "0";
    message.status = object.status ?? 0;
    message.totalData = object.totalData ?? "0";
    message.dataProcessed = object.dataProcessed ?? "0";
    message.createdBy = object.createdBy ?? "";
    message.updatedBy = object.updatedBy ?? "";
    message.totalFilesSkipped = object.totalFilesSkipped ?? "0";
    message.latestSkippedFilesList = object.latestSkippedFilesList?.map((e) => e) || [];
    return message;
  },
};

function createBaseHydrationJobResource(): HydrationJobResource {
  return { cpuRequest: undefined, cpuLimit: undefined, memoryRequest: undefined, memoryLimit: undefined };
}

export const HydrationJobResource = {
  encode(message: HydrationJobResource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cpuRequest !== undefined) {
      writer.uint32(8).uint32(message.cpuRequest);
    }
    if (message.cpuLimit !== undefined) {
      writer.uint32(16).uint32(message.cpuLimit);
    }
    if (message.memoryRequest !== undefined) {
      writer.uint32(24).uint32(message.memoryRequest);
    }
    if (message.memoryLimit !== undefined) {
      writer.uint32(32).uint32(message.memoryLimit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HydrationJobResource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHydrationJobResource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.cpuRequest = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.cpuLimit = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.memoryRequest = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.memoryLimit = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HydrationJobResource {
    return {
      cpuRequest: isSet(object.cpuRequest) ? globalThis.Number(object.cpuRequest) : undefined,
      cpuLimit: isSet(object.cpuLimit) ? globalThis.Number(object.cpuLimit) : undefined,
      memoryRequest: isSet(object.memoryRequest) ? globalThis.Number(object.memoryRequest) : undefined,
      memoryLimit: isSet(object.memoryLimit) ? globalThis.Number(object.memoryLimit) : undefined,
    };
  },

  toJSON(message: HydrationJobResource): unknown {
    const obj: any = {};
    if (message.cpuRequest !== undefined) {
      obj.cpuRequest = Math.round(message.cpuRequest);
    }
    if (message.cpuLimit !== undefined) {
      obj.cpuLimit = Math.round(message.cpuLimit);
    }
    if (message.memoryRequest !== undefined) {
      obj.memoryRequest = Math.round(message.memoryRequest);
    }
    if (message.memoryLimit !== undefined) {
      obj.memoryLimit = Math.round(message.memoryLimit);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HydrationJobResource>, I>>(base?: I): HydrationJobResource {
    return HydrationJobResource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HydrationJobResource>, I>>(object: I): HydrationJobResource {
    const message = createBaseHydrationJobResource();
    message.cpuRequest = object.cpuRequest ?? undefined;
    message.cpuLimit = object.cpuLimit ?? undefined;
    message.memoryRequest = object.memoryRequest ?? undefined;
    message.memoryLimit = object.memoryLimit ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
