import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  ErrorReason,
  errorReasonFromJSON,
  errorReasonToJSON,
} from '@common-types/common/errors/error_reasons';
import { Site, Site_Status, site_StatusFromJSON } from '@common-types/site_manager/model/v1/site';
import get from 'lodash/get';
import { AppLoadingSpinner } from 'src/components/AppLoadingSpinner/AppLoadingSpinner';
import ErrorPage from 'src/components/Error/ErrorPage';
import UnauthorizedPage from 'src/components/Error/UnauthorizedPage';
import { SITE_POLLING_INTERVAL } from 'src/constants/siteCreation';
import { useAllSitesQuery } from 'src/hooks/api/useAllSitesQuery';
import { isSiteActiveAndAlive } from 'src/utils/site';

type SiteProviderContextType = {
  siteId: string;
  isLoading: boolean;
  site?: Site;
  isPollingSite: boolean;
  setIsPollingSite: Dispatch<SetStateAction<boolean>>;
  sites?: Site[];
  siteIds: string[];
  isSiteActive: boolean;
  refetchSites: VoidFunction;
  pushUrl?: string;
  pushLb?: string;
  caCert?: string;
  lastAlive?: string;
  setSelectedSiteId: Dispatch<SetStateAction<string | undefined>>;
};

const SiteContext = createContext({} as SiteProviderContextType);

export const useSite = () => useContext(SiteContext);

export const SiteProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [sites, setSites] = useState<Site[]>();
  const [isPollingSite, setIsPollingSite] = useState<boolean>(false);
  const [selectedSiteId, setSelectedSiteId] = useState<string | undefined>(
    localStorage.getItem('siteId') ?? undefined,
  );
  const [hasBeenFetched, setHasBeenFetched] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasForbiddenError, setHasForbiddenError] = useState<boolean>(false);

  const { isLoading, refetch } = useAllSitesQuery({
    refetchIntervalMs: isPollingSite ? SITE_POLLING_INTERVAL : undefined,
    onSuccess: (sitesResponse) => {
      const selectedSite = sitesResponse?.find(({ id }) => id === selectedSiteId);
      setSites(sitesResponse);
      if (
        isPollingSite &&
        selectedSite &&
        site_StatusFromJSON(selectedSite?.status) === Site_Status.ACTIVE
      ) {
        setIsPollingSite(false);
      }

      if (!selectedSite && sitesResponse && sitesResponse.length > 0) {
        const defaultSite = sitesResponse?.find(isSiteActiveAndAlive) || sitesResponse[0];
        setSelectedSiteId(defaultSite.id);
        localStorage.setItem('siteId', defaultSite.id);
      }

      setHasBeenFetched(true);
    },
    onError: (error) => {
      const RESPONSE_ERROR_REASON_PATH = 'response.data.reason';
      const errorReason = get(error, RESPONSE_ERROR_REASON_PATH);
      const parsedErrorReason = errorReasonFromJSON(errorReason);

      if (isPollingSite) {
        setIsPollingSite(false);
      }
      if (parsedErrorReason === ErrorReason.FORBIDDEN) {
        setHasForbiddenError(true);
        return;
      }
      setHasError(true);
    },
  });

  const refetchSites = useCallback(() => {
    refetch();
  }, [refetch]);

  const site = selectedSiteId ? sites?.find(({ id }) => id === selectedSiteId) : undefined;
  const siteId = site?.id || '';
  const isSiteActive = site_StatusFromJSON(site?.status) === Site_Status.ACTIVE;
  const siteIds = useMemo(() => (siteId ? [siteId] : []), [siteId]);
  const pushUrl = site?.pushSourceIngress;
  const pushLb = site?.pushSourceLb;
  const caCert = site?.caCert;
  const lastAlive = site?.lastAlive || '';

  const contextValue = useMemo<SiteProviderContextType>(
    () => ({
      sites,
      site,
      isLoading,
      siteId,
      isSiteActive,
      isPollingSite,
      setIsPollingSite,
      setSelectedSiteId,
      refetchSites,
      siteIds,
      pushUrl,
      pushLb,
      caCert,
      lastAlive,
    }),
    [
      isLoading,
      sites,
      siteId,
      site,
      isPollingSite,
      setIsPollingSite,
      setSelectedSiteId,
      refetchSites,
      isSiteActive,
      siteIds,
      pushUrl,
      pushLb,
      caCert,
      lastAlive,
    ],
  );

  if (hasError) {
    return <ErrorPage />;
  }

  if (hasForbiddenError) {
    return <UnauthorizedPage error={errorReasonToJSON(ErrorReason.FORBIDDEN)} />;
  }

  if (!isPollingSite && (isLoading || !hasBeenFetched)) {
    return <AppLoadingSpinner />;
  }

  return <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>;
};
