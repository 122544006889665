import { Flex } from '@chakra-ui/react';
import { ErrorIndicatorIcon } from 'src/components/Icons/ErrorIndicatorIcon';
import { useGetPipelineErrors } from 'src/hooks/useGetPipelineErrors';

export function ErrorIndicator() {
  const { errorsCount } = useGetPipelineErrors();

  return errorsCount ? (
    <Flex justifyContent="end" w="full">
      <ErrorIndicatorIcon fontSize="20px" />
    </Flex>
  ) : null;
}
