import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const Text: ComponentSingleStyleConfig = {
  baseStyle: {
    fontSize: '0.875rem',
  },
  variants: {
    paragraph: {
      fontSize: '1rem',
    },
    label: {
      fontSize: '0.75rem',
    },
    link: {
      cursor: 'pointer',
      fontWeight: 600,
      textColor: 'popoverFooterText',
    },
  },
};
