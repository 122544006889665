import { Flex } from '@chakra-ui/react';
import { Site, site_StatusFromJSON } from '@common-types/site_manager/model/v1/site';
import { chakraComponents, OptionProps } from 'chakra-react-select';
import { SiteOption } from '../types';
import { getSiteStatusIcon } from '../utils';

export const CustomOption = (sites?: Site[]) => {
  const option = (props: OptionProps<SiteOption, false>) => {
    const { data, children } = props;

    const optionSite = sites?.find((site) => site.id === data.value);
    return (
      <chakraComponents.Option {...props}>
        <Flex gap={5} alignItems="center" ml={1}>
          {getSiteStatusIcon(site_StatusFromJSON(optionSite?.status))}
          {children}
        </Flex>
      </chakraComponents.Option>
    );
  };

  return option;
};
