import { editableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { COLORS } from 'src/constants/colors';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  editableAnatomy.keys,
);

const baseStylePreview = defineStyle({ fontWeight: 600, fontSize: '18px' });

const baseStyleInput = defineStyle({
  fontWeight: 600,
  fontSize: '18px',
  px: 2,
  _dark: {
    _focusVisible: { boxShadow: 'unset', border: `1px solid ${COLORS.link}` },
  },
});

const baseStyle = definePartsStyle({
  preview: baseStylePreview,
  input: baseStyleInput,
});

export const Editable = defineMultiStyleConfig({
  baseStyle,
});
