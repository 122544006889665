import { lazy, Suspense } from 'react';

const LogSearch = lazy(() => import(/* webpackPrefetch: true */ 'src/pages/LogSearch/LogSearch'));

const LogSearchPage = () => (
  <Suspense>
    <LogSearch />
  </Suspense>
);

export default LogSearchPage;
