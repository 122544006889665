import React, { PropsWithChildren } from 'react';
import { Flex } from '@chakra-ui/react';
import { Sidebar } from 'src/components/Sidebar/Sidebar';

const SidebarLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <Flex h="100%" w="100% ">
    <Sidebar />
    <Flex px="36px" pt="36px" flexDirection="column" overflow="auto" w="100%">
      {children}
    </Flex>
  </Flex>
);

export default SidebarLayout;
