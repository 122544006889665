import { createIcon } from '@chakra-ui/react';
import { COLORS } from 'src/constants/colors';

export const LogOutIcon = createIcon({
  displayName: 'LogOutIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9M16 17L21 12M21 12L16 7M21 12H9"
      stroke={COLORS.disabled}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
