import { lazy, Suspense } from 'react';
import { ReactChildrenProps } from 'src/types';
import { getEnvVar } from 'src/utils/getEnvVar';

const Auth0Provider = lazy(() => import(/* webpackPrefetch: true */ './parts/Auth0Provider'));
const KeyCloakAuthProvider = lazy(
  () => import(/* webpackPrefetch: true */ './parts/KeyCloakAuthProvider'),
);

enum AuthType {
  Auth0 = 'AUTH0',
  KeyCloak = 'KEY_CLOAK',
  None = 'NONE',
}

export function AuthProvider({ children }: ReactChildrenProps) {
  const authType = getEnvVar('REACT_APP_AUTH_TYPE');

  if (authType === AuthType.Auth0) {
    return (
      <Suspense>
        <Auth0Provider>{children}</Auth0Provider>
      </Suspense>
    );
  }
  if (authType === AuthType.KeyCloak) {
    return (
      <Suspense>
        <KeyCloakAuthProvider>{children}</KeyCloakAuthProvider>
      </Suspense>
    );
  }

  return null;
}
