import React, { ReactElement } from 'react';
import {
  Button,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';
import { Z_INDEX_OVER_TOAST } from 'src/constants/modal';
import { ErrorCircleIcon } from '../Icons/ErrorCircleIcon';
import { DeleteIcon } from '../Icons/DeleteIcon';

interface ErrorBoundaryProps {
  children: ReactElement;
}

interface ErrorBoundaryState {
  hasError: boolean;
  hasUnauthorizedError: boolean;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, hasUnauthorizedError: false };
  }

  static getDerivedStateFromError(error: Error & { isAxiosError: boolean }) {
    if (error?.isAxiosError) {
      return { hasUnauthorizedError: true };
    }

    return { hasError: true };
  }

  onReset = () => {
    const { hasUnauthorizedError } = this.state;
    if (hasUnauthorizedError) {
      this.setState({ hasUnauthorizedError: false });
    } else {
      window.location.reload();
    }
  };

  render() {
    const { hasError, hasUnauthorizedError } = this.state;
    const { children } = this.props;

    return (
      <>
        {(hasError || hasUnauthorizedError) && (
          <Modal size="lg" isOpen closeOnOverlayClick={false} isCentered onClose={this.onReset}>
            <ModalOverlay backdropFilter="blur(5px)" zIndex={Z_INDEX_OVER_TOAST} />
            <ModalContent
              containerProps={{
                zIndex: Z_INDEX_OVER_TOAST,
              }}
            >
              <ModalHeader
                bgColor={rgba(COLORS.status.warning, OPACITY[8])}
                borderTopRadius="8px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <HStack>
                  <ErrorCircleIcon fill="none" />
                  <Heading variant="title-4">Error</Heading>
                </HStack>
                <DeleteIcon onClick={this.onReset} cursor="pointer" />
              </ModalHeader>
              <Divider bgColor={COLORS.status.error} />
              <ModalBody>
                <Text fontSize="xl">
                  {hasUnauthorizedError
                    ? 'You are not authorized to perform this action!'
                    : 'Oops, something went wrong!'}
                </Text>
                {hasError && (
                  <Text fontSize="md" color={COLORS.hintText} pb="4" pt="3">
                    Please reload this page to continue.
                  </Text>
                )}
              </ModalBody>
              <Divider />
              <ModalFooter>
                <Button variant="solid" onClick={this.onReset}>
                  {hasUnauthorizedError ? 'Close' : 'Reload'}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
        {children}
      </>
    );
  }
}
