import { ListSitesResponse } from '@common-types/site_manager/api/v1/site_api';
import { Site } from '@common-types/site_manager/model/v1/site';
import { useQuery } from '@tanstack/react-query';
import axios from 'src/lib/axios/axios';

type UseAllSitesQueryParams = {
  onSuccess?: (data?: Site[]) => void;
  onError?: (err: unknown) => void;
  refetchIntervalMs?: number;
};

export function useAllSitesQuery({
  onSuccess,
  refetchIntervalMs,
  onError,
}: UseAllSitesQueryParams = {}) {
  return useQuery({
    queryKey: ['allSites', { refetchIntervalMs }],
    queryFn: async () => {
      let offset = 0;
      let numOfSites = 0;

      let sites: Site[] = [];
      do {
        // eslint-disable-next-line no-await-in-loop
        const response = await axios.get<ListSitesResponse>('/v1/sites', {
          params: {
            'pagination.ordering.fieldName': 'updated_at',
            'pagination.ordering.descending': true,
            'pagination.limit': 100,
            'pagination.offset': offset,
          },
        });

        numOfSites = response.data.pagination?.totalCount ?? 0;
        sites = sites.concat(response.data.sites);

        offset += 100;
      } while (offset < numOfSites);

      return sites;
    },
    onSuccess,
    onError,
    ...(refetchIntervalMs && {
      refetchInterval: refetchIntervalMs,
    }),
  });
}
