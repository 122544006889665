import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

const card = defineStyle({
  bg: rgba(COLORS.lighterPurple, OPACITY[16]),
  boxShadow: `0px 4px 10px 0px ${rgba(COLORS.cardBackground, OPACITY[25])}`,
  border: '1px',
  borderColor: 'transparent',
  borderRadius: '4px',
});

const tile = defineStyle({
  bg: rgba(COLORS.darkBlue, OPACITY[16]),
  boxShadow: `2px 2px 4px 0px ${rgba(COLORS.black, OPACITY[25])}`,
  border: '1px',
  borderColor: 'transparent',
  borderRadius: '10px',
});

export const Container = defineStyleConfig({
  variants: { card, tile },
});
