import { lazy, Suspense } from 'react';

const Settings = lazy(() => import(/* webpackPrefetch: true */ '../pages/Settings'));

const SettingsPage = () => (
  <Suspense>
    <Settings />
  </Suspense>
);

export default SettingsPage;
