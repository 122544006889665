import { useCallback, useEffect, useState } from 'react';
import { DATE_FILTER_VALUES, DEFAULT_DATE_FILTER_VALUE } from 'src/constants/dateFilter';
import { FilterName } from 'src/constants/filterName';
import { DateFilter } from 'src/types/DateFilter';
import { FilterStorageType, SelectedFilterValues } from '../types';

const getValidatedStorageValues = (filterJson: SelectedFilterValues) => {
  const dateFilter = filterJson?.[FilterName.Date]?.[0] as DateFilter;
  const isValid = !dateFilter || DATE_FILTER_VALUES.some((date) => date.id === dateFilter);
  return isValid ? filterJson : { ...filterJson, [FilterName.Date]: [DEFAULT_DATE_FILTER_VALUE] };
};

const useFilter = (
  initialFilters: SelectedFilterValues,
  viewName: string,
  storageType = FilterStorageType.SessionStorage,
  shouldUseCache: boolean | undefined = true,
) => {
  const storage = storageType === FilterStorageType.LocalStorage ? localStorage : sessionStorage;
  const savedFilter = storage.getItem(viewName);

  const [selectedFilterValues, setSelectedFilterValues] = useState<SelectedFilterValues>(
    savedFilter && shouldUseCache
      ? getValidatedStorageValues(JSON.parse(savedFilter) as SelectedFilterValues)
      : initialFilters,
  );

  useEffect(() => {
    storage.setItem(viewName, JSON.stringify(selectedFilterValues));
  }, [selectedFilterValues, viewName, storage]);

  const onChange = useCallback(
    (name: string, filters: string[]) => {
      setSelectedFilterValues((prev) => ({ ...prev, [name]: filters }));
    },
    [setSelectedFilterValues],
  );

  return {
    selectedFilterValues,
    setSelectedFilterValues,
    onChange,
  };
};

export default useFilter;
