/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../../google/protobuf/timestamp";
import { SubCategory, subCategoryFromJSON, subCategoryToJSON } from "./event_type";

export const protobufPackage = "v1.event";

/** Next ID: 13 */
export interface Event {
  id: string;
  category: Event_Category;
  subCategory: SubCategory;
  createdAt: string | undefined;
  siteId: string;
  userId: string;
  jsonPayload: string;
  ackRequired: boolean;
  state: Event_EventState;
  ack: boolean;
  error: string;
  seriesId: string;
}

/** Next ID: 3 */
export enum Event_Category {
  SITE_EVENT = 0,
  USER_EVENT = 1,
  /**
   * UPDATE_EVENT - Update the notification event itself. Used mainly for use cases like
   * acknowledging an event.
   */
  UPDATE_EVENT = 2,
  ALERT_EVENT = 3,
  UNRECOGNIZED = -1,
}

export function event_CategoryFromJSON(object: any): Event_Category {
  switch (object) {
    case 0:
    case "SITE_EVENT":
      return Event_Category.SITE_EVENT;
    case 1:
    case "USER_EVENT":
      return Event_Category.USER_EVENT;
    case 2:
    case "UPDATE_EVENT":
      return Event_Category.UPDATE_EVENT;
    case 3:
    case "ALERT_EVENT":
      return Event_Category.ALERT_EVENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Event_Category.UNRECOGNIZED;
  }
}

export function event_CategoryToJSON(object: Event_Category): string {
  switch (object) {
    case Event_Category.SITE_EVENT:
      return "SITE_EVENT";
    case Event_Category.USER_EVENT:
      return "USER_EVENT";
    case Event_Category.UPDATE_EVENT:
      return "UPDATE_EVENT";
    case Event_Category.ALERT_EVENT:
      return "ALERT_EVENT";
    case Event_Category.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Next ID: 3 */
export enum Event_EventState {
  DEFAULT = 0,
  RUNNING = 1,
  SUCCESS = 2,
  FAIL = 3,
  UNRECOGNIZED = -1,
}

export function event_EventStateFromJSON(object: any): Event_EventState {
  switch (object) {
    case 0:
    case "DEFAULT":
      return Event_EventState.DEFAULT;
    case 1:
    case "RUNNING":
      return Event_EventState.RUNNING;
    case 2:
    case "SUCCESS":
      return Event_EventState.SUCCESS;
    case 3:
    case "FAIL":
      return Event_EventState.FAIL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Event_EventState.UNRECOGNIZED;
  }
}

export function event_EventStateToJSON(object: Event_EventState): string {
  switch (object) {
    case Event_EventState.DEFAULT:
      return "DEFAULT";
    case Event_EventState.RUNNING:
      return "RUNNING";
    case Event_EventState.SUCCESS:
      return "SUCCESS";
    case Event_EventState.FAIL:
      return "FAIL";
    case Event_EventState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseEvent(): Event {
  return {
    id: "",
    category: 0,
    subCategory: 0,
    createdAt: undefined,
    siteId: "0",
    userId: "",
    jsonPayload: "",
    ackRequired: false,
    state: 0,
    ack: false,
    error: "",
    seriesId: "",
  };
}

export const Event = {
  encode(message: Event, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.category !== 0) {
      writer.uint32(16).int32(message.category);
    }
    if (message.subCategory !== 0) {
      writer.uint32(24).int32(message.subCategory);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.siteId !== "0") {
      writer.uint32(48).uint64(message.siteId);
    }
    if (message.userId !== "") {
      writer.uint32(58).string(message.userId);
    }
    if (message.jsonPayload !== "") {
      writer.uint32(66).string(message.jsonPayload);
    }
    if (message.ackRequired !== false) {
      writer.uint32(72).bool(message.ackRequired);
    }
    if (message.state !== 0) {
      writer.uint32(80).int32(message.state);
    }
    if (message.ack !== false) {
      writer.uint32(88).bool(message.ack);
    }
    if (message.error !== "") {
      writer.uint32(98).string(message.error);
    }
    if (message.seriesId !== "") {
      writer.uint32(106).string(message.seriesId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.subCategory = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.jsonPayload = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.ackRequired = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.ack = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.error = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.seriesId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Event {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      category: isSet(object.category) ? event_CategoryFromJSON(object.category) : 0,
      subCategory: isSet(object.subCategory) ? subCategoryFromJSON(object.subCategory) : 0,
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : undefined,
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      jsonPayload: isSet(object.jsonPayload) ? globalThis.String(object.jsonPayload) : "",
      ackRequired: isSet(object.ackRequired) ? globalThis.Boolean(object.ackRequired) : false,
      state: isSet(object.state) ? event_EventStateFromJSON(object.state) : 0,
      ack: isSet(object.ack) ? globalThis.Boolean(object.ack) : false,
      error: isSet(object.error) ? globalThis.String(object.error) : "",
      seriesId: isSet(object.seriesId) ? globalThis.String(object.seriesId) : "",
    };
  },

  toJSON(message: Event): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.category !== 0) {
      obj.category = event_CategoryToJSON(message.category);
    }
    if (message.subCategory !== 0) {
      obj.subCategory = subCategoryToJSON(message.subCategory);
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt;
    }
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.jsonPayload !== "") {
      obj.jsonPayload = message.jsonPayload;
    }
    if (message.ackRequired !== false) {
      obj.ackRequired = message.ackRequired;
    }
    if (message.state !== 0) {
      obj.state = event_EventStateToJSON(message.state);
    }
    if (message.ack !== false) {
      obj.ack = message.ack;
    }
    if (message.error !== "") {
      obj.error = message.error;
    }
    if (message.seriesId !== "") {
      obj.seriesId = message.seriesId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Event>, I>>(base?: I): Event {
    return Event.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Event>, I>>(object: I): Event {
    const message = createBaseEvent();
    message.id = object.id ?? "";
    message.category = object.category ?? 0;
    message.subCategory = object.subCategory ?? 0;
    message.createdAt = object.createdAt ?? undefined;
    message.siteId = object.siteId ?? "0";
    message.userId = object.userId ?? "";
    message.jsonPayload = object.jsonPayload ?? "";
    message.ackRequired = object.ackRequired ?? false;
    message.state = object.state ?? 0;
    message.ack = object.ack ?? false;
    message.error = object.error ?? "";
    message.seriesId = object.seriesId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
