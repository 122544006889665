import { lazy, Suspense } from 'react';

const Pipelines = lazy(
  () => import(/* webpackPrefetch: true */ '../pages/Pipelines/Pipelines/Pipelines'),
);

const PipelinesPage = () => (
  <Suspense>
    <Pipelines />
  </Suspense>
);

export default PipelinesPage;
