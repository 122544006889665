export const TIME_IN_SECONDS = {
  ONE_MINUTE: 60,
  ONE_HOUR: 3600,
  ONE_DAY: 86400,
  ONE_MONTH: 2592000,
  ONE_YEAR: 31536000,
};

export const TIME_IN_MILLISECONDS = {
  ONE_MINUTE: 60000,
  ONE_HOUR: 3600000,
  ONE_DAY: 86400000,
  ONE_MONTH: 2592000000,
  ONE_YEAR: 31536000000,
};
