import { lazy, Suspense } from 'react';

const Hydration = lazy(() => import(/* webpackPrefetch: true */ 'src/pages/Hydration/Hydration'));

const HydrationPage = () => (
  <Suspense>
    <Hydration />
  </Suspense>
);

export default HydrationPage;
