import { lazy, Suspense } from 'react';

const Destinations = lazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/Destinations/Destinations'),
);

const DestinationsPage = () => (
  <Suspense>
    <Destinations />
  </Suspense>
);

export default DestinationsPage;
