import { Site_Status } from '@common-types/site_manager/model/v1/site';
import { ErrorCircleIcon } from '../Icons/ErrorCircleIcon';
import { CheckCircleIcon } from '../Icons/CheckCircleIcon';

export const getSiteStatusIcon = (siteStatus: Site_Status) => {
  switch (siteStatus) {
    case Site_Status.ACTIVE:
      return <CheckCircleIcon h={4} w="auto" />;
    case Site_Status.INACTIVE:
      return <ErrorCircleIcon h={4} w="auto" />;
    default:
      return null;
  }
};
