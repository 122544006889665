import { SelectedFilterValues } from 'src/components/Filters/types';
import { DATE_FILTER_START_DATE, DEFAULT_DATE_FILTER_VALUE } from 'src/constants/dateFilter';
import { FilterName } from 'src/constants/filterName';
import { DateFilter } from 'src/types/DateFilter';

export function getDefaultDateFilterValues(selectedFilterValues?: SelectedFilterValues): Date {
  const dateFilterValue: DateFilter | undefined = selectedFilterValues?.[
    FilterName.Date
  ]?.[0] as DateFilter;

  const dateValue = dateFilterValue || DEFAULT_DATE_FILTER_VALUE;
  return DATE_FILTER_START_DATE[dateValue]();
}
