import { Helmet } from 'react-helmet-async';
import { getURLOrigins, isDevEnvironment } from 'src/utils/general';
import { getEnvVar } from 'src/utils/getEnvVar';

export function AppMeta() {
  return (
    <Helmet>
      <meta
        httpEquiv="Content-Security-Policy"
        content={`
        default-src 'self';
        frame-src 'self' ${getEnvVar('REACT_APP_AUTH_DOMAIN', false)};
        connect-src 'self' ${getEnvVar('REACT_APP_AUTH_DOMAIN', false)} ${
          getURLOrigins(getEnvVar('REACT_APP_SENTRY_DSN', false)) || ''
        } ${getURLOrigins(getEnvVar('REACT_APP_API_BASE_URL', false), true) || ''} ${
          isDevEnvironment() ? 'ws://127.0.0.1:56572' : ''
        } https://*.clarity.ms;
        style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
        font-src 'self' https://fonts.gstatic.com;
        img-src 'self' data: https://lh3.googleusercontent.com https://*.clarity.ms https://assets.observo.ai;
        `}
      />
    </Helmet>
  );
}
