import { addMinutes, format, getMinutes, roundToNearestMinutes } from 'date-fns';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import formatRelative from 'date-fns/formatRelative';
import parseISO from 'date-fns/parseISO';
import capitalize from 'lodash/capitalize';
import { DATE_FILTER_FORMAT_STRING } from 'src/constants/dateFilter';

export function convertUnixTimeSecondsToFormattedDateString(
  unixTimeSeconds: string,
  dateFormat = 'MMM dd',
) {
  const date = new Date(Number(unixTimeSeconds) * 1000);
  const formattedDateString = format(date, dateFormat);

  return formattedDateString;
}

export function convertDateToUnixTimeSeconds(date: Date) {
  return Math.round(date.getTime() / 1000);
}

export function convertUnixTimeSecondsToISOFormat(seconds: number) {
  return new Date(seconds * 1000).toISOString();
}

export function startOfDayInUtc(inputDate: Date): Date {
  const currentUtcDateString = formatInTimeZone(inputDate, 'UTC', 'EEE MMM dd yyyy 00:00:00');
  const currentStartOfDayInUtc = new Date(`${currentUtcDateString} GMT`);
  return currentStartOfDayInUtc;
}

/**
 * Gets the end date required for the analytics api's. It ensures that the returned date is minimum
 * half hour advance to the current date and maximum one hour advance with rounding it to nearest
 * 30 minutes.
 *
 * For. eg.
 * If Current Time -> 12:00:00,  Then returned time -> 12:30:00
 * If Current Time -> 12:01:00,  Then returned time -> 13:00:00
 * If Current Time -> 12:10:00,  Then returned time -> 13:00:00
 * If Current Time -> 12:14:00,  Then returned time -> 13:00:00
 * If Current Time -> 12:15:00,  Then returned time -> 13:00:00
 * If Current Time -> 12:16:00,  Then returned time -> 13:00:00
 * If Current Time -> 12:20:00,  Then returned time -> 13:00:00
 * If Current Time -> 12:25:00,  Then returned time -> 13:00:00
 * If Current Time -> 12:29:00,  Then returned time -> 13:00:00
 * If Current Time -> 12:30:00,  Then returned time -> 13:00:00
 * If Current Time -> 12:31:00,  Then returned time -> 13:30:00
 * If Current Time -> 12:35:00,  Then returned time -> 13:30:00
 * If Current Time -> 12:44:00,  Then returned time -> 13:30:00
 * If Current Time -> 12:45:00,  Then returned time -> 13:30:00
 * If Current Time -> 12:46:00,  Then returned time -> 13:30:00
 * If Current Time -> 12:49:00,  Then returned time -> 13:30:00
 * If Current Time -> 12:50:00,  Then returned time -> 13:30:00
 * If Current Time -> 12:55:00,  Then returned time -> 13:30:00
 * If Current Time -> 12:59:00,  Then returned time -> 13:30:00
 * If Current Time -> 13:00:00,  Then returned time -> 13:30:00
 * If Current Time -> 13:01:00,  Then returned time -> 14:00:00
 */
export function getAnalyticsEndDate(date = new Date(), isReturnExactEndDate = true): Date {
  if (isReturnExactEndDate) {
    return date;
  }

  const minute = getMinutes(date);
  const roundingMethod =
    (minute >= 15 && minute <= 30) || (minute >= 45 && minute <= 59) ? 'floor' : 'ceil';

  return roundToNearestMinutes(addMinutes(date, 30), {
    nearestTo: 30,
    roundingMethod,
  });
}

export function isValidDate(date: Date): date is Date {
  return date instanceof Date && !isNaN(Number(date));
}

export function formatDateString({
  dateString,
  dateFormat = DATE_FILTER_FORMAT_STRING,
  defaultOutput = '',
}: {
  dateString?: string;
  dateFormat?: string;
  defaultOutput?: string;
}): string {
  if (!dateString) {
    return defaultOutput;
  }

  const date = new Date(dateString);

  if (!isValidDate(date)) {
    return defaultOutput;
  }

  return format(date, dateFormat);
}

export const getCreatedAtRelativeTime = (timestamp: string) =>
  capitalize(
    formatRelative(parseISO(timestamp), new Date(), {
      weekStartsOn: 1,
    }),
  );
