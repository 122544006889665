import { createIcon } from '@chakra-ui/react';

export const ErrorIndicatorIcon = createIcon({
  displayName: 'ErrorIndicatorIcon',
  viewBox: '0 0 26 26',
  path: [
    <g filter="url(#filter0_f_9111_56561)">
      <circle cx="13" cy="13" r="5" fill="#FF4F50" />
    </g>,
    <circle cx="13" cy="13" r="5" fill="#FF4F50" />,
    <defs>
      <filter
        id="filter0_f_9111_56561"
        x="0"
        y="0"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_9111_56561" />
      </filter>
    </defs>,
  ],
});
