import { lazy, Suspense } from 'react';

const PipelineCreationPage = lazy(
  () => import(/* webpackPrefetch: true */ 'src/pages/Pipelines/PipelineCreation'),
);

const PipelinesCreate = () => (
  <Suspense>
    <PipelineCreationPage />
  </Suspense>
);

export default PipelinesCreate;
