import {
  chakra,
  IconButton,
  IconButtonProps,
  PlacementWithLogical,
  Tooltip,
} from '@chakra-ui/react';

interface IconButtonWithTooltipProps extends IconButtonProps {
  label: string;
  placement?: PlacementWithLogical;
}

export const IconButtonWithTooltip = chakra(
  ({ label, placement = 'top', ...rest }: IconButtonWithTooltipProps) => (
    <Tooltip label={label} placement={placement} fontSize="md">
      <IconButton {...rest} />
    </Tooltip>
  ),
);
