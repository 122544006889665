import { NavigateFunction } from 'react-router-dom';
import { ROUTE_PATHS } from 'src/constants/routes';

export const checkIfDetailRoutePage = (
  routeParams: Record<string, string | undefined>,
): boolean => {
  const { pipelineId, sourceId } = routeParams;
  return Boolean(pipelineId || sourceId);
};

export const navigateToClosestListPage = (
  navigate: NavigateFunction,
  routeParams: Record<string, string | undefined>,
): void => {
  const { pipelineId, sourceId } = routeParams;
  if (pipelineId) {
    navigate(ROUTE_PATHS.pipelines.index);
    return;
  }
  if (sourceId) {
    navigate(ROUTE_PATHS.sources.index);
    return;
  }
};

export const checkIfPathMatchesAgainstRoutes = (list: string[], path: string): boolean => {
  const pathWithoutSlug = path.split('/').slice(0, -1).join('/');

  return list.some((route) => {
    const routeArray = route.split('/');
    const slug = routeArray.pop();

    if (slug?.includes(':')) {
      return routeArray.join('/') === pathWithoutSlug;
    }

    return route === path;
  });
};
