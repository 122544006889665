import type { MultiSelectItem } from 'src/types/MultiSelect';
import { SelectItem } from 'src/types/Select';

interface CreateSelectOptionParams {
  value: string;
  label: string;
}

interface MultiCreateSelectOptionParams {
  label: string;
  name: string;
}

export const createSelectOption = ({ value, label }: CreateSelectOptionParams): SelectItem => ({
  value,
  label,
});

export const createMultiSelectOption = ({
  name,
  label,
}: MultiCreateSelectOptionParams): MultiSelectItem => ({
  name,
  label,
});
