import { lazy, Suspense } from 'react';

const Monitoring = lazy(() => import(/* webpackPrefetch: true */ 'src/pages/Monitoring'));

const MonitoringPage = () => (
  <Suspense>
    <Monitoring />
  </Suspense>
);

export default MonitoringPage;
