import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

const baseStyle = defineStyle({
  fontSize: '0.875rem',
  fontWeight: '300',
  _disabled: {
    borderColor: rgba(COLORS.fullWhite, OPACITY[8]),
    opacity: OPACITY[90],
  },
});

export const FormLabel = defineStyleConfig({
  baseStyle,
});
