import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  header: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  body: {
    padding: '24px',
    fontSize: '1rem',
  },
  dialog: {
    _dark: {
      transition: 'max-width 200ms linear',
      // TODO @t-ondrej bg vs background
      bg: 'dialogBackground',
    },
  },
});

export const Modal = defineMultiStyleConfig({
  baseStyle,
});
