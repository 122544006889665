import { createIcon } from '@chakra-ui/react';

export const DeleteIcon = createIcon({
  displayName: 'DeleteIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M5 5L15 15M15 5L5 15"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
