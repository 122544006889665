import { format, isToday, isYesterday } from 'date-fns';
import { isIgnoreErrorsFeatureEnabled } from './featureFlags';

export const getRelativeFormattedDate = (date: Date) => {
  const timeFormat = 'hh:mm aaa';

  if (isToday(date)) {
    return `Today ${format(date, timeFormat)}`;
  }
  if (isYesterday(date)) {
    return `Yesterday ${format(date, timeFormat)}`;
  }
  return format(date, `MM/dd/yyyy ${timeFormat}`);
};

export function isIgnorePipelineErrors() {
  return (
    localStorage.getItem('ignore_pipeline_errors') === 'true' || isIgnoreErrorsFeatureEnabled()
  );
}

export function parseErrorDescriptionAsJson(errorDescription: string) {
  try {
    const errorJson = JSON.parse(errorDescription);
    const errorTitle = errorJson['Error'];
    const errorCauses = errorJson['Possible causes'] as string[];
    const errorResolutions = errorJson['Resolutions'] as string[];

    return { errorTitle, errorCauses, errorResolutions };
  } catch {
    return { errorTitle: errorDescription, errorCauses: [], errorResolutions: [] };
  }
}
