import { createIcon } from '@chakra-ui/react';

export const SettingsSidebarIcon = createIcon({
  displayName: 'SettingsSidebarIcon',
  viewBox: '0 0 24 24',
  path: [
    <g stroke="currentcolor" strokeWidth="2" clipPath="url(#a)">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m19.3 16 .1-1a1.7 1.7 0 0 1 1.5-1h.1a2 2 0 0 0 0-4h-.2a1.7 1.7 0 0 1-1.5-1v0a1.7 1.7 0 0 1 .3-1.9h.1A2 2 0 1 0 17 4.1h0a1.6 1.6 0 0 1-1.9.4 1.6 1.6 0 0 1-1-1.5V3a2 2 0 1 0-4 0v.2a1.6 1.6 0 0 1-1 1.5h0A1.6 1.6 0 0 1 7 4.3h0A2 2 0 1 0 4.1 7h0A1.6 1.6 0 0 1 4.7 9a1.6 1.6 0 0 1-1.5 1H3a2 2 0 1 0 0 4h.2c.3 0 .6.2.9.4s.5.4.6.7a1.6 1.6 0 0 1-.4 1.8h0A2 2 0 1 0 7 19.9h0a1.7 1.7 0 0 1 1.9-.4 1.7 1.7 0 0 1 1 1.5v.1a2 2 0 0 0 4 0v-.2c0-.3.2-.6.4-.9s.4-.5.7-.6c.3-.1.6-.2 1 0l.8.3v.1a2 2 0 1 0 2.9-2.8h0c-.3-.3-.5-.6-.5-1z"
      />
      <circle cx="12" cy="12" r="3" />
    </g>,
    <defs>
      <clipPath id="clip0_991_8966">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>,
  ],
});
