import { Button, Center, Heading, Text } from '@chakra-ui/react';
import { ErrorReason, errorReasonToJSON } from '@common-types/common/errors/error_reasons';

const UnauthorizedPage = ({ error }: { error: string }) => {
  const isUnauthorized = error === errorReasonToJSON(ErrorReason.UNAUTHORIZED);
  const isForbidden = error === errorReasonToJSON(ErrorReason.FORBIDDEN);

  return (
    <Center h="100%" flexDirection="column" gap={8}>
      <Heading variant="title-1" fontWeight={400}>
        {isUnauthorized &&
          `You're not authorized for the application. Please contact your organization admin.`}
        {isForbidden &&
          `You don't have access to application resources. Please contact your organization admin.`}
      </Heading>
      {isUnauthorized && <Text fontSize="xl">Redirecting back to login page...</Text>}
      {isForbidden && (
        <Button variant="outline" onClick={() => window.location.reload()}>
          Reload
        </Button>
      )}
    </Center>
  );
};
export default UnauthorizedPage;
