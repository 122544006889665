import { createIcon } from '@chakra-ui/react';

export const BellIcon = createIcon({
  displayName: 'BellIcon',
  viewBox: '0 0 20 20',
  path: [
    <path
      d="M11.4417 17.5C11.2951 17.7525 11.0849 17.9622 10.8318 18.1079C10.5788 18.2536 10.292 18.3303 9.99999 18.3303C9.70801 18.3303 9.42115 18.2536 9.16813 18.1079C8.91512 17.9622 8.70483 17.7525 8.55832 17.5M15 6.66663C15 5.34054 14.4732 4.06877 13.5355 3.13109C12.5979 2.19341 11.3261 1.66663 10 1.66663C8.67392 1.66663 7.40215 2.19341 6.46447 3.13109C5.52678 4.06877 5 5.34054 5 6.66663C5 12.5 2.5 14.1666 2.5 14.1666H17.5C17.5 14.1666 15 12.5 15 6.66663Z"
      stroke="currentcolor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
