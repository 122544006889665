import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { cssVar } from '@chakra-ui/theme-tools';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const $arrowBg = cssVar('popper-arrow-bg');

const baseStyle = definePartsStyle({
  content: {
    px: '20px',
    pb: '14px',
    borderRadius: '8px',
    border: `1px solid ${rgba(COLORS.fullWhite, OPACITY[20])}`,
    _dark: {
      bg: 'popoverBackground',
      [$arrowBg.variable]: COLORS.popoverBackground,
    },
  },
  header: {
    px: 0,
    py: 0,
    borderBottom: 'unset',
    lineHeight: '20px',
  },
  body: {
    px: 0,
    pb: 0,
    fontSize: '14px',
    lineHeight: '20px',
  },
  footer: {
    px: 0,
    pb: 0,
    borderTop: 'unset',
    fontWeight: 400,
    lineHeight: '20px',
  },
});

const variantDashboard = definePartsStyle({
  content: { pt: '14px' },
  header: { fontWeight: 500, fontSize: '16px', _dark: { color: 'popoverFooterText' } },
  body: { pt: '6px', fontWeight: 400 },
  footer: {
    pt: '15px',
    fontSize: '14px',
    _dark: {
      color: 'popoverFooterText',
    },
  },
});

const variantTransformation = definePartsStyle({
  content: { pt: '7px' },
  header: { fontWeight: 400, fontSize: '12px', _dark: { color: 'label' } },
  body: { pt: '25px', fontWeight: 600 },
  footer: {
    pt: '8px',
    fontSize: '12px',
    _dark: {
      color: 'link',
    },
  },
});

export const Popover = defineMultiStyleConfig({
  baseStyle,
  variants: { dashboard: variantDashboard, transformation: variantTransformation },
});
