import { Styles } from '@chakra-ui/theme-tools';

export const globalStyles: Styles = {
  global: {
    'html, body': {
      fontFamily: 'Inter, sans-serif',
    },
    html: {
      fontSize: '15px',
    },
  },
};
