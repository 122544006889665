import { createIcon } from '@chakra-ui/react';
import { COLORS } from 'src/constants/colors';

export const SourceIcon = createIcon({
  displayName: 'SourceIcon',
  viewBox: '0 0 24 24',
  path: [
    <g clipPath="url(#clip0_4028_9136)">
      <path
        d="M5.75 8.875L2 10.75L12 15.75L22 10.75L18.25 8.875"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 15.75L12 20.75L22 15.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10.75V2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 5.75L12 2L15.75 5.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>,
    <defs>
      <clipPath id="clip0_4028_9136">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>,
  ],
});
