import { ChakraStylesConfig } from 'chakra-react-select';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

export const getChakraSelectStyles = (): ChakraStylesConfig => ({
  container: (provided) => ({ ...provided, w: 'full' }),
  groupHeading: (provided) => ({
    ...provided,
    display: 'flex',
    gap: 5,
    backgroundColor: COLORS.scrollbar,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2500,
  }),
  control: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    _hover: {
      boxShadow: 'none',
    },
    _focus: {
      boxShadow: 'none',
    },
    _dark: {
      color: 'white',
      bg: rgba(COLORS.link, OPACITY[4]),
      _hover: {
        borderColor: rgba(COLORS.fullWhite, OPACITY[80]),
      },
      _focus: {
        borderColor: 'highlightColor',
      },
      _focusVisible: {
        boxShadow: 'none',
        borderColor: 'highlightColor',
      },
      _disabled: {
        borderColor: rgba(COLORS.fullWhite, OPACITY[8]),
        opacity: OPACITY[90],
      },
      _invalid: {
        border: `1px solid ${COLORS.status.error}`,
        boxShadow: 'unset',
      },
    },
  }),
});
