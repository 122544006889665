import { SiteFormFieldLabel, SiteFormFieldName } from 'src/components/SiteForm/types';
import { ConfigFormat } from 'src/types/FormConfig';

export const CLOUD_TYPE_OPTIONS = [
  {
    label: 'AWS',
    name: 'AWS',
  },
  {
    label: 'GCP',
    name: 'GCP',
  },
  {
    label: 'Azure',
    name: 'AZURE',
  },
  {
    label: 'Air Gapped',
    name: 'AIR_GAPPED',
  },
  {
    label: 'Self Managed',
    name: 'ON_PREMISE',
  },
];

export const CONFIG_FORMAT: ConfigFormat = {
  properties: [
    {
      label: SiteFormFieldLabel.Name,
      name: SiteFormFieldName.Name,
      type: 'string',
      placeholder: 'Add Name',
      minLength: 3,
    },
    {
      label: SiteFormFieldLabel.Description,
      name: SiteFormFieldName.Description,
      type: 'string',
      placeholder: 'Add Description',
    },
    {
      label: SiteFormFieldLabel.CloudType,
      name: SiteFormFieldName.CloudType,
      type: 'select',
      placeholder: 'Select Cloud Type',
      options: CLOUD_TYPE_OPTIONS,
    },
    // {
    //   label: SiteFormFieldLabel.Region,
    //   name: SiteFormFieldName.Region,
    //   type: 'string',
    //   placeholder: 'Add Region',
    // },
    // {
    //   label: SiteFormFieldLabel.MinInstances,
    //   name: SiteFormFieldName.MinInstances,
    //   type: 'number',
    //   placeholder: 'Add Min Instances',
    //   minimum: 0,
    // },
    // {
    //   label: SiteFormFieldLabel.MaxInstances,
    //   name: SiteFormFieldName.MaxInstances,
    //   type: 'number',
    //   placeholder: 'Add Max Instances',
    //   minimum: 0,
    // },
  ],
  required: [SiteFormFieldName.Name, SiteFormFieldName.CloudType],
};

export const SITE_POLLING_INTERVAL = 5000;
export const SITE_NEVER_USED = '0001-01-01T00:00:00Z';
