export enum NotificationStatus {
  Info,
  Success,
  Warning,
  Error,
}

export type Notification = {
  id: string;
  message: string;
  status: NotificationStatus;
  showTimestamp?: boolean;
};

export type ShowNotification = Omit<Notification, 'id'>;
