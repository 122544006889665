import { useEffect, useState } from 'react';
import { InfoIcon, WarningIcon } from '@chakra-ui/icons';
import { chakra, CloseButton, Flex, shouldForwardProp, Text } from '@chakra-ui/react';
import { isValidMotionProp, motion } from 'framer-motion';
import noop from 'lodash/noop';
import { COLORS } from 'src/constants/colors';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip/IconButtonWithTooltip';
import { ErrorCircleIcon } from '../Icons/ErrorCircleIcon';
import { RedirectIcon } from '../Icons/RedirectIcon';
import { SuccessCircleIcon } from '../Icons/SuccessCircleIcon';
import { ANIMATION_CONFIG, ANIMATION_TRANSITION } from './constants';
import { Notification as NotificationType, NotificationStatus } from './types';

type Props = NotificationType & {
  onClose?: VoidFunction;
  onOpenDialog: VoidFunction;
};

const STATUS_COLOR_MAP = {
  [NotificationStatus.Info]: [COLORS.notificationStatus.info, COLORS.link],
  [NotificationStatus.Success]: [COLORS.notificationStatus.success, COLORS.status.success],
  [NotificationStatus.Warning]: [COLORS.notificationStatus.warning, COLORS.status.warning],
  [NotificationStatus.Error]: [COLORS.notificationStatus.error, COLORS.status.error],
};

const STATUS_ICON_MAP = {
  [NotificationStatus.Info]: InfoIcon,
  [NotificationStatus.Success]: SuccessCircleIcon,
  [NotificationStatus.Warning]: WarningIcon,
  [NotificationStatus.Error]: ErrorCircleIcon,
};

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const Notification = ({ id, message, status, onClose = noop, onOpenDialog }: Props) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [color, borderColor] = STATUS_COLOR_MAP[status];
  const Icon = STATUS_ICON_MAP[status];

  const textId = `${id}-toast-${message}`;

  useEffect(() => {
    const el = document.getElementById(textId);
    if (el) {
      const hasOverflow = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
      setIsOverflowing(hasOverflow);
    }
  }, [textId]);

  return (
    <ChakraBox
      key={message}
      initial={false}
      animate={ANIMATION_CONFIG}
      // @ts-ignore no problem in operation, although type error appears.
      transition={ANIMATION_TRANSITION}
    >
      <Flex
        mb={2}
        ml={4}
        px={4}
        py={2}
        w="470px"
        bgColor={color}
        border={`1px solid ${borderColor}`}
        borderRadius="20px"
        alignItems="center"
        data-cy="user-toast-notification"
      >
        <Icon fill="none" color={borderColor} mr={4} />
        <Text
          id={textId}
          mr={2}
          maxH="300px"
          whiteSpace="break-spaces"
          overflow="hidden"
          marginRight="auto"
          noOfLines={2}
        >
          {message}
        </Text>
        {isOverflowing && (
          <IconButtonWithTooltip
            mr={2}
            icon={<RedirectIcon />}
            aria-label="Redirect"
            label="Show error details"
            fill="none"
            size="sm"
            variant="ghost"
            onClick={onOpenDialog}
          />
        )}
        <CloseButton onClick={onClose} />
      </Flex>
    </ChakraBox>
  );
};

export default Notification;
