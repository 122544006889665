import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys,
);

const baseStyle = definePartsStyle({
  label: {
    fontSize: '0.875rem',
  },
  control: {
    borderRadius: '4px',
    _dark: {
      bg: rgba(COLORS.link, OPACITY[16]),
      border: `1px solid ${COLORS.link}`,
      _hover: {
        bg: rgba(COLORS.link, OPACITY[32]),
      },
      _checked: {
        bg: 'lighterBlue',
        borderColor: 'lighterBlue',
      },
      _disabled: {
        _checked: {
          bg: 'lighterBlue',
        },
        bg: 'unset',
        opacity: OPACITY[60],
      },
    },
  },
  icon: {
    color: 'white',
  },
});

const circular = definePartsStyle({
  control: defineStyle({
    rounded: 'full',
  }),
});

export const Checkbox = defineMultiStyleConfig({
  baseStyle,
  variants: { circular },
});
