import { COLORS } from './colors';

export const ALWAYS_VISIBLE_SCROLLBAR_STYLES = {
  '&::-webkit-scrollbar': {
    webkitAppearance: 'none',
    width: 6,
    height: 8,
  },
  '&::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 2,
    backgroundColor: COLORS.scrollbar,
  },
};
