import { Avatar, Flex, HStack } from '@chakra-ui/react';
import { IconButtonWithTooltip } from 'src/components/IconButtonWithTooltip/IconButtonWithTooltip';
import { LogOutIcon } from 'src/components/Icons/LogOutIcon';
import { TruncatedText } from 'src/components/TruncatedText/TruncatedText';
import { useAuthStoreContext } from 'src/context/AuthProvider/AuthStoreContext';
import { useLogout } from 'src/hooks/useLogout';

type UserProfileProps = {
  showDetails?: boolean;
};

export const UserProfile = ({ showDetails = true }: UserProfileProps) => {
  const { user } = useAuthStoreContext();

  const { name = '', picture, email = '' } = user ?? {};

  const onLogout = useLogout();

  return (
    <HStack gap={2} justifyContent="stretch" alignItems="center" pl={1}>
      <Avatar name={name} src={picture} size="sm" w="40px" h="40px" referrerPolicy="no-referrer" />
      {showDetails && (
        // don't remove userName class
        <Flex direction="column" gap={2} overflow="clip">
          <TruncatedText variant="paragraph" noOfLines={1} className="userName" truncateLength={12}>
            {name}
          </TruncatedText>
          <TruncatedText
            variant="label"
            noOfLines={1}
            lineHeight="shorter"
            color="label"
            truncateLength={20}
          >
            {email}
          </TruncatedText>
        </Flex>
      )}
      <IconButtonWithTooltip
        label="Logout"
        fill="none"
        onClick={onLogout}
        size="md"
        variant="ghost"
        aria-label="Search database"
        icon={<LogOutIcon />}
        fontSize="24px"
      />
    </HStack>
  );
};
