import { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

export const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    borderRadius: '32px',
    fontWeight: 500,
  },
  sizes: {
    default: {
      minW: '26',
      h: '10',
      px: '8',
    },
    menu: {
      minW: '36',
      h: '10',
      px: '4',
      textAlign: 'start',
    },
    filter: {
      h: '8',
      px: '4',
    },
  },
  defaultProps: {
    size: 'default',
  },
  variants: {
    solid: {
      _dark: {
        bg: 'lighterBlue',
        _hover: {
          bg: 'highlightColor',
          _disabled: {
            bg: 'disabledBlue',
          },
        },
        _disabled: {
          bg: 'disabledBlue',
        },
      },
    },
    // As a workaround. Otherwise can't override button styles in Menu.ts theme
    menu: {
      borderRadius: '8px',
      _dark: {
        bg: 'lighterBlue',
        _hover: {
          bg: 'highlightColor',
          _disabled: {
            bg: 'disabledBlue',
          },
        },
        _disabled: {
          bg: 'disabledBlue',
        },
      },
    },
    badge: {
      borderRadius: '8px',
      _dark: {
        bg: rgba(COLORS.fullWhite, OPACITY[8]),
        _hover: {
          bg: 'lighterBlue',
          _disabled: {
            bg: 'disabledBlue',
          },
        },
        _disabled: {
          bg: 'disabledBlue',
        },
      },
    },
    outline: {
      _dark: {
        _disabled: {
          borderColor: rgba(COLORS.fullWhite, OPACITY[8]),
          opacity: OPACITY[90],
        },
        borderColor: rgba(COLORS.fullWhite, OPACITY[60]),
        _hover: {
          borderColor: rgba(COLORS.fullWhite, OPACITY[80]),
          bg: rgba(COLORS.link, OPACITY[4]),
          _disabled: {
            borderColor: rgba(COLORS.fullWhite, OPACITY[60]),
            bg: 'transparent',
          },
        },
      },
    },
    link: {
      _dark: {
        color: 'link',
      },
    },
    advanced: {
      _dark: {
        color: 'white',
      },
    },
    filter: {
      borderRadius: '8px',
      fontWeight: 400,
      fontSize: '14px',
      px: '14px',
      py: '6px',
      _dark: {
        bg: rgba(COLORS.navbarHover, OPACITY[64]),
        color: rgba(COLORS.fullWhite, OPACITY[80]),
        border: `1px solid ${rgba(COLORS.fullWhite, OPACITY[16])}`,
        '.chakra-button__icon': {
          fill: 'none',
          color: rgba(COLORS.fullWhite, OPACITY[50]),
          fontSize: '20px',
        },
        _hover: {
          bg: rgba(COLORS.fullWhite, OPACITY[16]),
          _disabled: {
            bg: rgba(COLORS.fullWhite, OPACITY[1]),
          },
        },
        _disabled: {
          bg: rgba(COLORS.fullWhite, OPACITY[1]),
        },
      },
    },
    graphFilter: {
      fontWeight: 400,
      fontSize: '14px',
      height: '16px',
      px: '8px',
      _dark: {
        color: COLORS.link,
        '.chakra-button__icon': {
          fill: 'none',
          color: COLORS.link,
          fontSize: '14px',
        },
        _hover: {
          color: COLORS.white,
          '.chakra-button__icon': {
            color: COLORS.white,
          },
        },
      },
    },
    itemMenu: {
      fontWeight: 400,
      fontSize: '14px',
      height: '16px',
      px: '8px',
      _dark: {
        cursor: 'context-menu',
        color: COLORS.white,
        '.chakra-button__icon': {
          fill: 'none',
          color: COLORS.white,
          fontSize: '14px',
        },
        _hover: {
          color: COLORS.white,
          '.chakra-button__icon': {
            color: COLORS.white,
          },
        },
      },
    },
    blueAdvance: {
      fontWeight: 400,
      fontSize: '14px',
      height: '16px',
      px: '8px',
      _dark: {
        color: COLORS.link,
        '.chakra-button__icon': {
          fill: 'none',
          color: COLORS.link,
          fontSize: '14px',
        },
        _hover: {
          color: COLORS.link,
          '.chakra-button__icon': {
            color: COLORS.link,
          },
        },
      },
    },
    tableActionMenu: {
      _dark: {
        _hover: {
          background: 'menuHover',
        },
        _active: {
          background: 'menuHover',
        },
      },
    },
    delete: {
      _dark: {
        bg: 'buttonRed',
        _hover: {
          bg: rgba(COLORS.buttonRed, OPACITY[80]),
          _disabled: {
            bg: rgba(COLORS.buttonRed, OPACITY[50]),
          },
        },
        _disabled: {
          bg: rgba(COLORS.buttonRed, OPACITY[50]),
        },
      },
    },
    accordion: {
      _dark: {
        bg: 'transparent',
      },
    },
  },
};
