/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Struct } from "../../../../../google/protobuf/struct";
import { Timestamp } from "../../../../../google/protobuf/timestamp";
import { Status, statusFromJSON, statusToJSON } from "../../../common/v1/common";

export const protobufPackage = "v1.analytics_service";

/** Enum for different channel types */
export enum ChannelType {
  CHANNEL_DEFAULT = 0,
  SLACK = 1,
  EMAIL = 2,
  WEBHOOK = 3,
  MSTEAMS = 4,
  OPSGENIE = 5,
  UNRECOGNIZED = -1,
}

export function channelTypeFromJSON(object: any): ChannelType {
  switch (object) {
    case 0:
    case "CHANNEL_DEFAULT":
      return ChannelType.CHANNEL_DEFAULT;
    case 1:
    case "SLACK":
      return ChannelType.SLACK;
    case 2:
    case "EMAIL":
      return ChannelType.EMAIL;
    case 3:
    case "WEBHOOK":
      return ChannelType.WEBHOOK;
    case 4:
    case "MSTEAMS":
      return ChannelType.MSTEAMS;
    case 5:
    case "OPSGENIE":
      return ChannelType.OPSGENIE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChannelType.UNRECOGNIZED;
  }
}

export function channelTypeToJSON(object: ChannelType): string {
  switch (object) {
    case ChannelType.CHANNEL_DEFAULT:
      return "CHANNEL_DEFAULT";
    case ChannelType.SLACK:
      return "SLACK";
    case ChannelType.EMAIL:
      return "EMAIL";
    case ChannelType.WEBHOOK:
      return "WEBHOOK";
    case ChannelType.MSTEAMS:
      return "MSTEAMS";
    case ChannelType.OPSGENIE:
      return "OPSGENIE";
    case ChannelType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Enum for alert categories */
export enum AlertCategory {
  ALERT_CATEGORY_DEFAULT = 0,
  UNRECOGNIZED = -1,
}

export function alertCategoryFromJSON(object: any): AlertCategory {
  switch (object) {
    case 0:
    case "ALERT_CATEGORY_DEFAULT":
      return AlertCategory.ALERT_CATEGORY_DEFAULT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AlertCategory.UNRECOGNIZED;
  }
}

export function alertCategoryToJSON(object: AlertCategory): string {
  switch (object) {
    case AlertCategory.ALERT_CATEGORY_DEFAULT:
      return "ALERT_CATEGORY_DEFAULT";
    case AlertCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Enum for alert severity levels */
export enum AlertSeverity {
  SEVERITY_UNSPECIFIED = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  CRITICAL = 4,
  UNRECOGNIZED = -1,
}

export function alertSeverityFromJSON(object: any): AlertSeverity {
  switch (object) {
    case 0:
    case "SEVERITY_UNSPECIFIED":
      return AlertSeverity.SEVERITY_UNSPECIFIED;
    case 1:
    case "LOW":
      return AlertSeverity.LOW;
    case 2:
    case "MEDIUM":
      return AlertSeverity.MEDIUM;
    case 3:
    case "HIGH":
      return AlertSeverity.HIGH;
    case 4:
    case "CRITICAL":
      return AlertSeverity.CRITICAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AlertSeverity.UNRECOGNIZED;
  }
}

export function alertSeverityToJSON(object: AlertSeverity): string {
  switch (object) {
    case AlertSeverity.SEVERITY_UNSPECIFIED:
      return "SEVERITY_UNSPECIFIED";
    case AlertSeverity.LOW:
      return "LOW";
    case AlertSeverity.MEDIUM:
      return "MEDIUM";
    case AlertSeverity.HIGH:
      return "HIGH";
    case AlertSeverity.CRITICAL:
      return "CRITICAL";
    case AlertSeverity.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum FiringState {
  FIRING_STATE_INACTIVE = 0,
  RESOLVED = 1,
  PENDING = 2,
  FIRING = 3,
  UNRECOGNIZED = -1,
}

export function firingStateFromJSON(object: any): FiringState {
  switch (object) {
    case 0:
    case "FIRING_STATE_INACTIVE":
      return FiringState.FIRING_STATE_INACTIVE;
    case 1:
    case "RESOLVED":
      return FiringState.RESOLVED;
    case 2:
    case "PENDING":
      return FiringState.PENDING;
    case 3:
    case "FIRING":
      return FiringState.FIRING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FiringState.UNRECOGNIZED;
  }
}

export function firingStateToJSON(object: FiringState): string {
  switch (object) {
    case FiringState.FIRING_STATE_INACTIVE:
      return "FIRING_STATE_INACTIVE";
    case FiringState.RESOLVED:
      return "RESOLVED";
    case FiringState.PENDING:
      return "PENDING";
    case FiringState.FIRING:
      return "FIRING";
    case FiringState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AlertEventState {
  ALERT_EVENT_STATE_INACTIVE = 0,
  ALERT_EVENT_STATE_RESOLVED = 1,
  ALERT_EVENT_STATE_FIRING = 2,
  ALERT_EVENT_STATE_PENDING = 3,
  ALERT_EVENT_STATE_DISCARDED = 4,
  UNRECOGNIZED = -1,
}

export function alertEventStateFromJSON(object: any): AlertEventState {
  switch (object) {
    case 0:
    case "ALERT_EVENT_STATE_INACTIVE":
      return AlertEventState.ALERT_EVENT_STATE_INACTIVE;
    case 1:
    case "ALERT_EVENT_STATE_RESOLVED":
      return AlertEventState.ALERT_EVENT_STATE_RESOLVED;
    case 2:
    case "ALERT_EVENT_STATE_FIRING":
      return AlertEventState.ALERT_EVENT_STATE_FIRING;
    case 3:
    case "ALERT_EVENT_STATE_PENDING":
      return AlertEventState.ALERT_EVENT_STATE_PENDING;
    case 4:
    case "ALERT_EVENT_STATE_DISCARDED":
      return AlertEventState.ALERT_EVENT_STATE_DISCARDED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AlertEventState.UNRECOGNIZED;
  }
}

export function alertEventStateToJSON(object: AlertEventState): string {
  switch (object) {
    case AlertEventState.ALERT_EVENT_STATE_INACTIVE:
      return "ALERT_EVENT_STATE_INACTIVE";
    case AlertEventState.ALERT_EVENT_STATE_RESOLVED:
      return "ALERT_EVENT_STATE_RESOLVED";
    case AlertEventState.ALERT_EVENT_STATE_FIRING:
      return "ALERT_EVENT_STATE_FIRING";
    case AlertEventState.ALERT_EVENT_STATE_PENDING:
      return "ALERT_EVENT_STATE_PENDING";
    case AlertEventState.ALERT_EVENT_STATE_DISCARDED:
      return "ALERT_EVENT_STATE_DISCARDED";
    case AlertEventState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AcknowledgementState {
  ACKNOWLEDGEMENT_ALL = 0,
  ACKNOWLEDGED = 1,
  UNACKNOWLEDGED = 2,
  UNRECOGNIZED = -1,
}

export function acknowledgementStateFromJSON(object: any): AcknowledgementState {
  switch (object) {
    case 0:
    case "ACKNOWLEDGEMENT_ALL":
      return AcknowledgementState.ACKNOWLEDGEMENT_ALL;
    case 1:
    case "ACKNOWLEDGED":
      return AcknowledgementState.ACKNOWLEDGED;
    case 2:
    case "UNACKNOWLEDGED":
      return AcknowledgementState.UNACKNOWLEDGED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AcknowledgementState.UNRECOGNIZED;
  }
}

export function acknowledgementStateToJSON(object: AcknowledgementState): string {
  switch (object) {
    case AcknowledgementState.ACKNOWLEDGEMENT_ALL:
      return "ACKNOWLEDGEMENT_ALL";
    case AcknowledgementState.ACKNOWLEDGED:
      return "ACKNOWLEDGED";
    case AcknowledgementState.UNACKNOWLEDGED:
      return "UNACKNOWLEDGED";
    case AcknowledgementState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AnalyticsServerConfig {
  prometheusconfig: PrometheusConfig | undefined;
  useDummy: boolean;
  ssaConfig: SSAConfig | undefined;
  clickhouseConfig: ClickHouseConfig | undefined;
  patternStoreConfig: PatternStoreConfig | undefined;
}

export interface PatternStoreConfig {
  storeName: string;
  enabled: string;
}

export interface SSAConfig {
  streamAnalyticsMergerIntervalHour: number;
  trafficProtocolMapper: { [key: string]: string };
}

export interface SSAConfig_TrafficProtocolMapperEntry {
  key: string;
  value: string;
}

export interface PrometheusConfig {
  url: string;
  timeout: number;
}

/** Comma separated addresses */
export interface ClickHouseConfig {
  addresses: string;
  database: string;
  username: string;
  password: string;
}

/** Message for Slack channel configuration */
export interface SlackChannel {
  /** URL for Slack webhook */
  webhookUrl: string;
}

/** Message for Email channel configuration */
export interface EmailChannel {
  /** Email address for notifications */
  emailAddress: string;
}

/** Message for Webhook channel configuration */
export interface WebhookChannel {
  /** URL for webhook */
  webhookUrl: string;
}

/** Message for MS Teams channel configuration */
export interface MSTeamsChannel {
  /** URL for webhook */
  webhookUrl: string;
}

/** Message for OpsGenie channel configuration */
export interface OpsGenieChannel {
  /** URL for api */
  apiUrl: string;
  /** key for api */
  apiKey: string;
}

/** Message for Channel */
export interface Channel {
  /** Unique identifier for the channel */
  id: string;
  /** Organization ID */
  orgId: string;
  /** Name of the channel */
  name: string;
  /** Description of the channel */
  description: string;
  /** Type of the channel (e.g., Slack, Email, Webhook) */
  channelType: ChannelType;
  /** User who created the channel */
  createdBy: string;
  /** User who last updated the channel */
  updatedBy: string;
  /** Timestamp when the channel was created */
  created:
    | string
    | undefined;
  /** Timestamp when the channel was last updated */
  updated: string | undefined;
  slackConfig?: SlackChannel | undefined;
  emailConfig?: EmailChannel | undefined;
  webhookConfig?: WebhookChannel | undefined;
  msTeams?: MSTeamsChannel | undefined;
  opsGenie?: OpsGenieChannel | undefined;
  status: Status;
  alerts: Alert[];
}

/** Message for Alert Template */
export interface AlertTemplate {
  /** Unique identifier for the alert template */
  id: string;
  /** Name of the alert template */
  name: string;
  /** Name of the alert template */
  version: string;
  displayName: string;
  /** Description of the alert template */
  description: string;
  /** Category of the alert template */
  category: AlertCategory;
  /** Configuration format for the alert template */
  configFormat:
    | { [key: string]: any }
    | undefined;
  /** Timestamp when the alert template was last updated */
  updated: string | undefined;
}

/** Message for Alert */
export interface Alert {
  /** Unique identifier for the alert */
  id: string;
  /** Name of the alert */
  name: string;
  /** Site IDs associated with the alert */
  siteId: string;
  /** ID of the alert template */
  templateId: string;
  /** Description of the alert */
  description: string;
  /** Channel IDs for notification */
  channels: Channel[];
  /** active, inactive, deleted */
  status: Status;
  /** Timestamp when the alert was created */
  created:
    | string
    | undefined;
  /** Timestamp when the alert was last updated */
  updated:
    | string
    | undefined;
  /** User who created the alert */
  createdBy: string;
  /** User who last updated the alert */
  updatedBy: string;
  /** Configuration for the alert template */
  config:
    | { [key: string]: any }
    | undefined;
  /** Timestamp when the alert was created */
  lastTrigger:
    | string
    | undefined;
  /** Firing, resolved, pending */
  firingState: FiringState;
  /** Severity level of the alert template */
  severity: AlertSeverity;
  /** User defined tags */
  userTags: { [key: string]: string };
}

export interface Alert_UserTagsEntry {
  key: string;
  value: string;
}

export interface PrometheusAlertEvent {
  status: string;
  labels: { [key: string]: any } | undefined;
  annotations: { [key: string]: any } | undefined;
  generatorURL: string;
  fingerprint: string;
  startsAt: string | undefined;
  endsAt: string | undefined;
}

export interface PrometheusAlertManagerEvent {
  version: string;
  /** key identifying the group of alerts (e.g. to deduplicate) */
  groupKey: string;
  /** how many alerts have been truncated due to "max_alerts" */
  truncatedAlerts: number;
  status: string;
  receiver: string;
  groupLabels: { [key: string]: any } | undefined;
  commonLabels: { [key: string]: any } | undefined;
  commonAnnotations:
    | { [key: string]: any }
    | undefined;
  /** backlink to the Alertmanager */
  externalURL: string;
  alerts: PrometheusAlertEvent[];
}

export interface ObservoAlertEvent {
  alertEventId: string;
  siteId: string;
  /** id of the corresponding alert object */
  alertId: string;
  /** name of the corresponding alert object */
  alertName: string;
  severity: AlertSeverity;
  /** fingerprint of prometheus alertmanager event */
  fingerprint: string;
  /** Current state of the active alert */
  state: AlertEventState;
  /** Event message sent by prometheus alertmanager */
  eventMessage:
    | { [key: string]: any }
    | undefined;
  /** id of observo component associated with the alert */
  componentId: string;
  /** Timestamp when the event was produced */
  firstEventAt:
    | string
    | undefined;
  /** Timestamp when the event was last updated */
  lastEventAt: string | undefined;
  isAcknowledged: boolean;
}

function createBaseAnalyticsServerConfig(): AnalyticsServerConfig {
  return {
    prometheusconfig: undefined,
    useDummy: false,
    ssaConfig: undefined,
    clickhouseConfig: undefined,
    patternStoreConfig: undefined,
  };
}

export const AnalyticsServerConfig = {
  encode(message: AnalyticsServerConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.prometheusconfig !== undefined) {
      PrometheusConfig.encode(message.prometheusconfig, writer.uint32(10).fork()).ldelim();
    }
    if (message.useDummy !== false) {
      writer.uint32(16).bool(message.useDummy);
    }
    if (message.ssaConfig !== undefined) {
      SSAConfig.encode(message.ssaConfig, writer.uint32(26).fork()).ldelim();
    }
    if (message.clickhouseConfig !== undefined) {
      ClickHouseConfig.encode(message.clickhouseConfig, writer.uint32(34).fork()).ldelim();
    }
    if (message.patternStoreConfig !== undefined) {
      PatternStoreConfig.encode(message.patternStoreConfig, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnalyticsServerConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnalyticsServerConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.prometheusconfig = PrometheusConfig.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.useDummy = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ssaConfig = SSAConfig.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clickhouseConfig = ClickHouseConfig.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.patternStoreConfig = PatternStoreConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnalyticsServerConfig {
    return {
      prometheusconfig: isSet(object.prometheusconfig) ? PrometheusConfig.fromJSON(object.prometheusconfig) : undefined,
      useDummy: isSet(object.useDummy) ? globalThis.Boolean(object.useDummy) : false,
      ssaConfig: isSet(object.ssaConfig) ? SSAConfig.fromJSON(object.ssaConfig) : undefined,
      clickhouseConfig: isSet(object.clickhouseConfig) ? ClickHouseConfig.fromJSON(object.clickhouseConfig) : undefined,
      patternStoreConfig: isSet(object.patternStoreConfig)
        ? PatternStoreConfig.fromJSON(object.patternStoreConfig)
        : undefined,
    };
  },

  toJSON(message: AnalyticsServerConfig): unknown {
    const obj: any = {};
    if (message.prometheusconfig !== undefined) {
      obj.prometheusconfig = PrometheusConfig.toJSON(message.prometheusconfig);
    }
    if (message.useDummy !== false) {
      obj.useDummy = message.useDummy;
    }
    if (message.ssaConfig !== undefined) {
      obj.ssaConfig = SSAConfig.toJSON(message.ssaConfig);
    }
    if (message.clickhouseConfig !== undefined) {
      obj.clickhouseConfig = ClickHouseConfig.toJSON(message.clickhouseConfig);
    }
    if (message.patternStoreConfig !== undefined) {
      obj.patternStoreConfig = PatternStoreConfig.toJSON(message.patternStoreConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AnalyticsServerConfig>, I>>(base?: I): AnalyticsServerConfig {
    return AnalyticsServerConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AnalyticsServerConfig>, I>>(object: I): AnalyticsServerConfig {
    const message = createBaseAnalyticsServerConfig();
    message.prometheusconfig = (object.prometheusconfig !== undefined && object.prometheusconfig !== null)
      ? PrometheusConfig.fromPartial(object.prometheusconfig)
      : undefined;
    message.useDummy = object.useDummy ?? false;
    message.ssaConfig = (object.ssaConfig !== undefined && object.ssaConfig !== null)
      ? SSAConfig.fromPartial(object.ssaConfig)
      : undefined;
    message.clickhouseConfig = (object.clickhouseConfig !== undefined && object.clickhouseConfig !== null)
      ? ClickHouseConfig.fromPartial(object.clickhouseConfig)
      : undefined;
    message.patternStoreConfig = (object.patternStoreConfig !== undefined && object.patternStoreConfig !== null)
      ? PatternStoreConfig.fromPartial(object.patternStoreConfig)
      : undefined;
    return message;
  },
};

function createBasePatternStoreConfig(): PatternStoreConfig {
  return { storeName: "", enabled: "" };
}

export const PatternStoreConfig = {
  encode(message: PatternStoreConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.storeName !== "") {
      writer.uint32(10).string(message.storeName);
    }
    if (message.enabled !== "") {
      writer.uint32(18).string(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatternStoreConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatternStoreConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.storeName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.enabled = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PatternStoreConfig {
    return {
      storeName: isSet(object.storeName) ? globalThis.String(object.storeName) : "",
      enabled: isSet(object.enabled) ? globalThis.String(object.enabled) : "",
    };
  },

  toJSON(message: PatternStoreConfig): unknown {
    const obj: any = {};
    if (message.storeName !== "") {
      obj.storeName = message.storeName;
    }
    if (message.enabled !== "") {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PatternStoreConfig>, I>>(base?: I): PatternStoreConfig {
    return PatternStoreConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PatternStoreConfig>, I>>(object: I): PatternStoreConfig {
    const message = createBasePatternStoreConfig();
    message.storeName = object.storeName ?? "";
    message.enabled = object.enabled ?? "";
    return message;
  },
};

function createBaseSSAConfig(): SSAConfig {
  return { streamAnalyticsMergerIntervalHour: 0, trafficProtocolMapper: {} };
}

export const SSAConfig = {
  encode(message: SSAConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.streamAnalyticsMergerIntervalHour !== 0) {
      writer.uint32(8).int32(message.streamAnalyticsMergerIntervalHour);
    }
    Object.entries(message.trafficProtocolMapper).forEach(([key, value]) => {
      SSAConfig_TrafficProtocolMapperEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SSAConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSSAConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.streamAnalyticsMergerIntervalHour = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = SSAConfig_TrafficProtocolMapperEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.trafficProtocolMapper[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SSAConfig {
    return {
      streamAnalyticsMergerIntervalHour: isSet(object.streamAnalyticsMergerIntervalHour)
        ? globalThis.Number(object.streamAnalyticsMergerIntervalHour)
        : 0,
      trafficProtocolMapper: isObject(object.trafficProtocolMapper)
        ? Object.entries(object.trafficProtocolMapper).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: SSAConfig): unknown {
    const obj: any = {};
    if (message.streamAnalyticsMergerIntervalHour !== 0) {
      obj.streamAnalyticsMergerIntervalHour = Math.round(message.streamAnalyticsMergerIntervalHour);
    }
    if (message.trafficProtocolMapper) {
      const entries = Object.entries(message.trafficProtocolMapper);
      if (entries.length > 0) {
        obj.trafficProtocolMapper = {};
        entries.forEach(([k, v]) => {
          obj.trafficProtocolMapper[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SSAConfig>, I>>(base?: I): SSAConfig {
    return SSAConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SSAConfig>, I>>(object: I): SSAConfig {
    const message = createBaseSSAConfig();
    message.streamAnalyticsMergerIntervalHour = object.streamAnalyticsMergerIntervalHour ?? 0;
    message.trafficProtocolMapper = Object.entries(object.trafficProtocolMapper ?? {}).reduce<
      { [key: string]: string }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseSSAConfig_TrafficProtocolMapperEntry(): SSAConfig_TrafficProtocolMapperEntry {
  return { key: "", value: "" };
}

export const SSAConfig_TrafficProtocolMapperEntry = {
  encode(message: SSAConfig_TrafficProtocolMapperEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SSAConfig_TrafficProtocolMapperEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSSAConfig_TrafficProtocolMapperEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SSAConfig_TrafficProtocolMapperEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: SSAConfig_TrafficProtocolMapperEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SSAConfig_TrafficProtocolMapperEntry>, I>>(
    base?: I,
  ): SSAConfig_TrafficProtocolMapperEntry {
    return SSAConfig_TrafficProtocolMapperEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SSAConfig_TrafficProtocolMapperEntry>, I>>(
    object: I,
  ): SSAConfig_TrafficProtocolMapperEntry {
    const message = createBaseSSAConfig_TrafficProtocolMapperEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBasePrometheusConfig(): PrometheusConfig {
  return { url: "", timeout: 0 };
}

export const PrometheusConfig = {
  encode(message: PrometheusConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.timeout !== 0) {
      writer.uint32(16).uint32(message.timeout);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrometheusConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrometheusConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timeout = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrometheusConfig {
    return {
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      timeout: isSet(object.timeout) ? globalThis.Number(object.timeout) : 0,
    };
  },

  toJSON(message: PrometheusConfig): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.timeout !== 0) {
      obj.timeout = Math.round(message.timeout);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrometheusConfig>, I>>(base?: I): PrometheusConfig {
    return PrometheusConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrometheusConfig>, I>>(object: I): PrometheusConfig {
    const message = createBasePrometheusConfig();
    message.url = object.url ?? "";
    message.timeout = object.timeout ?? 0;
    return message;
  },
};

function createBaseClickHouseConfig(): ClickHouseConfig {
  return { addresses: "", database: "", username: "", password: "" };
}

export const ClickHouseConfig = {
  encode(message: ClickHouseConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.addresses !== "") {
      writer.uint32(10).string(message.addresses);
    }
    if (message.database !== "") {
      writer.uint32(18).string(message.database);
    }
    if (message.username !== "") {
      writer.uint32(26).string(message.username);
    }
    if (message.password !== "") {
      writer.uint32(34).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClickHouseConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClickHouseConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.addresses = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.database = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.username = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClickHouseConfig {
    return {
      addresses: isSet(object.addresses) ? globalThis.String(object.addresses) : "",
      database: isSet(object.database) ? globalThis.String(object.database) : "",
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
    };
  },

  toJSON(message: ClickHouseConfig): unknown {
    const obj: any = {};
    if (message.addresses !== "") {
      obj.addresses = message.addresses;
    }
    if (message.database !== "") {
      obj.database = message.database;
    }
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClickHouseConfig>, I>>(base?: I): ClickHouseConfig {
    return ClickHouseConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClickHouseConfig>, I>>(object: I): ClickHouseConfig {
    const message = createBaseClickHouseConfig();
    message.addresses = object.addresses ?? "";
    message.database = object.database ?? "";
    message.username = object.username ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseSlackChannel(): SlackChannel {
  return { webhookUrl: "" };
}

export const SlackChannel = {
  encode(message: SlackChannel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.webhookUrl !== "") {
      writer.uint32(10).string(message.webhookUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlackChannel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlackChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhookUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SlackChannel {
    return { webhookUrl: isSet(object.webhookUrl) ? globalThis.String(object.webhookUrl) : "" };
  },

  toJSON(message: SlackChannel): unknown {
    const obj: any = {};
    if (message.webhookUrl !== "") {
      obj.webhookUrl = message.webhookUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SlackChannel>, I>>(base?: I): SlackChannel {
    return SlackChannel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SlackChannel>, I>>(object: I): SlackChannel {
    const message = createBaseSlackChannel();
    message.webhookUrl = object.webhookUrl ?? "";
    return message;
  },
};

function createBaseEmailChannel(): EmailChannel {
  return { emailAddress: "" };
}

export const EmailChannel = {
  encode(message: EmailChannel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.emailAddress !== "") {
      writer.uint32(10).string(message.emailAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailChannel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.emailAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmailChannel {
    return { emailAddress: isSet(object.emailAddress) ? globalThis.String(object.emailAddress) : "" };
  },

  toJSON(message: EmailChannel): unknown {
    const obj: any = {};
    if (message.emailAddress !== "") {
      obj.emailAddress = message.emailAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmailChannel>, I>>(base?: I): EmailChannel {
    return EmailChannel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmailChannel>, I>>(object: I): EmailChannel {
    const message = createBaseEmailChannel();
    message.emailAddress = object.emailAddress ?? "";
    return message;
  },
};

function createBaseWebhookChannel(): WebhookChannel {
  return { webhookUrl: "" };
}

export const WebhookChannel = {
  encode(message: WebhookChannel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.webhookUrl !== "") {
      writer.uint32(10).string(message.webhookUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookChannel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhookUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebhookChannel {
    return { webhookUrl: isSet(object.webhookUrl) ? globalThis.String(object.webhookUrl) : "" };
  },

  toJSON(message: WebhookChannel): unknown {
    const obj: any = {};
    if (message.webhookUrl !== "") {
      obj.webhookUrl = message.webhookUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebhookChannel>, I>>(base?: I): WebhookChannel {
    return WebhookChannel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebhookChannel>, I>>(object: I): WebhookChannel {
    const message = createBaseWebhookChannel();
    message.webhookUrl = object.webhookUrl ?? "";
    return message;
  },
};

function createBaseMSTeamsChannel(): MSTeamsChannel {
  return { webhookUrl: "" };
}

export const MSTeamsChannel = {
  encode(message: MSTeamsChannel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.webhookUrl !== "") {
      writer.uint32(10).string(message.webhookUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MSTeamsChannel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMSTeamsChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhookUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MSTeamsChannel {
    return { webhookUrl: isSet(object.webhookUrl) ? globalThis.String(object.webhookUrl) : "" };
  },

  toJSON(message: MSTeamsChannel): unknown {
    const obj: any = {};
    if (message.webhookUrl !== "") {
      obj.webhookUrl = message.webhookUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MSTeamsChannel>, I>>(base?: I): MSTeamsChannel {
    return MSTeamsChannel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MSTeamsChannel>, I>>(object: I): MSTeamsChannel {
    const message = createBaseMSTeamsChannel();
    message.webhookUrl = object.webhookUrl ?? "";
    return message;
  },
};

function createBaseOpsGenieChannel(): OpsGenieChannel {
  return { apiUrl: "", apiKey: "" };
}

export const OpsGenieChannel = {
  encode(message: OpsGenieChannel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.apiUrl !== "") {
      writer.uint32(10).string(message.apiUrl);
    }
    if (message.apiKey !== "") {
      writer.uint32(18).string(message.apiKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OpsGenieChannel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpsGenieChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.apiUrl = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.apiKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OpsGenieChannel {
    return {
      apiUrl: isSet(object.apiUrl) ? globalThis.String(object.apiUrl) : "",
      apiKey: isSet(object.apiKey) ? globalThis.String(object.apiKey) : "",
    };
  },

  toJSON(message: OpsGenieChannel): unknown {
    const obj: any = {};
    if (message.apiUrl !== "") {
      obj.apiUrl = message.apiUrl;
    }
    if (message.apiKey !== "") {
      obj.apiKey = message.apiKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OpsGenieChannel>, I>>(base?: I): OpsGenieChannel {
    return OpsGenieChannel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OpsGenieChannel>, I>>(object: I): OpsGenieChannel {
    const message = createBaseOpsGenieChannel();
    message.apiUrl = object.apiUrl ?? "";
    message.apiKey = object.apiKey ?? "";
    return message;
  },
};

function createBaseChannel(): Channel {
  return {
    id: "0",
    orgId: "",
    name: "",
    description: "",
    channelType: 0,
    createdBy: "",
    updatedBy: "",
    created: undefined,
    updated: undefined,
    slackConfig: undefined,
    emailConfig: undefined,
    webhookConfig: undefined,
    msTeams: undefined,
    opsGenie: undefined,
    status: 0,
    alerts: [],
  };
}

export const Channel = {
  encode(message: Channel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.orgId !== "") {
      writer.uint32(18).string(message.orgId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.channelType !== 0) {
      writer.uint32(40).int32(message.channelType);
    }
    if (message.createdBy !== "") {
      writer.uint32(50).string(message.createdBy);
    }
    if (message.updatedBy !== "") {
      writer.uint32(58).string(message.updatedBy);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(66).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(74).fork()).ldelim();
    }
    if (message.slackConfig !== undefined) {
      SlackChannel.encode(message.slackConfig, writer.uint32(82).fork()).ldelim();
    }
    if (message.emailConfig !== undefined) {
      EmailChannel.encode(message.emailConfig, writer.uint32(90).fork()).ldelim();
    }
    if (message.webhookConfig !== undefined) {
      WebhookChannel.encode(message.webhookConfig, writer.uint32(98).fork()).ldelim();
    }
    if (message.msTeams !== undefined) {
      MSTeamsChannel.encode(message.msTeams, writer.uint32(170).fork()).ldelim();
    }
    if (message.opsGenie !== undefined) {
      OpsGenieChannel.encode(message.opsGenie, writer.uint32(178).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(104).int32(message.status);
    }
    for (const v of message.alerts) {
      Alert.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Channel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.channelType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.updatedBy = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.slackConfig = SlackChannel.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.emailConfig = EmailChannel.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.webhookConfig = WebhookChannel.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.msTeams = MSTeamsChannel.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.opsGenie = OpsGenieChannel.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.alerts.push(Alert.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Channel {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "0",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      channelType: isSet(object.channelType) ? channelTypeFromJSON(object.channelType) : 0,
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      updatedBy: isSet(object.updatedBy) ? globalThis.String(object.updatedBy) : "",
      created: isSet(object.created) ? globalThis.String(object.created) : undefined,
      updated: isSet(object.updated) ? globalThis.String(object.updated) : undefined,
      slackConfig: isSet(object.slackConfig) ? SlackChannel.fromJSON(object.slackConfig) : undefined,
      emailConfig: isSet(object.emailConfig) ? EmailChannel.fromJSON(object.emailConfig) : undefined,
      webhookConfig: isSet(object.webhookConfig) ? WebhookChannel.fromJSON(object.webhookConfig) : undefined,
      msTeams: isSet(object.msTeams) ? MSTeamsChannel.fromJSON(object.msTeams) : undefined,
      opsGenie: isSet(object.opsGenie) ? OpsGenieChannel.fromJSON(object.opsGenie) : undefined,
      status: isSet(object.status) ? statusFromJSON(object.status) : 0,
      alerts: globalThis.Array.isArray(object?.alerts) ? object.alerts.map((e: any) => Alert.fromJSON(e)) : [],
    };
  },

  toJSON(message: Channel): unknown {
    const obj: any = {};
    if (message.id !== "0") {
      obj.id = message.id;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.channelType !== 0) {
      obj.channelType = channelTypeToJSON(message.channelType);
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.updatedBy !== "") {
      obj.updatedBy = message.updatedBy;
    }
    if (message.created !== undefined) {
      obj.created = message.created;
    }
    if (message.updated !== undefined) {
      obj.updated = message.updated;
    }
    if (message.slackConfig !== undefined) {
      obj.slackConfig = SlackChannel.toJSON(message.slackConfig);
    }
    if (message.emailConfig !== undefined) {
      obj.emailConfig = EmailChannel.toJSON(message.emailConfig);
    }
    if (message.webhookConfig !== undefined) {
      obj.webhookConfig = WebhookChannel.toJSON(message.webhookConfig);
    }
    if (message.msTeams !== undefined) {
      obj.msTeams = MSTeamsChannel.toJSON(message.msTeams);
    }
    if (message.opsGenie !== undefined) {
      obj.opsGenie = OpsGenieChannel.toJSON(message.opsGenie);
    }
    if (message.status !== 0) {
      obj.status = statusToJSON(message.status);
    }
    if (message.alerts?.length) {
      obj.alerts = message.alerts.map((e) => Alert.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Channel>, I>>(base?: I): Channel {
    return Channel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Channel>, I>>(object: I): Channel {
    const message = createBaseChannel();
    message.id = object.id ?? "0";
    message.orgId = object.orgId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.channelType = object.channelType ?? 0;
    message.createdBy = object.createdBy ?? "";
    message.updatedBy = object.updatedBy ?? "";
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.slackConfig = (object.slackConfig !== undefined && object.slackConfig !== null)
      ? SlackChannel.fromPartial(object.slackConfig)
      : undefined;
    message.emailConfig = (object.emailConfig !== undefined && object.emailConfig !== null)
      ? EmailChannel.fromPartial(object.emailConfig)
      : undefined;
    message.webhookConfig = (object.webhookConfig !== undefined && object.webhookConfig !== null)
      ? WebhookChannel.fromPartial(object.webhookConfig)
      : undefined;
    message.msTeams = (object.msTeams !== undefined && object.msTeams !== null)
      ? MSTeamsChannel.fromPartial(object.msTeams)
      : undefined;
    message.opsGenie = (object.opsGenie !== undefined && object.opsGenie !== null)
      ? OpsGenieChannel.fromPartial(object.opsGenie)
      : undefined;
    message.status = object.status ?? 0;
    message.alerts = object.alerts?.map((e) => Alert.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAlertTemplate(): AlertTemplate {
  return {
    id: "0",
    name: "",
    version: "0",
    displayName: "",
    description: "",
    category: 0,
    configFormat: undefined,
    updated: undefined,
  };
}

export const AlertTemplate = {
  encode(message: AlertTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.version !== "0") {
      writer.uint32(24).uint64(message.version);
    }
    if (message.displayName !== "") {
      writer.uint32(34).string(message.displayName);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.category !== 0) {
      writer.uint32(48).int32(message.category);
    }
    if (message.configFormat !== undefined) {
      Struct.encode(Struct.wrap(message.configFormat), writer.uint32(58).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AlertTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlertTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.configFormat = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlertTemplate {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "0",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "0",
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      category: isSet(object.category) ? alertCategoryFromJSON(object.category) : 0,
      configFormat: isObject(object.configFormat) ? object.configFormat : undefined,
      updated: isSet(object.updated) ? globalThis.String(object.updated) : undefined,
    };
  },

  toJSON(message: AlertTemplate): unknown {
    const obj: any = {};
    if (message.id !== "0") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.version !== "0") {
      obj.version = message.version;
    }
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.category !== 0) {
      obj.category = alertCategoryToJSON(message.category);
    }
    if (message.configFormat !== undefined) {
      obj.configFormat = message.configFormat;
    }
    if (message.updated !== undefined) {
      obj.updated = message.updated;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlertTemplate>, I>>(base?: I): AlertTemplate {
    return AlertTemplate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlertTemplate>, I>>(object: I): AlertTemplate {
    const message = createBaseAlertTemplate();
    message.id = object.id ?? "0";
    message.name = object.name ?? "";
    message.version = object.version ?? "0";
    message.displayName = object.displayName ?? "";
    message.description = object.description ?? "";
    message.category = object.category ?? 0;
    message.configFormat = object.configFormat ?? undefined;
    message.updated = object.updated ?? undefined;
    return message;
  },
};

function createBaseAlert(): Alert {
  return {
    id: "0",
    name: "",
    siteId: "0",
    templateId: "0",
    description: "",
    channels: [],
    status: 0,
    created: undefined,
    updated: undefined,
    createdBy: "",
    updatedBy: "",
    config: undefined,
    lastTrigger: undefined,
    firingState: 0,
    severity: 0,
    userTags: {},
  };
}

export const Alert = {
  encode(message: Alert, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.siteId !== "0") {
      writer.uint32(24).uint64(message.siteId);
    }
    if (message.templateId !== "0") {
      writer.uint32(32).uint64(message.templateId);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    for (const v of message.channels) {
      Channel.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(56).int32(message.status);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(66).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(74).fork()).ldelim();
    }
    if (message.createdBy !== "") {
      writer.uint32(82).string(message.createdBy);
    }
    if (message.updatedBy !== "") {
      writer.uint32(90).string(message.updatedBy);
    }
    if (message.config !== undefined) {
      Struct.encode(Struct.wrap(message.config), writer.uint32(98).fork()).ldelim();
    }
    if (message.lastTrigger !== undefined) {
      Timestamp.encode(toTimestamp(message.lastTrigger), writer.uint32(106).fork()).ldelim();
    }
    if (message.firingState !== 0) {
      writer.uint32(112).int32(message.firingState);
    }
    if (message.severity !== 0) {
      writer.uint32(120).int32(message.severity);
    }
    Object.entries(message.userTags).forEach(([key, value]) => {
      Alert_UserTagsEntry.encode({ key: key as any, value }, writer.uint32(130).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Alert {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlert();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.templateId = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.channels.push(Channel.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.updatedBy = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.config = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.lastTrigger = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.firingState = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.severity = reader.int32() as any;
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          const entry16 = Alert_UserTagsEntry.decode(reader, reader.uint32());
          if (entry16.value !== undefined) {
            message.userTags[entry16.key] = entry16.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Alert {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "0",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "0",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      channels: globalThis.Array.isArray(object?.channels) ? object.channels.map((e: any) => Channel.fromJSON(e)) : [],
      status: isSet(object.status) ? statusFromJSON(object.status) : 0,
      created: isSet(object.created) ? globalThis.String(object.created) : undefined,
      updated: isSet(object.updated) ? globalThis.String(object.updated) : undefined,
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      updatedBy: isSet(object.updatedBy) ? globalThis.String(object.updatedBy) : "",
      config: isObject(object.config) ? object.config : undefined,
      lastTrigger: isSet(object.lastTrigger) ? globalThis.String(object.lastTrigger) : undefined,
      firingState: isSet(object.firingState) ? firingStateFromJSON(object.firingState) : 0,
      severity: isSet(object.severity) ? alertSeverityFromJSON(object.severity) : 0,
      userTags: isObject(object.userTags)
        ? Object.entries(object.userTags).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: Alert): unknown {
    const obj: any = {};
    if (message.id !== "0") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.templateId !== "0") {
      obj.templateId = message.templateId;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.channels?.length) {
      obj.channels = message.channels.map((e) => Channel.toJSON(e));
    }
    if (message.status !== 0) {
      obj.status = statusToJSON(message.status);
    }
    if (message.created !== undefined) {
      obj.created = message.created;
    }
    if (message.updated !== undefined) {
      obj.updated = message.updated;
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.updatedBy !== "") {
      obj.updatedBy = message.updatedBy;
    }
    if (message.config !== undefined) {
      obj.config = message.config;
    }
    if (message.lastTrigger !== undefined) {
      obj.lastTrigger = message.lastTrigger;
    }
    if (message.firingState !== 0) {
      obj.firingState = firingStateToJSON(message.firingState);
    }
    if (message.severity !== 0) {
      obj.severity = alertSeverityToJSON(message.severity);
    }
    if (message.userTags) {
      const entries = Object.entries(message.userTags);
      if (entries.length > 0) {
        obj.userTags = {};
        entries.forEach(([k, v]) => {
          obj.userTags[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Alert>, I>>(base?: I): Alert {
    return Alert.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Alert>, I>>(object: I): Alert {
    const message = createBaseAlert();
    message.id = object.id ?? "0";
    message.name = object.name ?? "";
    message.siteId = object.siteId ?? "0";
    message.templateId = object.templateId ?? "0";
    message.description = object.description ?? "";
    message.channels = object.channels?.map((e) => Channel.fromPartial(e)) || [];
    message.status = object.status ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.createdBy = object.createdBy ?? "";
    message.updatedBy = object.updatedBy ?? "";
    message.config = object.config ?? undefined;
    message.lastTrigger = object.lastTrigger ?? undefined;
    message.firingState = object.firingState ?? 0;
    message.severity = object.severity ?? 0;
    message.userTags = Object.entries(object.userTags ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseAlert_UserTagsEntry(): Alert_UserTagsEntry {
  return { key: "", value: "" };
}

export const Alert_UserTagsEntry = {
  encode(message: Alert_UserTagsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Alert_UserTagsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlert_UserTagsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Alert_UserTagsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: Alert_UserTagsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Alert_UserTagsEntry>, I>>(base?: I): Alert_UserTagsEntry {
    return Alert_UserTagsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Alert_UserTagsEntry>, I>>(object: I): Alert_UserTagsEntry {
    const message = createBaseAlert_UserTagsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBasePrometheusAlertEvent(): PrometheusAlertEvent {
  return {
    status: "",
    labels: undefined,
    annotations: undefined,
    generatorURL: "",
    fingerprint: "",
    startsAt: undefined,
    endsAt: undefined,
  };
}

export const PrometheusAlertEvent = {
  encode(message: PrometheusAlertEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    if (message.labels !== undefined) {
      Struct.encode(Struct.wrap(message.labels), writer.uint32(18).fork()).ldelim();
    }
    if (message.annotations !== undefined) {
      Struct.encode(Struct.wrap(message.annotations), writer.uint32(26).fork()).ldelim();
    }
    if (message.generatorURL !== "") {
      writer.uint32(34).string(message.generatorURL);
    }
    if (message.fingerprint !== "") {
      writer.uint32(42).string(message.fingerprint);
    }
    if (message.startsAt !== undefined) {
      Timestamp.encode(toTimestamp(message.startsAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.endsAt !== undefined) {
      Timestamp.encode(toTimestamp(message.endsAt), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrometheusAlertEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrometheusAlertEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.labels = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.annotations = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.generatorURL = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fingerprint = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.startsAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.endsAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrometheusAlertEvent {
    return {
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      labels: isObject(object.labels) ? object.labels : undefined,
      annotations: isObject(object.annotations) ? object.annotations : undefined,
      generatorURL: isSet(object.generatorURL) ? globalThis.String(object.generatorURL) : "",
      fingerprint: isSet(object.fingerprint) ? globalThis.String(object.fingerprint) : "",
      startsAt: isSet(object.startsAt) ? globalThis.String(object.startsAt) : undefined,
      endsAt: isSet(object.endsAt) ? globalThis.String(object.endsAt) : undefined,
    };
  },

  toJSON(message: PrometheusAlertEvent): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.labels !== undefined) {
      obj.labels = message.labels;
    }
    if (message.annotations !== undefined) {
      obj.annotations = message.annotations;
    }
    if (message.generatorURL !== "") {
      obj.generatorURL = message.generatorURL;
    }
    if (message.fingerprint !== "") {
      obj.fingerprint = message.fingerprint;
    }
    if (message.startsAt !== undefined) {
      obj.startsAt = message.startsAt;
    }
    if (message.endsAt !== undefined) {
      obj.endsAt = message.endsAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrometheusAlertEvent>, I>>(base?: I): PrometheusAlertEvent {
    return PrometheusAlertEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrometheusAlertEvent>, I>>(object: I): PrometheusAlertEvent {
    const message = createBasePrometheusAlertEvent();
    message.status = object.status ?? "";
    message.labels = object.labels ?? undefined;
    message.annotations = object.annotations ?? undefined;
    message.generatorURL = object.generatorURL ?? "";
    message.fingerprint = object.fingerprint ?? "";
    message.startsAt = object.startsAt ?? undefined;
    message.endsAt = object.endsAt ?? undefined;
    return message;
  },
};

function createBasePrometheusAlertManagerEvent(): PrometheusAlertManagerEvent {
  return {
    version: "",
    groupKey: "",
    truncatedAlerts: 0,
    status: "",
    receiver: "",
    groupLabels: undefined,
    commonLabels: undefined,
    commonAnnotations: undefined,
    externalURL: "",
    alerts: [],
  };
}

export const PrometheusAlertManagerEvent = {
  encode(message: PrometheusAlertManagerEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.version !== "") {
      writer.uint32(10).string(message.version);
    }
    if (message.groupKey !== "") {
      writer.uint32(18).string(message.groupKey);
    }
    if (message.truncatedAlerts !== 0) {
      writer.uint32(24).uint32(message.truncatedAlerts);
    }
    if (message.status !== "") {
      writer.uint32(34).string(message.status);
    }
    if (message.receiver !== "") {
      writer.uint32(42).string(message.receiver);
    }
    if (message.groupLabels !== undefined) {
      Struct.encode(Struct.wrap(message.groupLabels), writer.uint32(50).fork()).ldelim();
    }
    if (message.commonLabels !== undefined) {
      Struct.encode(Struct.wrap(message.commonLabels), writer.uint32(58).fork()).ldelim();
    }
    if (message.commonAnnotations !== undefined) {
      Struct.encode(Struct.wrap(message.commonAnnotations), writer.uint32(66).fork()).ldelim();
    }
    if (message.externalURL !== "") {
      writer.uint32(74).string(message.externalURL);
    }
    for (const v of message.alerts) {
      PrometheusAlertEvent.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrometheusAlertManagerEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrometheusAlertManagerEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.version = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groupKey = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.truncatedAlerts = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.status = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.receiver = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.groupLabels = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.commonLabels = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.commonAnnotations = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.externalURL = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.alerts.push(PrometheusAlertEvent.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrometheusAlertManagerEvent {
    return {
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      groupKey: isSet(object.groupKey) ? globalThis.String(object.groupKey) : "",
      truncatedAlerts: isSet(object.truncatedAlerts) ? globalThis.Number(object.truncatedAlerts) : 0,
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      receiver: isSet(object.receiver) ? globalThis.String(object.receiver) : "",
      groupLabels: isObject(object.groupLabels) ? object.groupLabels : undefined,
      commonLabels: isObject(object.commonLabels) ? object.commonLabels : undefined,
      commonAnnotations: isObject(object.commonAnnotations) ? object.commonAnnotations : undefined,
      externalURL: isSet(object.externalURL) ? globalThis.String(object.externalURL) : "",
      alerts: globalThis.Array.isArray(object?.alerts)
        ? object.alerts.map((e: any) => PrometheusAlertEvent.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PrometheusAlertManagerEvent): unknown {
    const obj: any = {};
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.groupKey !== "") {
      obj.groupKey = message.groupKey;
    }
    if (message.truncatedAlerts !== 0) {
      obj.truncatedAlerts = Math.round(message.truncatedAlerts);
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.receiver !== "") {
      obj.receiver = message.receiver;
    }
    if (message.groupLabels !== undefined) {
      obj.groupLabels = message.groupLabels;
    }
    if (message.commonLabels !== undefined) {
      obj.commonLabels = message.commonLabels;
    }
    if (message.commonAnnotations !== undefined) {
      obj.commonAnnotations = message.commonAnnotations;
    }
    if (message.externalURL !== "") {
      obj.externalURL = message.externalURL;
    }
    if (message.alerts?.length) {
      obj.alerts = message.alerts.map((e) => PrometheusAlertEvent.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrometheusAlertManagerEvent>, I>>(base?: I): PrometheusAlertManagerEvent {
    return PrometheusAlertManagerEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrometheusAlertManagerEvent>, I>>(object: I): PrometheusAlertManagerEvent {
    const message = createBasePrometheusAlertManagerEvent();
    message.version = object.version ?? "";
    message.groupKey = object.groupKey ?? "";
    message.truncatedAlerts = object.truncatedAlerts ?? 0;
    message.status = object.status ?? "";
    message.receiver = object.receiver ?? "";
    message.groupLabels = object.groupLabels ?? undefined;
    message.commonLabels = object.commonLabels ?? undefined;
    message.commonAnnotations = object.commonAnnotations ?? undefined;
    message.externalURL = object.externalURL ?? "";
    message.alerts = object.alerts?.map((e) => PrometheusAlertEvent.fromPartial(e)) || [];
    return message;
  },
};

function createBaseObservoAlertEvent(): ObservoAlertEvent {
  return {
    alertEventId: "0",
    siteId: "0",
    alertId: "0",
    alertName: "",
    severity: 0,
    fingerprint: "",
    state: 0,
    eventMessage: undefined,
    componentId: "0",
    firstEventAt: undefined,
    lastEventAt: undefined,
    isAcknowledged: false,
  };
}

export const ObservoAlertEvent = {
  encode(message: ObservoAlertEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.alertEventId !== "0") {
      writer.uint32(8).uint64(message.alertEventId);
    }
    if (message.siteId !== "0") {
      writer.uint32(16).uint64(message.siteId);
    }
    if (message.alertId !== "0") {
      writer.uint32(24).uint64(message.alertId);
    }
    if (message.alertName !== "") {
      writer.uint32(34).string(message.alertName);
    }
    if (message.severity !== 0) {
      writer.uint32(40).int32(message.severity);
    }
    if (message.fingerprint !== "") {
      writer.uint32(50).string(message.fingerprint);
    }
    if (message.state !== 0) {
      writer.uint32(56).int32(message.state);
    }
    if (message.eventMessage !== undefined) {
      Struct.encode(Struct.wrap(message.eventMessage), writer.uint32(66).fork()).ldelim();
    }
    if (message.componentId !== "0") {
      writer.uint32(72).uint64(message.componentId);
    }
    if (message.firstEventAt !== undefined) {
      Timestamp.encode(toTimestamp(message.firstEventAt), writer.uint32(82).fork()).ldelim();
    }
    if (message.lastEventAt !== undefined) {
      Timestamp.encode(toTimestamp(message.lastEventAt), writer.uint32(90).fork()).ldelim();
    }
    if (message.isAcknowledged !== false) {
      writer.uint32(96).bool(message.isAcknowledged);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObservoAlertEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObservoAlertEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.alertEventId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.alertId = longToString(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.alertName = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.severity = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fingerprint = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.eventMessage = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.componentId = longToString(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.firstEventAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.lastEventAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.isAcknowledged = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ObservoAlertEvent {
    return {
      alertEventId: isSet(object.alertEventId) ? globalThis.String(object.alertEventId) : "0",
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      alertId: isSet(object.alertId) ? globalThis.String(object.alertId) : "0",
      alertName: isSet(object.alertName) ? globalThis.String(object.alertName) : "",
      severity: isSet(object.severity) ? alertSeverityFromJSON(object.severity) : 0,
      fingerprint: isSet(object.fingerprint) ? globalThis.String(object.fingerprint) : "",
      state: isSet(object.state) ? alertEventStateFromJSON(object.state) : 0,
      eventMessage: isObject(object.eventMessage) ? object.eventMessage : undefined,
      componentId: isSet(object.componentId) ? globalThis.String(object.componentId) : "0",
      firstEventAt: isSet(object.firstEventAt) ? globalThis.String(object.firstEventAt) : undefined,
      lastEventAt: isSet(object.lastEventAt) ? globalThis.String(object.lastEventAt) : undefined,
      isAcknowledged: isSet(object.isAcknowledged) ? globalThis.Boolean(object.isAcknowledged) : false,
    };
  },

  toJSON(message: ObservoAlertEvent): unknown {
    const obj: any = {};
    if (message.alertEventId !== "0") {
      obj.alertEventId = message.alertEventId;
    }
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.alertId !== "0") {
      obj.alertId = message.alertId;
    }
    if (message.alertName !== "") {
      obj.alertName = message.alertName;
    }
    if (message.severity !== 0) {
      obj.severity = alertSeverityToJSON(message.severity);
    }
    if (message.fingerprint !== "") {
      obj.fingerprint = message.fingerprint;
    }
    if (message.state !== 0) {
      obj.state = alertEventStateToJSON(message.state);
    }
    if (message.eventMessage !== undefined) {
      obj.eventMessage = message.eventMessage;
    }
    if (message.componentId !== "0") {
      obj.componentId = message.componentId;
    }
    if (message.firstEventAt !== undefined) {
      obj.firstEventAt = message.firstEventAt;
    }
    if (message.lastEventAt !== undefined) {
      obj.lastEventAt = message.lastEventAt;
    }
    if (message.isAcknowledged !== false) {
      obj.isAcknowledged = message.isAcknowledged;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ObservoAlertEvent>, I>>(base?: I): ObservoAlertEvent {
    return ObservoAlertEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ObservoAlertEvent>, I>>(object: I): ObservoAlertEvent {
    const message = createBaseObservoAlertEvent();
    message.alertEventId = object.alertEventId ?? "0";
    message.siteId = object.siteId ?? "0";
    message.alertId = object.alertId ?? "0";
    message.alertName = object.alertName ?? "";
    message.severity = object.severity ?? 0;
    message.fingerprint = object.fingerprint ?? "";
    message.state = object.state ?? 0;
    message.eventMessage = object.eventMessage ?? undefined;
    message.componentId = object.componentId ?? "0";
    message.firstEventAt = object.firstEventAt ?? undefined;
    message.lastEventAt = object.lastEventAt ?? undefined;
    message.isAcknowledged = object.isAcknowledged ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
