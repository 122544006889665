import { createIcon } from '@chakra-ui/react';
import { COLORS } from 'src/constants/colors';

export const ErrorCircleIcon = createIcon({
  displayName: 'ErrorCircleIcon',
  viewBox: '0 0 22 22',
  path: [
    <path
      d="M14 8L8 14M8 8L14 14M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
      stroke={COLORS.status.error}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />,
  ],
});
