import { useCallback, useState } from 'react';
import useFilter from 'src/components/Filters/hooks/useFilter';
import { FilterStorageType, SelectedFilterValues } from 'src/components/Filters/types';
import { APP_DEFAULT_FILTER_VALUES, LOCAL_STORAGE_FILTER_TIMESTAMP } from 'src/constants';
import {
  DATE_FILTER_START_DATE,
  DATE_FILTER_VALUES,
  DEFAULT_DATE_FILTER_VALUE,
} from 'src/constants/dateFilter';
import { FilterViewName } from 'src/constants/filters';
import { TIMEFRAME_RESOLUTION_MAP } from 'src/pages/Monitoring/constants';
import { DateFilter } from 'src/types/DateFilter';
import { convertDateToUnixTimeSeconds, getAnalyticsEndDate } from 'src/utils/date';
import { getDefaultDateFilterValues } from 'src/utils/getDefaultFilterValues';
import useOnMountEffect from './useOnMountEffect';

const getDefaultTimeQueryValues = (selectedFilterValues: SelectedFilterValues) => ({
  startDateUnixTime: convertDateToUnixTimeSeconds(getDefaultDateFilterValues(selectedFilterValues)),
  endDateUnixTime: convertDateToUnixTimeSeconds(getAnalyticsEndDate()),
  resolutionInSeconds:
    TIMEFRAME_RESOLUTION_MAP[
      (selectedFilterValues.date[0] as DateFilter) || DEFAULT_DATE_FILTER_VALUE
    ],
});

export const useGetTimeFilterValue = () => {
  const {
    onChange: onSelectedFilterValuesChange,
    selectedFilterValues,
    setSelectedFilterValues,
  } = useFilter(
    APP_DEFAULT_FILTER_VALUES,
    FilterViewName.TimeFilter,
    FilterStorageType.LocalStorage,
  );

  const currentDateFilterDisplayName = DATE_FILTER_VALUES.find(
    (dateFilter) => dateFilter.id === selectedFilterValues.date[0],
  )?.displayName;

  const [timeQuery, setTimeQuery] = useState(getDefaultTimeQueryValues(selectedFilterValues));

  const canResetTimeFilter = selectedFilterValues.date[0] !== DEFAULT_DATE_FILTER_VALUE;

  const resetTimeFilter = () => {
    setSelectedFilterValues(APP_DEFAULT_FILTER_VALUES);
    setTimeQuery(getDefaultTimeQueryValues(APP_DEFAULT_FILTER_VALUES));
  };

  useOnMountEffect(() => {
    const localStorageFilterTimestamp = localStorage.getItem('localStorageFilterTimestamp');
    if (localStorageFilterTimestamp !== LOCAL_STORAGE_FILTER_TIMESTAMP) {
      localStorage.setItem('localStorageFilterTimestamp', LOCAL_STORAGE_FILTER_TIMESTAMP);
      resetTimeFilter();
    }
  });

  const handleFilterChange = (filterName: string, filterValue: string[]) => {
    onSelectedFilterValuesChange(filterName, filterValue);
    if (filterName === 'date') {
      const currentDateFilter = (filterValue[0] as DateFilter) || DEFAULT_DATE_FILTER_VALUE;

      const startDate = DATE_FILTER_START_DATE[currentDateFilter]();
      const currentResolutionInSeconds = TIMEFRAME_RESOLUTION_MAP[currentDateFilter];
      setTimeQuery(() => ({
        startDateUnixTime: convertDateToUnixTimeSeconds(startDate),
        endDateUnixTime: convertDateToUnixTimeSeconds(getAnalyticsEndDate()),
        resolutionInSeconds: currentResolutionInSeconds,
      }));
    }
  };

  const refreshTimeQuery = useCallback(() => {
    setTimeQuery(getDefaultTimeQueryValues(selectedFilterValues));
  }, [selectedFilterValues]);

  return {
    timeQuery,
    handleFilterChange,
    selectedFilterValues,
    currentDateFilterDisplayName,
    canResetTimeFilter,
    resetTimeFilter,
    refreshTimeQuery,
  };
};
