import { rgba } from 'polished';
import { DialogType } from 'src/components/Dialog/Dialog';
import { COLORS, OPACITY } from 'src/constants/colors';

export const headerBgColor = (type: DialogType) => {
  if (type === 'delete' || type === 'error') {
    return rgba(COLORS.status.error, OPACITY[8]);
  }
  if (type === 'alert') {
    return rgba(COLORS.status.warning, OPACITY[8]);
  }
  return rgba(COLORS.link, OPACITY[8]);
};

export const dividerBgColor = (type: DialogType) => {
  if (type === 'delete' || type === 'error') {
    return COLORS.status.error;
  }
  if (type === 'alert') {
    return COLORS.status.warning;
  }
  return COLORS.link;
};
