/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Struct } from "../../../../../google/protobuf/struct";
import { Timestamp } from "../../../../../google/protobuf/timestamp";

export const protobufPackage = "v1.site_manager";

export enum DynamicLookupJobStatus {
  DYNAMIC_LOOKUP_JOB_STATUS_UNSPECIFIED = 0,
  DYNAMIC_LOOKUP_JOB_STATUS_PENDING = 1,
  DYNAMIC_LOOKUP_JOB_RUNNING = 2,
  DYNAMIC_LOOKUP_JOB_DELETED = 3,
  UNRECOGNIZED = -1,
}

export function dynamicLookupJobStatusFromJSON(object: any): DynamicLookupJobStatus {
  switch (object) {
    case 0:
    case "DYNAMIC_LOOKUP_JOB_STATUS_UNSPECIFIED":
      return DynamicLookupJobStatus.DYNAMIC_LOOKUP_JOB_STATUS_UNSPECIFIED;
    case 1:
    case "DYNAMIC_LOOKUP_JOB_STATUS_PENDING":
      return DynamicLookupJobStatus.DYNAMIC_LOOKUP_JOB_STATUS_PENDING;
    case 2:
    case "DYNAMIC_LOOKUP_JOB_RUNNING":
      return DynamicLookupJobStatus.DYNAMIC_LOOKUP_JOB_RUNNING;
    case 3:
    case "DYNAMIC_LOOKUP_JOB_DELETED":
      return DynamicLookupJobStatus.DYNAMIC_LOOKUP_JOB_DELETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DynamicLookupJobStatus.UNRECOGNIZED;
  }
}

export function dynamicLookupJobStatusToJSON(object: DynamicLookupJobStatus): string {
  switch (object) {
    case DynamicLookupJobStatus.DYNAMIC_LOOKUP_JOB_STATUS_UNSPECIFIED:
      return "DYNAMIC_LOOKUP_JOB_STATUS_UNSPECIFIED";
    case DynamicLookupJobStatus.DYNAMIC_LOOKUP_JOB_STATUS_PENDING:
      return "DYNAMIC_LOOKUP_JOB_STATUS_PENDING";
    case DynamicLookupJobStatus.DYNAMIC_LOOKUP_JOB_RUNNING:
      return "DYNAMIC_LOOKUP_JOB_RUNNING";
    case DynamicLookupJobStatus.DYNAMIC_LOOKUP_JOB_DELETED:
      return "DYNAMIC_LOOKUP_JOB_DELETED";
    case DynamicLookupJobStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Point {
  latitude: number;
  longitude: number;
}

/** Next ID: 24 */
export interface Site {
  id: string;
  orgId: string;
  name: string;
  description: string;
  status: Site_Status;
  geoLocation: Point | undefined;
  vendor: Site_Vendor;
  /** View only, port is used internally for tunneling. */
  tunnelPort: number;
  /** Vendor specific configuration. */
  vendorConfig: { [key: string]: any } | undefined;
  maxInstances: number;
  minInstances: number;
  createdBy: string;
  updatedBy: string;
  created: string | undefined;
  updated:
    | string
    | undefined;
  /**
   * ca_cert is CA certificate that can be used by clients connecting
   * to push based source
   */
  caCert: string;
  lastAlive:
    | string
    | undefined;
  /**
   * push_source_ingress refers to the ingress endpoint
   * which is used by push based sources with unique subpaths
   */
  pushSourceIngress: string;
  /**
   * push_source_lb refers to the endpoint which is used by
   * push based sources with unique ports
   */
  pushSourceLb: string;
  k8sInternalSvcUrl: string;
  version: string;
}

export enum Site_Status {
  DEFAULT_STATUS = 0,
  ACTIVE = 1,
  DELETED = 2,
  INACTIVE = 3,
  UNRECOGNIZED = -1,
}

export function site_StatusFromJSON(object: any): Site_Status {
  switch (object) {
    case 0:
    case "DEFAULT_STATUS":
      return Site_Status.DEFAULT_STATUS;
    case 1:
    case "ACTIVE":
      return Site_Status.ACTIVE;
    case 2:
    case "DELETED":
      return Site_Status.DELETED;
    case 3:
    case "INACTIVE":
      return Site_Status.INACTIVE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Site_Status.UNRECOGNIZED;
  }
}

export function site_StatusToJSON(object: Site_Status): string {
  switch (object) {
    case Site_Status.DEFAULT_STATUS:
      return "DEFAULT_STATUS";
    case Site_Status.ACTIVE:
      return "ACTIVE";
    case Site_Status.DELETED:
      return "DELETED";
    case Site_Status.INACTIVE:
      return "INACTIVE";
    case Site_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Site_Vendor {
  DEFAULT_TYPE = 0,
  AWS = 1,
  AZURE = 2,
  GCP = 3,
  ON_PREMISE = 4,
  AIR_GAPPED = 5,
  UNRECOGNIZED = -1,
}

export function site_VendorFromJSON(object: any): Site_Vendor {
  switch (object) {
    case 0:
    case "DEFAULT_TYPE":
      return Site_Vendor.DEFAULT_TYPE;
    case 1:
    case "AWS":
      return Site_Vendor.AWS;
    case 2:
    case "AZURE":
      return Site_Vendor.AZURE;
    case 3:
    case "GCP":
      return Site_Vendor.GCP;
    case 4:
    case "ON_PREMISE":
      return Site_Vendor.ON_PREMISE;
    case 5:
    case "AIR_GAPPED":
      return Site_Vendor.AIR_GAPPED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Site_Vendor.UNRECOGNIZED;
  }
}

export function site_VendorToJSON(object: Site_Vendor): string {
  switch (object) {
    case Site_Vendor.DEFAULT_TYPE:
      return "DEFAULT_TYPE";
    case Site_Vendor.AWS:
      return "AWS";
    case Site_Vendor.AZURE:
      return "AZURE";
    case Site_Vendor.GCP:
      return "GCP";
    case Site_Vendor.ON_PREMISE:
      return "ON_PREMISE";
    case Site_Vendor.AIR_GAPPED:
      return "AIR_GAPPED";
    case Site_Vendor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Next ID: 16 */
export interface SiteFile {
  id: string;
  siteId: string;
  filename: string;
  description: string;
  fileType: SiteFile_FileType;
  data: string;
  createdBy: string;
  updatedBy: string;
  created: string | undefined;
  updated: string | undefined;
  filepath: string;
  /** For DATA and LOOK_UP_DATA site file type data will be filled in data_chunk */
  dataChunk: Uint8Array;
  fileSizeBytes: string;
  compressionType: SiteFile_CompressionType;
  fileSchema: { [key: string]: any } | undefined;
  dynamicLookupJob: DynamicLookupJob | undefined;
}

export enum SiteFile_FileType {
  DEFAULT_TYPE = 0,
  SECRET = 1,
  DATA = 2,
  LOOK_UP_DATA = 3,
  DYNAMIC_LOOK_UP = 4,
  UNRECOGNIZED = -1,
}

export function siteFile_FileTypeFromJSON(object: any): SiteFile_FileType {
  switch (object) {
    case 0:
    case "DEFAULT_TYPE":
      return SiteFile_FileType.DEFAULT_TYPE;
    case 1:
    case "SECRET":
      return SiteFile_FileType.SECRET;
    case 2:
    case "DATA":
      return SiteFile_FileType.DATA;
    case 3:
    case "LOOK_UP_DATA":
      return SiteFile_FileType.LOOK_UP_DATA;
    case 4:
    case "DYNAMIC_LOOK_UP":
      return SiteFile_FileType.DYNAMIC_LOOK_UP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SiteFile_FileType.UNRECOGNIZED;
  }
}

export function siteFile_FileTypeToJSON(object: SiteFile_FileType): string {
  switch (object) {
    case SiteFile_FileType.DEFAULT_TYPE:
      return "DEFAULT_TYPE";
    case SiteFile_FileType.SECRET:
      return "SECRET";
    case SiteFile_FileType.DATA:
      return "DATA";
    case SiteFile_FileType.LOOK_UP_DATA:
      return "LOOK_UP_DATA";
    case SiteFile_FileType.DYNAMIC_LOOK_UP:
      return "DYNAMIC_LOOK_UP";
    case SiteFile_FileType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SiteFile_CompressionType {
  NO_COMPRESSION = 0,
  GZIP = 1,
  ZIP = 2,
  UNRECOGNIZED = -1,
}

export function siteFile_CompressionTypeFromJSON(object: any): SiteFile_CompressionType {
  switch (object) {
    case 0:
    case "NO_COMPRESSION":
      return SiteFile_CompressionType.NO_COMPRESSION;
    case 1:
    case "GZIP":
      return SiteFile_CompressionType.GZIP;
    case 2:
    case "ZIP":
      return SiteFile_CompressionType.ZIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SiteFile_CompressionType.UNRECOGNIZED;
  }
}

export function siteFile_CompressionTypeToJSON(object: SiteFile_CompressionType): string {
  switch (object) {
    case SiteFile_CompressionType.NO_COMPRESSION:
      return "NO_COMPRESSION";
    case SiteFile_CompressionType.GZIP:
      return "GZIP";
    case SiteFile_CompressionType.ZIP:
      return "ZIP";
    case SiteFile_CompressionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DynamicLookupJob {
  jobId: string;
  status: DynamicLookupJobStatus;
  resource: JobResource | undefined;
}

export interface JobResource {
  cpuRequest?: number | undefined;
  cpuLimit?: number | undefined;
  memoryRequest?: number | undefined;
  memoryLimit?: number | undefined;
}

function createBasePoint(): Point {
  return { latitude: 0, longitude: 0 };
}

export const Point = {
  encode(message: Point, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.latitude !== 0) {
      writer.uint32(13).float(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(21).float(message.longitude);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Point {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.latitude = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.longitude = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Point {
    return {
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
    };
  },

  toJSON(message: Point): unknown {
    const obj: any = {};
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Point>, I>>(base?: I): Point {
    return Point.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Point>, I>>(object: I): Point {
    const message = createBasePoint();
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    return message;
  },
};

function createBaseSite(): Site {
  return {
    id: "0",
    orgId: "",
    name: "",
    description: "",
    status: 0,
    geoLocation: undefined,
    vendor: 0,
    tunnelPort: 0,
    vendorConfig: undefined,
    maxInstances: 0,
    minInstances: 0,
    createdBy: "",
    updatedBy: "",
    created: undefined,
    updated: undefined,
    caCert: "",
    lastAlive: undefined,
    pushSourceIngress: "",
    pushSourceLb: "",
    k8sInternalSvcUrl: "",
    version: "",
  };
}

export const Site = {
  encode(message: Site, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.orgId !== "") {
      writer.uint32(18).string(message.orgId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.status !== 0) {
      writer.uint32(40).int32(message.status);
    }
    if (message.geoLocation !== undefined) {
      Point.encode(message.geoLocation, writer.uint32(50).fork()).ldelim();
    }
    if (message.vendor !== 0) {
      writer.uint32(56).int32(message.vendor);
    }
    if (message.tunnelPort !== 0) {
      writer.uint32(64).uint32(message.tunnelPort);
    }
    if (message.vendorConfig !== undefined) {
      Struct.encode(Struct.wrap(message.vendorConfig), writer.uint32(74).fork()).ldelim();
    }
    if (message.maxInstances !== 0) {
      writer.uint32(80).int32(message.maxInstances);
    }
    if (message.minInstances !== 0) {
      writer.uint32(88).int32(message.minInstances);
    }
    if (message.createdBy !== "") {
      writer.uint32(98).string(message.createdBy);
    }
    if (message.updatedBy !== "") {
      writer.uint32(106).string(message.updatedBy);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(114).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(122).fork()).ldelim();
    }
    if (message.caCert !== "") {
      writer.uint32(138).string(message.caCert);
    }
    if (message.lastAlive !== undefined) {
      Timestamp.encode(toTimestamp(message.lastAlive), writer.uint32(146).fork()).ldelim();
    }
    if (message.pushSourceIngress !== "") {
      writer.uint32(162).string(message.pushSourceIngress);
    }
    if (message.pushSourceLb !== "") {
      writer.uint32(170).string(message.pushSourceLb);
    }
    if (message.k8sInternalSvcUrl !== "") {
      writer.uint32(178).string(message.k8sInternalSvcUrl);
    }
    if (message.version !== "") {
      writer.uint32(186).string(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Site {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSite();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.geoLocation = Point.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.vendor = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.tunnelPort = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.vendorConfig = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.maxInstances = reader.int32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.minInstances = reader.int32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.updatedBy = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.caCert = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.lastAlive = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.pushSourceIngress = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.pushSourceLb = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.k8sInternalSvcUrl = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.version = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Site {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "0",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      status: isSet(object.status) ? site_StatusFromJSON(object.status) : 0,
      geoLocation: isSet(object.geoLocation) ? Point.fromJSON(object.geoLocation) : undefined,
      vendor: isSet(object.vendor) ? site_VendorFromJSON(object.vendor) : 0,
      tunnelPort: isSet(object.tunnelPort) ? globalThis.Number(object.tunnelPort) : 0,
      vendorConfig: isObject(object.vendorConfig) ? object.vendorConfig : undefined,
      maxInstances: isSet(object.maxInstances) ? globalThis.Number(object.maxInstances) : 0,
      minInstances: isSet(object.minInstances) ? globalThis.Number(object.minInstances) : 0,
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      updatedBy: isSet(object.updatedBy) ? globalThis.String(object.updatedBy) : "",
      created: isSet(object.created) ? globalThis.String(object.created) : undefined,
      updated: isSet(object.updated) ? globalThis.String(object.updated) : undefined,
      caCert: isSet(object.caCert) ? globalThis.String(object.caCert) : "",
      lastAlive: isSet(object.lastAlive) ? globalThis.String(object.lastAlive) : undefined,
      pushSourceIngress: isSet(object.pushSourceIngress) ? globalThis.String(object.pushSourceIngress) : "",
      pushSourceLb: isSet(object.pushSourceLb) ? globalThis.String(object.pushSourceLb) : "",
      k8sInternalSvcUrl: isSet(object.k8sInternalSvcUrl) ? globalThis.String(object.k8sInternalSvcUrl) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
    };
  },

  toJSON(message: Site): unknown {
    const obj: any = {};
    if (message.id !== "0") {
      obj.id = message.id;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.status !== 0) {
      obj.status = site_StatusToJSON(message.status);
    }
    if (message.geoLocation !== undefined) {
      obj.geoLocation = Point.toJSON(message.geoLocation);
    }
    if (message.vendor !== 0) {
      obj.vendor = site_VendorToJSON(message.vendor);
    }
    if (message.tunnelPort !== 0) {
      obj.tunnelPort = Math.round(message.tunnelPort);
    }
    if (message.vendorConfig !== undefined) {
      obj.vendorConfig = message.vendorConfig;
    }
    if (message.maxInstances !== 0) {
      obj.maxInstances = Math.round(message.maxInstances);
    }
    if (message.minInstances !== 0) {
      obj.minInstances = Math.round(message.minInstances);
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.updatedBy !== "") {
      obj.updatedBy = message.updatedBy;
    }
    if (message.created !== undefined) {
      obj.created = message.created;
    }
    if (message.updated !== undefined) {
      obj.updated = message.updated;
    }
    if (message.caCert !== "") {
      obj.caCert = message.caCert;
    }
    if (message.lastAlive !== undefined) {
      obj.lastAlive = message.lastAlive;
    }
    if (message.pushSourceIngress !== "") {
      obj.pushSourceIngress = message.pushSourceIngress;
    }
    if (message.pushSourceLb !== "") {
      obj.pushSourceLb = message.pushSourceLb;
    }
    if (message.k8sInternalSvcUrl !== "") {
      obj.k8sInternalSvcUrl = message.k8sInternalSvcUrl;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Site>, I>>(base?: I): Site {
    return Site.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Site>, I>>(object: I): Site {
    const message = createBaseSite();
    message.id = object.id ?? "0";
    message.orgId = object.orgId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.status = object.status ?? 0;
    message.geoLocation = (object.geoLocation !== undefined && object.geoLocation !== null)
      ? Point.fromPartial(object.geoLocation)
      : undefined;
    message.vendor = object.vendor ?? 0;
    message.tunnelPort = object.tunnelPort ?? 0;
    message.vendorConfig = object.vendorConfig ?? undefined;
    message.maxInstances = object.maxInstances ?? 0;
    message.minInstances = object.minInstances ?? 0;
    message.createdBy = object.createdBy ?? "";
    message.updatedBy = object.updatedBy ?? "";
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.caCert = object.caCert ?? "";
    message.lastAlive = object.lastAlive ?? undefined;
    message.pushSourceIngress = object.pushSourceIngress ?? "";
    message.pushSourceLb = object.pushSourceLb ?? "";
    message.k8sInternalSvcUrl = object.k8sInternalSvcUrl ?? "";
    message.version = object.version ?? "";
    return message;
  },
};

function createBaseSiteFile(): SiteFile {
  return {
    id: "0",
    siteId: "0",
    filename: "",
    description: "",
    fileType: 0,
    data: "",
    createdBy: "",
    updatedBy: "",
    created: undefined,
    updated: undefined,
    filepath: "",
    dataChunk: new Uint8Array(0),
    fileSizeBytes: "0",
    compressionType: 0,
    fileSchema: undefined,
    dynamicLookupJob: undefined,
  };
}

export const SiteFile = {
  encode(message: SiteFile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.siteId !== "0") {
      writer.uint32(16).uint64(message.siteId);
    }
    if (message.filename !== "") {
      writer.uint32(26).string(message.filename);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.fileType !== 0) {
      writer.uint32(40).int32(message.fileType);
    }
    if (message.data !== "") {
      writer.uint32(50).string(message.data);
    }
    if (message.createdBy !== "") {
      writer.uint32(58).string(message.createdBy);
    }
    if (message.updatedBy !== "") {
      writer.uint32(66).string(message.updatedBy);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(74).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(82).fork()).ldelim();
    }
    if (message.filepath !== "") {
      writer.uint32(90).string(message.filepath);
    }
    if (message.dataChunk.length !== 0) {
      writer.uint32(98).bytes(message.dataChunk);
    }
    if (message.fileSizeBytes !== "0") {
      writer.uint32(104).uint64(message.fileSizeBytes);
    }
    if (message.compressionType !== 0) {
      writer.uint32(112).int32(message.compressionType);
    }
    if (message.fileSchema !== undefined) {
      Struct.encode(Struct.wrap(message.fileSchema), writer.uint32(122).fork()).ldelim();
    }
    if (message.dynamicLookupJob !== undefined) {
      DynamicLookupJob.encode(message.dynamicLookupJob, writer.uint32(130).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SiteFile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSiteFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.siteId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filename = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.fileType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.data = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.updatedBy = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.filepath = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.dataChunk = reader.bytes();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.fileSizeBytes = longToString(reader.uint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.compressionType = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.fileSchema = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.dynamicLookupJob = DynamicLookupJob.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SiteFile {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "0",
      siteId: isSet(object.siteId) ? globalThis.String(object.siteId) : "0",
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      fileType: isSet(object.fileType) ? siteFile_FileTypeFromJSON(object.fileType) : 0,
      data: isSet(object.data) ? globalThis.String(object.data) : "",
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      updatedBy: isSet(object.updatedBy) ? globalThis.String(object.updatedBy) : "",
      created: isSet(object.created) ? globalThis.String(object.created) : undefined,
      updated: isSet(object.updated) ? globalThis.String(object.updated) : undefined,
      filepath: isSet(object.filepath) ? globalThis.String(object.filepath) : "",
      dataChunk: isSet(object.dataChunk) ? bytesFromBase64(object.dataChunk) : new Uint8Array(0),
      fileSizeBytes: isSet(object.fileSizeBytes) ? globalThis.String(object.fileSizeBytes) : "0",
      compressionType: isSet(object.compressionType) ? siteFile_CompressionTypeFromJSON(object.compressionType) : 0,
      fileSchema: isObject(object.fileSchema) ? object.fileSchema : undefined,
      dynamicLookupJob: isSet(object.dynamicLookupJob) ? DynamicLookupJob.fromJSON(object.dynamicLookupJob) : undefined,
    };
  },

  toJSON(message: SiteFile): unknown {
    const obj: any = {};
    if (message.id !== "0") {
      obj.id = message.id;
    }
    if (message.siteId !== "0") {
      obj.siteId = message.siteId;
    }
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.fileType !== 0) {
      obj.fileType = siteFile_FileTypeToJSON(message.fileType);
    }
    if (message.data !== "") {
      obj.data = message.data;
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.updatedBy !== "") {
      obj.updatedBy = message.updatedBy;
    }
    if (message.created !== undefined) {
      obj.created = message.created;
    }
    if (message.updated !== undefined) {
      obj.updated = message.updated;
    }
    if (message.filepath !== "") {
      obj.filepath = message.filepath;
    }
    if (message.dataChunk.length !== 0) {
      obj.dataChunk = base64FromBytes(message.dataChunk);
    }
    if (message.fileSizeBytes !== "0") {
      obj.fileSizeBytes = message.fileSizeBytes;
    }
    if (message.compressionType !== 0) {
      obj.compressionType = siteFile_CompressionTypeToJSON(message.compressionType);
    }
    if (message.fileSchema !== undefined) {
      obj.fileSchema = message.fileSchema;
    }
    if (message.dynamicLookupJob !== undefined) {
      obj.dynamicLookupJob = DynamicLookupJob.toJSON(message.dynamicLookupJob);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SiteFile>, I>>(base?: I): SiteFile {
    return SiteFile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SiteFile>, I>>(object: I): SiteFile {
    const message = createBaseSiteFile();
    message.id = object.id ?? "0";
    message.siteId = object.siteId ?? "0";
    message.filename = object.filename ?? "";
    message.description = object.description ?? "";
    message.fileType = object.fileType ?? 0;
    message.data = object.data ?? "";
    message.createdBy = object.createdBy ?? "";
    message.updatedBy = object.updatedBy ?? "";
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.filepath = object.filepath ?? "";
    message.dataChunk = object.dataChunk ?? new Uint8Array(0);
    message.fileSizeBytes = object.fileSizeBytes ?? "0";
    message.compressionType = object.compressionType ?? 0;
    message.fileSchema = object.fileSchema ?? undefined;
    message.dynamicLookupJob = (object.dynamicLookupJob !== undefined && object.dynamicLookupJob !== null)
      ? DynamicLookupJob.fromPartial(object.dynamicLookupJob)
      : undefined;
    return message;
  },
};

function createBaseDynamicLookupJob(): DynamicLookupJob {
  return { jobId: "0", status: 0, resource: undefined };
}

export const DynamicLookupJob = {
  encode(message: DynamicLookupJob, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== "0") {
      writer.uint32(8).uint64(message.jobId);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.resource !== undefined) {
      JobResource.encode(message.resource, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynamicLookupJob {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynamicLookupJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.jobId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.resource = JobResource.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynamicLookupJob {
    return {
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "0",
      status: isSet(object.status) ? dynamicLookupJobStatusFromJSON(object.status) : 0,
      resource: isSet(object.resource) ? JobResource.fromJSON(object.resource) : undefined,
    };
  },

  toJSON(message: DynamicLookupJob): unknown {
    const obj: any = {};
    if (message.jobId !== "0") {
      obj.jobId = message.jobId;
    }
    if (message.status !== 0) {
      obj.status = dynamicLookupJobStatusToJSON(message.status);
    }
    if (message.resource !== undefined) {
      obj.resource = JobResource.toJSON(message.resource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DynamicLookupJob>, I>>(base?: I): DynamicLookupJob {
    return DynamicLookupJob.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DynamicLookupJob>, I>>(object: I): DynamicLookupJob {
    const message = createBaseDynamicLookupJob();
    message.jobId = object.jobId ?? "0";
    message.status = object.status ?? 0;
    message.resource = (object.resource !== undefined && object.resource !== null)
      ? JobResource.fromPartial(object.resource)
      : undefined;
    return message;
  },
};

function createBaseJobResource(): JobResource {
  return { cpuRequest: undefined, cpuLimit: undefined, memoryRequest: undefined, memoryLimit: undefined };
}

export const JobResource = {
  encode(message: JobResource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cpuRequest !== undefined) {
      writer.uint32(8).uint32(message.cpuRequest);
    }
    if (message.cpuLimit !== undefined) {
      writer.uint32(16).uint32(message.cpuLimit);
    }
    if (message.memoryRequest !== undefined) {
      writer.uint32(24).uint32(message.memoryRequest);
    }
    if (message.memoryLimit !== undefined) {
      writer.uint32(32).uint32(message.memoryLimit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobResource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobResource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.cpuRequest = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.cpuLimit = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.memoryRequest = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.memoryLimit = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobResource {
    return {
      cpuRequest: isSet(object.cpuRequest) ? globalThis.Number(object.cpuRequest) : undefined,
      cpuLimit: isSet(object.cpuLimit) ? globalThis.Number(object.cpuLimit) : undefined,
      memoryRequest: isSet(object.memoryRequest) ? globalThis.Number(object.memoryRequest) : undefined,
      memoryLimit: isSet(object.memoryLimit) ? globalThis.Number(object.memoryLimit) : undefined,
    };
  },

  toJSON(message: JobResource): unknown {
    const obj: any = {};
    if (message.cpuRequest !== undefined) {
      obj.cpuRequest = Math.round(message.cpuRequest);
    }
    if (message.cpuLimit !== undefined) {
      obj.cpuLimit = Math.round(message.cpuLimit);
    }
    if (message.memoryRequest !== undefined) {
      obj.memoryRequest = Math.round(message.memoryRequest);
    }
    if (message.memoryLimit !== undefined) {
      obj.memoryLimit = Math.round(message.memoryLimit);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobResource>, I>>(base?: I): JobResource {
    return JobResource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JobResource>, I>>(object: I): JobResource {
    const message = createBaseJobResource();
    message.cpuRequest = object.cpuRequest ?? undefined;
    message.cpuLimit = object.cpuLimit ?? undefined;
    message.memoryRequest = object.memoryRequest ?? undefined;
    message.memoryLimit = object.memoryLimit ?? undefined;
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
