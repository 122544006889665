import { createIcon } from '@chakra-ui/react';

export const MonitoringIcon = createIcon({
  displayName: 'MonitoringIcon',
  viewBox: '0 0 21 21',
  path: [
    <path
      d="M18.735 10.0039H15.4017L12.9017 17.5039L7.90169 2.50391L5.40169 10.0039H2.06836"
      stroke="currentcolor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
