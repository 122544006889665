import { TIME_IN_SECONDS } from 'src/constants/time';
import { DateFilter } from 'src/types/DateFilter';

export const TIMEFRAME_RESOLUTION_MAP = {
  // TODO needs to be adjusted together with the time series API to match the resolution
  [DateFilter.Last5Mins]: TIME_IN_SECONDS.ONE_MINUTE,
  [DateFilter.Last15Mins]: TIME_IN_SECONDS.ONE_MINUTE,
  [DateFilter.Last1Hour]: TIME_IN_SECONDS.ONE_MINUTE * 5,
  [DateFilter.Last4Hours]: TIME_IN_SECONDS.ONE_MINUTE * 15,
  [DateFilter.Last12Hours]: TIME_IN_SECONDS.ONE_HOUR,
  [DateFilter.Last1Day]: TIME_IN_SECONDS.ONE_HOUR * 2,
  [DateFilter.Last7Days]: TIME_IN_SECONDS.ONE_HOUR,
  [DateFilter.Last14Days]: TIME_IN_SECONDS.ONE_DAY * 2,
  [DateFilter.Last30Days]: TIME_IN_SECONDS.ONE_DAY * 4,
  [DateFilter.Last60Days]: TIME_IN_SECONDS.ONE_DAY * 6,
  [DateFilter.Last90Days]: TIME_IN_SECONDS.ONE_DAY * 12,
  [DateFilter.Last12Months]: TIME_IN_SECONDS.ONE_MONTH,
};
