import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const Heading: ComponentSingleStyleConfig = {
  variants: {
    'title-1': {
      fontSize: '1.75rem',
      fontWeight: 700,
    },
    'title-2': {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    'title-3': {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    'title-4': {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
  },
};
