import { Button, Center, Heading } from '@chakra-ui/react';

const ErrorPage = () => (
  <Center h="100%" flexDirection="column" gap={8}>
    <Heading variant="title-1" fontWeight={400}>
      Oops, something went wrong!
    </Heading>
    <Button variant="outline" onClick={() => window.location.reload()}>
      Reload
    </Button>
  </Center>
);

export default ErrorPage;
