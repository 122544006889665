import { defineStyleConfig } from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';
import { Z_INDEX_OVER_TOAST } from 'src/constants/modal';

const baseStyle = {
  px: '16px',
  py: '8px',
  borderRadius: '8px',
  border: `1px solid ${rgba(COLORS.fullWhite, OPACITY[20])}`,
  _dark: {
    bg: 'popoverBackground',
    color: 'white',
  },
  zIndex: Z_INDEX_OVER_TOAST,
};

export const Tooltip = defineStyleConfig({ baseStyle });
