import { FilterOption } from '../types';

export const isSelectedComparator =
  (selectedFilterValueIds: string[]) =>
  ({ id: idA }: FilterOption, { id: idB }: FilterOption) => {
    const isSelectedA = selectedFilterValueIds.includes(idA);
    const isSelectedB = selectedFilterValueIds.includes(idB);

    if (isSelectedA && isSelectedB) {
      return 0;
    }

    if (isSelectedA) {
      return -1;
    }

    return 1;
  };

export const createFilterOption = (id: string, displayName: string): FilterOption => ({
  id,
  displayName,
});

export function createFilterOptionFromStrings(options?: string[]): FilterOption[] {
  return (options || []).map((option) => createFilterOption(option, option));
}
