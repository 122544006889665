import { useCallback } from 'react';
import { useAuthStoreContext } from 'src/context/AuthProvider/AuthStoreContext';

export function useLogout() {
  const { logout } = useAuthStoreContext();

  const onLogout = useCallback(() => {
    localStorage.removeItem('siteId');
    logout();
  }, [logout]);

  return onLogout;
}
