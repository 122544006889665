import { lazy, Suspense } from 'react';

const SiteDetails = lazy(() => import(/* webpackPrefetch: true */ './SiteDetails'));

const SiteDetailsPage = () => (
  <Suspense>
    <SiteDetails />
  </Suspense>
);

export default SiteDetailsPage;
