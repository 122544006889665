import { lazy, Suspense } from 'react';

const Alerts = lazy(() => import(/* webpackPrefetch: true */ 'src/pages/Alerts/Alerts'));

const AlertsPage = () => (
  <Suspense>
    <Alerts />
  </Suspense>
);

export default AlertsPage;
