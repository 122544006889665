import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys,
);

const baseStyle = definePartsStyle({
  list: {
    py: '0',
    borderRadius: '4px',
    border: '1px',
    _dark: {
      borderColor: 'darkBlue',
      bg: 'darkerBlue',
    },
  },
  item: {
    fontSize: '0.875rem',
    _dark: {
      bg: 'darkerBlue',
      _hover: {
        bg: 'menuHover',
      },
      _focus: {
        bg: 'menuHover',
      },
    },
    _first: {
      borderRadius: '4px 4px 0 0',
      border: '1px',
      borderColor: 'transparent',
    },
    _last: {
      borderRadius: '0 0 4px 4px',
      border: '1px',
      borderColor: 'transparent',
    },
  },
  divider: {
    my: '4',
    borderColor: 'white',
    borderBottom: '2px solid',
  },
});

export const Menu = defineMultiStyleConfig({ baseStyle });
