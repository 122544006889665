import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys,
);

const baseStyle = definePartsStyle({
  _disabled: {
    borderColor: rgba(COLORS.fullWhite, OPACITY[8]),
    opacity: OPACITY[90],
  },
  thumb: {
    _dark: {
      // TODO @t-ondrej white color
      bg: 'white',
    },
  },
  track: {
    _dark: {
      bg: rgba(COLORS.link, OPACITY[32]),
      _checked: {
        bg: 'lighterBlue',
        _hover: {
          bg: 'highlightColor',
        },
      },
    },
  },
});

export const Switch = defineMultiStyleConfig({ baseStyle });
