import { lazy, Suspense } from 'react';
import { Loader } from '@saas-ui/react';

const ChatbotApp = lazy(() => import(/* webpackPrefetch: true */ './Chatbot'));

export function Chatbot() {
  return (
    <Suspense fallback={<Loader />}>
      <ChatbotApp />
    </Suspense>
  );
}
