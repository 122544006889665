import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import { DateFilter } from 'src/types/DateFilter';
import { startOfDayInUtc } from 'src/utils/date';

export const DATE_FILTER_VALUES = [
  {
    id: DateFilter.Last5Mins,
    displayName: 'Last 5 mins',
  },
  {
    id: DateFilter.Last15Mins,
    displayName: 'Last 15 mins',
  },
  {
    id: DateFilter.Last1Hour,
    displayName: 'Last 1 hour',
  },
  {
    id: DateFilter.Last4Hours,
    displayName: 'Last 4 hours',
  },
  {
    id: DateFilter.Last12Hours,
    displayName: 'Last 12 hours',
  },
  {
    id: DateFilter.Last1Day,
    displayName: 'Last 1 day',
  },
  {
    id: DateFilter.Last7Days,
    displayName: 'Last 7 days',
  },
  {
    id: DateFilter.Last14Days,
    displayName: 'Last 14 days',
  },
  {
    id: DateFilter.Last30Days,
    displayName: 'Last 30 days',
  },
  {
    id: DateFilter.Last60Days,
    displayName: 'Last 60 days',
  },
];

export const DAYS_DATE_FILTER_IDS = [
  DateFilter.Last7Days,
  DateFilter.Last14Days,
  DateFilter.Last30Days,
  DateFilter.Last60Days,
] as DateFilter[];

export const DATE_FILTER_START_DATE = {
  [DateFilter.Last5Mins]: (endDate = new Date()) => subMinutes(endDate, 5),
  [DateFilter.Last15Mins]: (endDate = new Date()) => subMinutes(endDate, 15),
  [DateFilter.Last1Hour]: (endDate = new Date()) => subHours(endDate, 1),
  [DateFilter.Last4Hours]: (endDate = new Date()) => subHours(endDate, 4),
  [DateFilter.Last12Hours]: (endDate = new Date()) => subHours(endDate, 12),

  [DateFilter.Last1Day]: (endDate = new Date()) => startOfDayInUtc(subDays(endDate, 1)),
  [DateFilter.Last7Days]: (endDate = new Date()) => startOfDayInUtc(subDays(endDate, 7)),
  [DateFilter.Last14Days]: (endDate = new Date()) => startOfDayInUtc(subDays(endDate, 14)),
  [DateFilter.Last30Days]: (endDate = new Date()) => startOfDayInUtc(subDays(endDate, 30)),
  [DateFilter.Last60Days]: (endDate = new Date()) => startOfDayInUtc(subDays(endDate, 60)),
  [DateFilter.Last90Days]: (endDate = new Date()) => startOfDayInUtc(subDays(endDate, 90)),
  [DateFilter.Last12Months]: (endDate = new Date()) => startOfDayInUtc(subMonths(endDate, 12)),
};

export const DATE_FILTER_FORMAT_STRING = 'MMM d yyyy, HH:mm';

export const DEFAULT_DATE_FILTER_VALUE = DateFilter.Last1Hour;
