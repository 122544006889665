import { isRiggedValuesFeatureEnabled } from './featureFlags';

const OPTIMIZATION_MULTI_FACTOR_KEY = 'rigged_optimization_by_factor_of';
const ERROR_COUNT_FACTOR_KEY = 'rigged_errors_by_factor_of';

export function getRiggedFactor(key: string): number {
  if (!isRiggedValuesFeatureEnabled()) {
    return 1;
  }

  return Number(localStorage.getItem(key)) || 1;
}

export function riggedPercentageValue(value: number): number {
  const riggedFactor = getRiggedFactor(OPTIMIZATION_MULTI_FACTOR_KEY);
  return value * riggedFactor;
}

export function riggedErrorCount(value: number): number {
  const riggedFactor = getRiggedFactor(ERROR_COUNT_FACTOR_KEY);
  return value * riggedFactor;
}
