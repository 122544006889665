import { createIcon } from '@chakra-ui/react';

export const HydrationIcon = createIcon({
  displayName: 'HydrationIcon',
  viewBox: '0 0 24 24',
  path: [
    <g clipPath="url(#clip0_954_5892)">
      <path
        d="M20 16.58C21.0513 16.1196 21.9121 15.3116 22.4381 14.2916C22.9641 13.2715 23.1232 12.1016 22.8886 10.9782C22.6541 9.85478 22.0402 8.84623 21.1501 8.12185C20.2599 7.39748 19.1477 7.00137 18 7.00002H16.74C16.4231 5.77254 15.8189 4.63797 14.9773 3.68982C14.1358 2.74167 13.081 2.00709 11.8998 1.54664C10.7186 1.08619 9.44494 0.913035 8.18372 1.04146C6.92249 1.16989 5.70984 1.59622 4.64573 2.2853C3.58161 2.97439 2.6965 3.9065 2.06334 5.00482C1.43018 6.10313 1.06711 7.33619 1.00404 8.60237C0.940984 9.86855 1.17974 11.1316 1.70064 12.2874C2.22153 13.4432 3.00965 14.4586 4.00003 15.25M8.00006 19V21M8.00006 13V15M16.0001 19V21M16.0001 13V15M12.0001 21V23M12.0001 15V17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>,
    <defs>
      <clipPath id="clip0_954_5892">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>,
  ],
});
