export enum PaginationRequestValue {
  Offset = 'pagination.offset',
  Limit = 'pagination.limit',
  OrderingFieldName = 'pagination.ordering.fieldName',
  OrderingDescending = 'pagination.ordering.descending',
}

export type PaginationRequestParams = {
  [PaginationRequestValue.Offset]: number;
  [PaginationRequestValue.Limit]: number;
};

export type PaginationSortParams = {
  [PaginationRequestValue.OrderingFieldName]: string;
  [PaginationRequestValue.OrderingDescending]: boolean;
};
