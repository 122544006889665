import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['breadcrumb', 'item']);

export const Breadcrumb = helpers.defineMultiStyleConfig({
  baseStyle: {
    item: {
      fontSize: '14px',
      color: 'label',
    },
  },
});
