import { chakra, Flex, Spinner } from '@chakra-ui/react';

interface LoaderProps {
  color?: string;
  className?: string;
}

export const Loader = chakra(({ color, className }: LoaderProps) => (
  <Flex className={className} w="full" justify="center" align="center">
    <Spinner color={color} h="80px" w="80px" />
  </Flex>
));
