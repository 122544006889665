import { createContext, useContext } from 'react';

export interface AuthUser {
  name?: string;
  picture?: string;
  email?: string;
}

interface AuthStoreContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  authToken: string;
  user?: AuthUser;
  logout: VoidFunction;
  login: VoidFunction;
  error?: Error;
}

export const AuthStoreContext = createContext({} as AuthStoreContextType);

export const useAuthStoreContext = () => useContext(AuthStoreContext);
