import { Flex, Spinner } from '@chakra-ui/react';
import { Event_Category } from '@common-types/notification_service/model/v1/event';
import { Badge } from 'src/components/Badge/Badge';
import { useSite } from 'src/context/SiteProvider';
import { useNumberOfUnreadNotificationsQuery } from 'src/hooks/api/useNumberOfUnreadNotificationsQuery';

export function NotificationCount() {
  const { siteId, siteIds } = useSite();

  const {
    isLoading: isLoadingNotifications,
    isFetching: isFetchingNotifications,
    data: numOfUnreadNotifications,
  } = useNumberOfUnreadNotificationsQuery({
    siteIds,
    ackValues: [false],
    categories: [Event_Category.USER_EVENT],
    isEnabled: siteId !== undefined,
  });

  const shouldShowNotificationBadge =
    numOfUnreadNotifications !== undefined && numOfUnreadNotifications > 0;

  return (
    <Flex justifyContent="end" w="full">
      {isLoadingNotifications || isFetchingNotifications ? (
        <Spinner width={6} height={6} />
      ) : (
        shouldShowNotificationBadge && <Badge count={numOfUnreadNotifications} />
      )}
    </Flex>
  );
}
