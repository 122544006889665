import { createIcon } from '@chakra-ui/react';

export const InfoBlueIcon = createIcon({
  displayName: 'InfoBlueIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke="#62B3FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  ),
});
