import { PipelineDetailsTabs } from 'src/constants/pipelineTabs';

export function getTabViewName(tabId: PipelineDetailsTabs): string {
  switch (tabId) {
    case PipelineDetailsTabs.Analytics:
      return 'analytics';
    case PipelineDetailsTabs.Errors:
      return 'errors';
    case PipelineDetailsTabs.DataInsights:
      return 'dataInsights';
    default:
      return 'overview';
  }
}

export function getTabIdFromView(view?: string | null): PipelineDetailsTabs {
  switch (view) {
    case 'analytics':
      return PipelineDetailsTabs.Analytics;
    case 'errors':
      return PipelineDetailsTabs.Errors;
    case 'dataInsights':
      return PipelineDetailsTabs.DataInsights;
    default:
      return PipelineDetailsTabs.Overview;
  }
}
