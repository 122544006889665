import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Text, ToastPosition, ToastProps, useDisclosure, useToast } from '@chakra-ui/react';
import noop from 'lodash/noop';
import { NotificationStatus } from 'src/components/Notification/types';
import { MAX_NOTIFICATIONS_COUNT, NOTIFICATION_TIMEOUT_MS } from 'src/constants/notifications';
import { getErrorMessageWithTimestamp } from 'src/utils/notifications';
import { v4 as uuidv4 } from 'uuid';
import Dialog from '../Dialog/Dialog';
import Notification from './Notification';
import { Notification as NotificationType, ShowNotification } from './types';

type ShowNotificationOptions = {
  shouldForceDuplicate?: boolean;
  shouldTimeout?: boolean;
};

type NotificationContextType = {
  showNotification: (notification: ShowNotification, options?: ShowNotificationOptions) => void;
  showErrorNotification: (message: string) => void;
  removeNotification: (id: string) => void;
};

const notificationContext = createContext({} as NotificationContextType);
export const useNotification = () => useContext(notificationContext);

export const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const notifications = useRef<NotificationType[]>([]);
  const [activeErrorMessage, setActiveErrorMessage] = useState('');
  const { isOpen: isDialogOpen, onClose: onDialogClose, onOpen: onDialogOpen } = useDisclosure();
  const toast = useToast();

  const handleDailogClose = () => {
    onDialogClose();
    setActiveErrorMessage('');
  };

  const removeNotification = (id: string) => {
    const notificationsData = [...(notifications.current || [])];
    notifications.current = notificationsData.filter((notification) => notification.id !== id);
  };

  const getToastConfig = useCallback(
    (notification: NotificationType, message: string, shouldTimeout?: boolean) => ({
      position: 'bottom-left' as ToastPosition,
      duration: shouldTimeout ? NOTIFICATION_TIMEOUT_MS : null,
      render: ({ onClose }: ToastProps) => (
        <Notification
          id={notification.id}
          status={notification.status}
          message={message}
          onClose={onClose}
          onOpenDialog={() => {
            setActiveErrorMessage(notification.message);
            onDialogOpen();
            onClose?.();
          }}
        />
      ),
      onCloseComplete: () => removeNotification(notification.id),
    }),
    [onDialogOpen],
  );

  const showNotification = useCallback(
    (notification: Omit<NotificationType, 'id'>, options?: ShowNotificationOptions) => {
      const { shouldForceDuplicate, shouldTimeout } = options || {};
      const notificationId = uuidv4();

      const modifiedMessage = notification.showTimestamp
        ? getErrorMessageWithTimestamp(notification.message)
        : notification.message;

      const notificationsData = [...(notifications.current || [])];
      const duplicateNotification = notificationsData.find(
        ({ message, status }) => notification.message === message && notification.status === status,
      );

      if (duplicateNotification && !shouldForceDuplicate) {
        if (toast.isActive(duplicateNotification.id)) {
          toast.update(duplicateNotification.id, {
            ...getToastConfig(
              { ...notification, id: duplicateNotification.id },
              modifiedMessage,
              shouldTimeout,
            ),
          });
        }
        return;
      }

      while (notificationsData.length >= MAX_NOTIFICATIONS_COUNT) {
        const staleNotification = notificationsData.shift();
        if (staleNotification && toast.isActive(staleNotification.id)) {
          toast.close(staleNotification.id);
        }
      }

      notifications.current = [
        ...notificationsData,
        { ...notification, message: notification.message, id: notificationId },
      ];

      toast({
        id: notificationId,
        ...getToastConfig({ ...notification, id: notificationId }, modifiedMessage, shouldTimeout),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const showErrorNotification = useCallback(
    (message: string) => {
      showNotification({ message, status: NotificationStatus.Error, showTimestamp: true });
    },
    [showNotification],
  );

  const contextValue = useMemo(
    () => ({
      showNotification,
      showErrorNotification,
      removeNotification,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <notificationContext.Provider value={contextValue}>
      <Dialog
        shouldUseCustomZIndex
        actionLabel=""
        cancelLabel="Close"
        title="Error Details"
        type="error"
        isLoading={false}
        onActionClick={noop}
        isOpen={isDialogOpen}
        cancelOnly
        bodyContent={<Text>{activeErrorMessage}</Text>}
        onClose={handleDailogClose}
      />
      {children}
    </notificationContext.Provider>
  );
};
