import { useNavigate } from 'react-router-dom';
import { Button, Center, Heading } from '@chakra-ui/react';
import { ROUTE_PATHS } from 'src/constants/routes';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Center h="100%" flexDirection="column" gap={8}>
      <Heading variant="title-1" fontWeight={400}>
        Page not found!
      </Heading>
      <Button variant="outline" onClick={() => navigate(ROUTE_PATHS.root)}>
        Go to Home
      </Button>
    </Center>
  );
};

export default NotFound;
