import { createIcon } from '@chakra-ui/react';

export const AlertTriangleIcon = createIcon({
  displayName: 'AlertTriangleIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M11.9998 16.9999H12.0098M11.9998 8.99994V12.9999M10.2898 3.85996C10.468 3.56607 10.7191 3.32308 11.0186 3.15444C11.3181 2.98581 11.656 2.89722 11.9998 2.89722C12.3435 2.89722 12.6814 2.98581 12.981 3.15444C13.2805 3.32308 13.5315 3.56607 13.7098 3.85996L22.1798 18C22.3544 18.3024 22.4468 18.6453 22.4478 18.9945C22.4488 19.3437 22.3583 19.6871 22.1854 19.9905C22.0124 20.2939 21.7631 20.5467 21.4621 20.7238C21.1611 20.9009 20.819 20.9961 20.4698 21H3.52978C3.18058 20.9961 2.83847 20.9009 2.53748 20.7238C2.2365 20.5467 1.98714 20.2939 1.8142 19.9905C1.64127 19.6871 1.55079 19.3437 1.55177 18.9945C1.55274 18.6453 1.64514 18.3024 1.81978 18L9.00791 5.99994L9.99976 4.34413L10.2898 3.85996Z"
      stroke="#FFC123"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
