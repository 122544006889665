import axios from 'src/lib/axios/axios';
import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosHeaders, Method, RawAxiosRequestHeaders } from 'axios';
import { RTK_ERROR_EVENT_NAME } from 'src/constants/errors';

interface RtkApiQuery {
  url: string;
  method?: Method;
  params?: unknown;
  body?: unknown;
  headers?: RawAxiosRequestHeaders | AxiosHeaders;
}

export const axiosBaseQuery = async ({
  url,
  method = 'GET',
  params,
  body,
  headers,
}: RtkApiQuery) => {
  try {
    const result = await axios({ url, method, params, data: body, headers });
    return { data: result.data };
  } catch (axiosError) {
    const apiErrorEvent = new CustomEvent(RTK_ERROR_EVENT_NAME, { detail: { error: axiosError } });
    document.dispatchEvent(apiErrorEvent);
    return { error: axiosError };
  }
};

const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery,
  endpoints: () => ({}),
});

export default apiSlice;
