import { ReactElement } from 'react';

export enum FilterType {
  SingleSelect = 'singleSelect',
  MultiSelect = 'multiSelect',
}

export enum FilterStorageType {
  LocalStorage = 'localstorage',
  SessionStorage = 'sessionstorage',
}

export interface FilterOption {
  id: string;
  displayName: string;
  additionalInfo?: string | number;
}

export type Filter = {
  name: string;
  displayName?: string;
  filterValues: FilterOption[];
  type: FilterType;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  buttonVariant?: 'filter' | 'graphFilter';
  showAllFilterOption?: boolean;
  noneSelectedFilterDisplayName?: string;
  searchable?: boolean;
  sorted?: boolean;
};

export type RenderFilterButtonPropType = (
  filterDisplayValue: string | undefined,
  additionalSelectedFilters?: string | false,
) => JSX.Element;

export type SelectedFilterValues = { [key: string]: string[] };
