import { lazy, Suspense } from 'react';

const Notifications = lazy(
  () => import(/* webpackPrefetch: true */ 'src/components/Notifications/Notifications'),
);

const NotificationsPage = () => (
  <Suspense>
    <Notifications />
  </Suspense>
);

export default NotificationsPage;
