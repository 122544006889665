import { createIcon } from '@chakra-ui/react';

export const AlertsIcon = createIcon({
  displayName: 'AlertsIcon',
  viewBox: '0 0 21 21',
  path: [
    <path
      d="M13.9352 6.47064C14.4001 6.935 14.7689 7.48645 15.0205 8.09344C15.2721 8.70043 15.4016 9.35106 15.4016 10.0081C15.4016 10.6652 15.2721 11.3158 15.0205 11.9228C14.7689 12.5298 14.4001 13.0813 13.9352 13.5456M6.86853 13.5373C6.40365 13.0729 6.03486 12.5215 5.78323 11.9145C5.53161 11.3075 5.4021 10.6569 5.4021 9.9998C5.4021 9.34273 5.53161 8.69209 5.78323 8.0851C6.03486 7.47811 6.40365 6.92667 6.86853 6.4623M16.2935 4.1123C17.8558 5.67504 18.7334 7.79427 18.7334 10.004C18.7334 12.2137 17.8558 14.3329 16.2935 15.8956M4.5102 15.8956C2.94794 14.3329 2.07031 12.2137 2.07031 10.004C2.07031 7.79427 2.94794 5.67504 4.5102 4.1123M12.0685 10.004C12.0685 10.9244 11.3223 11.6706 10.4019 11.6706C9.48139 11.6706 8.7352 10.9244 8.7352 10.004C8.7352 9.0835 9.48139 8.3373 10.4019 8.3373C11.3223 8.3373 12.0685 9.0835 12.0685 10.004Z"
      stroke="currentcolor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
