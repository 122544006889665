import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { SaasProvider } from '@saas-ui/react';
import { NotificationProvider } from './components/Notification/NotificationProvider';
import { AuthProvider } from './context/AuthProvider';
import { ReactQueryClientProvider } from './context/ReactQueryClientProvider';
import { SiteProvider } from './context/SiteProvider';
import { TimeFilterProvider } from './context/TimeFilterProvider';
import { theme } from './lib/chakra/theme';
import { observoRouter } from './routes';
import store from './store';
import './index.css';
import '@fontsource/jetbrains-mono/400.css';

import('./initSentry').then((module) => module.default());

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <SaasProvider theme={theme}>
        <Provider store={store}>
          <AuthProvider>
            <NotificationProvider>
              <ReactQueryClientProvider>
                <SiteProvider>
                  <TimeFilterProvider>
                    <RouterProvider router={observoRouter} />
                  </TimeFilterProvider>
                </SiteProvider>
              </ReactQueryClientProvider>
            </NotificationProvider>
          </AuthProvider>
        </Provider>
      </SaasProvider>
    </HelmetProvider>
  </React.StrictMode>,
);
