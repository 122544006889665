import { createIcon } from '@chakra-ui/react';

export const DashboardIcon = createIcon({
  displayName: 'DashboardIcon',
  viewBox: '0 0 20 21',
  path: [
    <path
      d="M2.5 7.50391H17.5M7.5 17.5039V7.50391M4.16667 2.50391H15.8333C16.7538 2.50391 17.5 3.2501 17.5 4.17057V15.8372C17.5 16.7577 16.7538 17.5039 15.8333 17.5039H4.16667C3.24619 17.5039 2.5 16.7577 2.5 15.8372V4.17057C2.5 3.2501 3.24619 2.50391 4.16667 2.50391Z"
      stroke="currentcolor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
