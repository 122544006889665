import { lazy, Suspense } from 'react';
import { Loader } from 'src/components/Loader';

const PipelineDetails = lazy(() => import(/* webpackPrefetch: true */ 'src/pages/PipelineDetails'));

const PipelineDetailsPage = () => (
  <Suspense fallback={<Loader />}>
    <PipelineDetails />
  </Suspense>
);

export default PipelineDetailsPage;
