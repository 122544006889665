import { ReactNode } from 'react';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import {
  Box,
  ComponentWithAs,
  HStack,
  Icon,
  Link,
  LinkProps,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

interface NavLinkProps extends LinkProps {
  label: string;
  icon: ComponentWithAs<'svg', object>;
  to: string;
  rightContent?: ReactNode;
  iconActiveColor?: string;
  iconInactiveColor?: string;
}

export function NavLink(props: NavLinkProps) {
  const { icon, label, to, rightContent, iconActiveColor, iconInactiveColor, ...rest } = props;
  const activeColor = iconActiveColor ?? 'link';
  const inactiveColor = iconInactiveColor ?? 'inactiveLink';

  return (
    <ReactRouterNavLink to={to}>
      {({ isActive }) => (
        <Link
          as={Box}
          to={to}
          display="block"
          py={2}
          px={4}
          borderRadius="15px"
          transition="all 0.3s"
          lineHeight="1.5rem"
          aria-current={isActive ? 'page' : undefined}
          color={rgba(COLORS.fullWhite, OPACITY[60])}
          _hover={{
            bg: mode('gray.100', 'navbarHover'),
            color: mode('black', 'white'),
          }}
          _activeLink={{
            bg: mode('blue.500', 'navbarHover'),
            color: mode('black', 'white'),
          }}
          role="group"
          {...rest}
        >
          <HStack spacing={4}>
            <Icon
              as={icon}
              boxSize="20px"
              color={isActive ? activeColor : inactiveColor}
              _groupHover={{ color: activeColor }}
              fill="none"
            />
            <Text as="span" variant="paragraph" fontWeight={500}>
              {label}
            </Text>
            {rightContent}
          </HStack>
        </Link>
      )}
    </ReactRouterNavLink>
  );
}
