/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";

export const protobufPackage = "v1.common";

export enum Status {
  INACTIVE = 0,
  ACTIVE = 1,
  DELETED = 2,
  DISABLED = 3,
  UNRECOGNIZED = -1,
}

export function statusFromJSON(object: any): Status {
  switch (object) {
    case 0:
    case "INACTIVE":
      return Status.INACTIVE;
    case 1:
    case "ACTIVE":
      return Status.ACTIVE;
    case 2:
    case "DELETED":
      return Status.DELETED;
    case 3:
    case "DISABLED":
      return Status.DISABLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Status.UNRECOGNIZED;
  }
}

export function statusToJSON(object: Status): string {
  switch (object) {
    case Status.INACTIVE:
      return "INACTIVE";
    case Status.ACTIVE:
      return "ACTIVE";
    case Status.DELETED:
      return "DELETED";
    case Status.DISABLED:
      return "DISABLED";
    case Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface TimeRange {
  startTimestamp: string | undefined;
  endTimestamp: string | undefined;
}

export interface ResponseStatus {
  /** Status code to return */
  code: ResponseStatus_StatusCode;
  /** Message. */
  errorMessage: string;
  /** Frontend understandable user message code. */
  userMessageCode: ResponseStatus_UserMessageCode;
}

export enum ResponseStatus_StatusCode {
  OK = 0,
  INVALID_PARAMS = 1,
  /** SERVER_ERROR - Add all statuses */
  SERVER_ERROR = 2,
  UNRECOGNIZED = -1,
}

export function responseStatus_StatusCodeFromJSON(object: any): ResponseStatus_StatusCode {
  switch (object) {
    case 0:
    case "OK":
      return ResponseStatus_StatusCode.OK;
    case 1:
    case "INVALID_PARAMS":
      return ResponseStatus_StatusCode.INVALID_PARAMS;
    case 2:
    case "SERVER_ERROR":
      return ResponseStatus_StatusCode.SERVER_ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ResponseStatus_StatusCode.UNRECOGNIZED;
  }
}

export function responseStatus_StatusCodeToJSON(object: ResponseStatus_StatusCode): string {
  switch (object) {
    case ResponseStatus_StatusCode.OK:
      return "OK";
    case ResponseStatus_StatusCode.INVALID_PARAMS:
      return "INVALID_PARAMS";
    case ResponseStatus_StatusCode.SERVER_ERROR:
      return "SERVER_ERROR";
    case ResponseStatus_StatusCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ResponseStatus_UserMessageCode {
  /** DEFAULT - List of Message Codes like error in source connection */
  DEFAULT = 0,
  UNRECOGNIZED = -1,
}

export function responseStatus_UserMessageCodeFromJSON(object: any): ResponseStatus_UserMessageCode {
  switch (object) {
    case 0:
    case "DEFAULT":
      return ResponseStatus_UserMessageCode.DEFAULT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ResponseStatus_UserMessageCode.UNRECOGNIZED;
  }
}

export function responseStatus_UserMessageCodeToJSON(object: ResponseStatus_UserMessageCode): string {
  switch (object) {
    case ResponseStatus_UserMessageCode.DEFAULT:
      return "DEFAULT";
    case ResponseStatus_UserMessageCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Pagination {
  offset: number;
  limit: number;
  totalCount: number;
  ordering: Pagination_Ordering | undefined;
}

export interface Pagination_Ordering {
  fieldName: string;
  descending: boolean;
}

export interface StringArray {
  items: string[];
}

export interface SelectOption {
  /** the name which will be visible on the UI as option label. */
  label: string;
  /**
   * the id which will be associated with the given option and will be passed to
   * the request params.
   */
  name: string;
}

export interface SelectOptionResponse {
  options: SelectOption[];
  status: ResponseStatus | undefined;
}

function createBaseTimeRange(): TimeRange {
  return { startTimestamp: undefined, endTimestamp: undefined };
}

export const TimeRange = {
  encode(message: TimeRange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.startTimestamp), writer.uint32(10).fork()).ldelim();
    }
    if (message.endTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.endTimestamp), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeRange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TimeRange {
    return {
      startTimestamp: isSet(object.startTimestamp) ? globalThis.String(object.startTimestamp) : undefined,
      endTimestamp: isSet(object.endTimestamp) ? globalThis.String(object.endTimestamp) : undefined,
    };
  },

  toJSON(message: TimeRange): unknown {
    const obj: any = {};
    if (message.startTimestamp !== undefined) {
      obj.startTimestamp = message.startTimestamp;
    }
    if (message.endTimestamp !== undefined) {
      obj.endTimestamp = message.endTimestamp;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeRange>, I>>(base?: I): TimeRange {
    return TimeRange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TimeRange>, I>>(object: I): TimeRange {
    const message = createBaseTimeRange();
    message.startTimestamp = object.startTimestamp ?? undefined;
    message.endTimestamp = object.endTimestamp ?? undefined;
    return message;
  },
};

function createBaseResponseStatus(): ResponseStatus {
  return { code: 0, errorMessage: "", userMessageCode: 0 };
}

export const ResponseStatus = {
  encode(message: ResponseStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.errorMessage !== "") {
      writer.uint32(18).string(message.errorMessage);
    }
    if (message.userMessageCode !== 0) {
      writer.uint32(24).int32(message.userMessageCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResponseStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResponseStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.userMessageCode = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResponseStatus {
    return {
      code: isSet(object.code) ? responseStatus_StatusCodeFromJSON(object.code) : 0,
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
      userMessageCode: isSet(object.userMessageCode)
        ? responseStatus_UserMessageCodeFromJSON(object.userMessageCode)
        : 0,
    };
  },

  toJSON(message: ResponseStatus): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = responseStatus_StatusCodeToJSON(message.code);
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    if (message.userMessageCode !== 0) {
      obj.userMessageCode = responseStatus_UserMessageCodeToJSON(message.userMessageCode);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ResponseStatus>, I>>(base?: I): ResponseStatus {
    return ResponseStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResponseStatus>, I>>(object: I): ResponseStatus {
    const message = createBaseResponseStatus();
    message.code = object.code ?? 0;
    message.errorMessage = object.errorMessage ?? "";
    message.userMessageCode = object.userMessageCode ?? 0;
    return message;
  },
};

function createBasePagination(): Pagination {
  return { offset: 0, limit: 0, totalCount: 0, ordering: undefined };
}

export const Pagination = {
  encode(message: Pagination, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.offset !== 0) {
      writer.uint32(8).uint32(message.offset);
    }
    if (message.limit !== 0) {
      writer.uint32(16).uint32(message.limit);
    }
    if (message.totalCount !== 0) {
      writer.uint32(24).uint32(message.totalCount);
    }
    if (message.ordering !== undefined) {
      Pagination_Ordering.encode(message.ordering, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Pagination {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePagination();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.offset = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.totalCount = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ordering = Pagination_Ordering.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Pagination {
    return {
      offset: isSet(object.offset) ? globalThis.Number(object.offset) : 0,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : 0,
      totalCount: isSet(object.totalCount) ? globalThis.Number(object.totalCount) : 0,
      ordering: isSet(object.ordering) ? Pagination_Ordering.fromJSON(object.ordering) : undefined,
    };
  },

  toJSON(message: Pagination): unknown {
    const obj: any = {};
    if (message.offset !== 0) {
      obj.offset = Math.round(message.offset);
    }
    if (message.limit !== 0) {
      obj.limit = Math.round(message.limit);
    }
    if (message.totalCount !== 0) {
      obj.totalCount = Math.round(message.totalCount);
    }
    if (message.ordering !== undefined) {
      obj.ordering = Pagination_Ordering.toJSON(message.ordering);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Pagination>, I>>(base?: I): Pagination {
    return Pagination.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Pagination>, I>>(object: I): Pagination {
    const message = createBasePagination();
    message.offset = object.offset ?? 0;
    message.limit = object.limit ?? 0;
    message.totalCount = object.totalCount ?? 0;
    message.ordering = (object.ordering !== undefined && object.ordering !== null)
      ? Pagination_Ordering.fromPartial(object.ordering)
      : undefined;
    return message;
  },
};

function createBasePagination_Ordering(): Pagination_Ordering {
  return { fieldName: "", descending: false };
}

export const Pagination_Ordering = {
  encode(message: Pagination_Ordering, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldName !== "") {
      writer.uint32(42).string(message.fieldName);
    }
    if (message.descending !== false) {
      writer.uint32(32).bool(message.descending);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Pagination_Ordering {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePagination_Ordering();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fieldName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.descending = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Pagination_Ordering {
    return {
      fieldName: isSet(object.fieldName) ? globalThis.String(object.fieldName) : "",
      descending: isSet(object.descending) ? globalThis.Boolean(object.descending) : false,
    };
  },

  toJSON(message: Pagination_Ordering): unknown {
    const obj: any = {};
    if (message.fieldName !== "") {
      obj.fieldName = message.fieldName;
    }
    if (message.descending !== false) {
      obj.descending = message.descending;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Pagination_Ordering>, I>>(base?: I): Pagination_Ordering {
    return Pagination_Ordering.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Pagination_Ordering>, I>>(object: I): Pagination_Ordering {
    const message = createBasePagination_Ordering();
    message.fieldName = object.fieldName ?? "";
    message.descending = object.descending ?? false;
    return message;
  },
};

function createBaseStringArray(): StringArray {
  return { items: [] };
}

export const StringArray = {
  encode(message: StringArray, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.items) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StringArray {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStringArray();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StringArray {
    return { items: globalThis.Array.isArray(object?.items) ? object.items.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: StringArray): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StringArray>, I>>(base?: I): StringArray {
    return StringArray.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StringArray>, I>>(object: I): StringArray {
    const message = createBaseStringArray();
    message.items = object.items?.map((e) => e) || [];
    return message;
  },
};

function createBaseSelectOption(): SelectOption {
  return { label: "", name: "" };
}

export const SelectOption = {
  encode(message: SelectOption, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.label !== "") {
      writer.uint32(10).string(message.label);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectOption {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectOption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.label = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelectOption {
    return {
      label: isSet(object.label) ? globalThis.String(object.label) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: SelectOption): unknown {
    const obj: any = {};
    if (message.label !== "") {
      obj.label = message.label;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectOption>, I>>(base?: I): SelectOption {
    return SelectOption.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SelectOption>, I>>(object: I): SelectOption {
    const message = createBaseSelectOption();
    message.label = object.label ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseSelectOptionResponse(): SelectOptionResponse {
  return { options: [], status: undefined };
}

export const SelectOptionResponse = {
  encode(message: SelectOptionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.options) {
      SelectOption.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.status !== undefined) {
      ResponseStatus.encode(message.status, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectOptionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectOptionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.options.push(SelectOption.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = ResponseStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelectOptionResponse {
    return {
      options: globalThis.Array.isArray(object?.options)
        ? object.options.map((e: any) => SelectOption.fromJSON(e))
        : [],
      status: isSet(object.status) ? ResponseStatus.fromJSON(object.status) : undefined,
    };
  },

  toJSON(message: SelectOptionResponse): unknown {
    const obj: any = {};
    if (message.options?.length) {
      obj.options = message.options.map((e) => SelectOption.toJSON(e));
    }
    if (message.status !== undefined) {
      obj.status = ResponseStatus.toJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectOptionResponse>, I>>(base?: I): SelectOptionResponse {
    return SelectOptionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SelectOptionResponse>, I>>(object: I): SelectOptionResponse {
    const message = createBaseSelectOptionResponse();
    message.options = object.options?.map((e) => SelectOption.fromPartial(e)) || [];
    message.status = (object.status !== undefined && object.status !== null)
      ? ResponseStatus.fromPartial(object.status)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
