export const SOMETHING_WENT_WRONG = 'Something went wrong!';
export const SOMETHING_WENT_WRONG_ADD_HYDRATION =
  'Something went wrong while adding the hydration job. Please reload the page and try again.';
export const SOMETHING_WENT_WRONG_ADD_DESTINATION =
  'Something went wrong while adding the destination. Please check your data and try again.';
export const SOMETHING_WENT_WRONG_EDIT_DESTINATION =
  'Something went wrong while editing the destination. Please reload the page and try again.';
export const SOMETHING_WENT_WRONG_ADD_SOURCE =
  'Something went wrong while adding the source. Please check your data and try again.';
export const SOMETHING_WENT_WRONG_EDIT_SOURCE =
  'Something went wrong while editing the source. Please reload the page and try again.';
export const SOMETHING_WENT_WRONG_UPDATE_PIPELINE =
  'Something went wrong while updating the pipeline.';
export const SOMETHING_WENT_WRONG_SAVE_PIPELINE =
  'Something went wrong while saving the pipeline. Please reload the page and try again.';
export const SOMETHING_WENT_WRONG_CREATE_PIPELINE =
  'Something went wrong while creating the pipeline. Please reload the page and try again.';
export const SOMETHING_WENT_WRONG_DEPLOY_PIPELINE =
  'Something went wrong while deploying the pipeline.';
export const SOMETHING_WENT_WRONG_DEPLOY_PIPELINE_RELOAD =
  'Something went wrong while deploying the pipeline. Please reload the page and try again.';
export const SOMETHING_WENT_WRONG_SAVE_PIPELINE_GRAPH =
  'Something went wrong saving the pipeline graph. Please reload the page and try again.';
export const SOMETHING_WENT_WRONG_CREATE_TRANSFORM =
  'Something went wrong while creating the transformations. Please reload the page and try again.';
export const SOMETHING_WENT_WRONG_ADD_SITE_FILE =
  'Something went wrong while adding a site file. Please try again.';
export const SOMETHING_WENT_WRONG_UPDATE_SITE_FILE =
  'Something went wrong while updating a site file. Please try again.';
export const UNABLE_TO_UPLOAD_FILE = 'Unable to upload the file!';
export const CANNOT_REMOVE_DESTINATION = 'Destination cannot be removed.';
export const CANNOT_REMOVE_SOURCE = 'Source cannot be removed.';
export const CANNOT_REMOVE_HYDRATION_JOB = 'Hydration Job cannot be removed.';
