import { Flex, Text } from '@chakra-ui/react';
import { COLORS } from 'src/constants/colors';
import { convertNumberToShortString } from 'src/utils/dataConversion';

interface BadgeProps {
  count: number;
}

export function Badge({ count }: BadgeProps) {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      h="20px"
      minW="20px"
      w="auto"
      px={1}
      borderRadius={100}
      bg={COLORS.status.chip}
    >
      <Text fontSize="12px" fontWeight={700} color={COLORS.white}>
        {convertNumberToShortString(count, 0)}
      </Text>
    </Flex>
  );
}
