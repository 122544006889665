import Axios from 'axios';
import qs from 'qs';
import { getEnvVar } from 'src/utils/getEnvVar';

const axios = Axios.create({
  baseURL: getEnvVar('REACT_APP_API_BASE_URL'),
  paramsSerializer: {
    serialize: (params: Record<string, unknown>) =>
      qs.stringify(params, {
        arrayFormat: 'repeat',
      }),
  },
});

export default axios;
