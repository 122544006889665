import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys,
);

const baseStyle = definePartsStyle({
  thead: {
    th: {
      fontSize: '12px',
      textTransform: 'none',
      fontWeight: 700,
      lineHeight: '20px',
      borderBottom: 'unset',
      color: 'white',
    },
  },
  tbody: {
    borderY: `1px solid ${rgba(COLORS.lightVividSkyBlue, OPACITY[15])}`,
    tr: {
      '&:nth-of-type(odd)': {
        td: {
          bg: rgba(COLORS.lighterPurple, OPACITY[12]),
        },
      },
      _hover: {
        bg: rgba(COLORS.link, OPACITY[8]),
      },
      _selected: {
        bg: rgba(COLORS.link, OPACITY[16]),
      },
    },
    td: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      borderBottom: 'unset',
    },
  },
});

const variants = {
  widget: {
    thead: {
      borderBottom: `4px solid transparent`,
      th: {
        fontSize: '14px',
        fontWeight: '500',
        textTransform: 'none',
        borderBottom: 'unset',
        color: 'white',
        padding: '0 2px 0 4px',
      },
    },
    tbody: {
      border: 0,
      tr: {
        _hover: {
          bg: 'transparent',
        },
      },
      td: {
        fontSize: '12px',
        fontWeight: '400',
        borderBottom: 'unset',
        padding: '2px',
      },
    },
  },
};

export const Table = defineMultiStyleConfig({ baseStyle, variants });
