import { lazy, Suspense } from 'react';

const Sources = lazy(() => import(/* webpackPrefetch: true */ 'src/pages/Sources/Sources'));

const SourcesPage = () => (
  <Suspense>
    <Sources />
  </Suspense>
);

export default SourcesPage;
